
// import styled from "styled-components";
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'
import {mobileDisplayContentVertical} from 'components/emotionStyles'

import { useScreenContext } from 'context/AppContext/ScreenContext';
import { Link } from 'react-router-dom';

import { FaInstagram, FaFacebook, FaTwitter, FaYoutube } from 'react-icons/fa';

import { ReactComponent as GoghNowLogo } from 'assets/cta-logo-one-wht.svg';


const FooterLoggedOut = () => {

    const {isMobile, isMedium, isLarge} = useScreenContext()


    return (

        <FooterSection>

        <FooterContainer>
            <LogoContainer to="/">
                <GoghNowLogo/>
            </LogoContainer>
            <ContainerGrid $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>

                <ContainerColumns>
                    <SectionHeader>Company</SectionHeader>
                    <StyledList>
                        <StyledListItem>
                            <StyledLink to="/">Home</StyledLink>
                        </StyledListItem>
                        <StyledListItem>
                            <StyledLink to="/blog">Blog</StyledLink>
                        </StyledListItem>
                        <StyledListItem>
                            <StyledLink to="/help">Help</StyledLink>
                        </StyledListItem>
                    </StyledList>

                </ContainerColumns>

                <ContainerColumns>
                    <SectionHeader>Products</SectionHeader>
                    <StyledList>
                        <StyledListItem>
                            <StyledLink to="/">Users</StyledLink>
                        </StyledListItem>
                        <StyledListItem>
                            <StyledLink to="/provider">Providers</StyledLink>
                        </StyledListItem>
                        <StyledListItem>
                            <StyledLink to="/business">Businesses</StyledLink>
                        </StyledListItem>
                    </StyledList>

                    
                </ContainerColumns>

            </ContainerGrid>

            <SocialMediaContainer>
                <SocialIcon href="https://www.instagram.com/goghnow" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                </SocialIcon>
                <SocialIcon href="https://www.facebook.com/goghnow" target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                </SocialIcon>
                <SocialIcon href="https://www.twitter.com/goghnow" target="_blank" rel="noopener noreferrer">
                    <FaTwitter />
                </SocialIcon>
                <SocialIcon href="https://www.youtube.com/goghnow" target="_blank" rel="noopener noreferrer">
                    <FaYoutube />
                </SocialIcon>
            </SocialMediaContainer>
            <BottomInformation>
                <p>© 2024 GoghNow LLC</p>
            </BottomInformation>

        </FooterContainer>

    </FooterSection>

    )
}

export default FooterLoggedOut




const FooterSection = styled.div`

min-height: 65vh;
display: flex;
flex-direction: row;
text-align: center;
width: 100%;
justify-content: center;
// align-items: center;
background-color: #3F3F3F;
padding: 50px 75px;
`

const FooterContainer = styled.div`

width: 100%;
display:flex;
flex-direction: column;
gap: 0px;
`

const LogoContainer = styled(Link)`
padding: 0px;
width: 150px;
margin-top: 4px;
max-height: 70px;
font-size: 0px;
display: inline-block;
cursor: pointer;

// img {
//     display: block;
//     width:100%;
// }
`

const ContainerGrid = styled.div`

${mobileDisplayContentVertical};
display: grid;
grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(1, 1fr)';
    if (props.$isMedium) return 'repeat(3, 1fr)';
    if (props.$isLarge) return 'repeat(3, 1fr)';
    return 'repeat(3, 1fr)'; // Default to large if none match
  }};
grid-auto-rows: 1fr;
width: auto;
gap: 50px;
justify-content: space-around;
padding: 15px 0px;



@media (max-width: 769px) {
    gap: 0px;
    padding: 20px 0px;
}

@media (max-width: 450px) {
    gap: 0px;
    padding: 0px 0px;
}

`

const ContainerColumns = styled.div`

display: flex;
flex-direction:${props => {
    if (props.$isMobile) return 'row';
    if (props.$isMedium) return 'row';
    if (props.$isLarge) return 'column';
    return 'column'; // Default to large if none match
  }};
justify-content: start;
align-items: start;
// background-color: #00000020;
// border: 1px solid #CCC;
padding: 15px;
    h4{
        padding-top: 0px;
    }
@media (max-width: 769px) {
    gap: 20px;
    // padding: 10px 10px;
}
    width: auto;
`

const SectionHeader = styled.h3`
text-align: left:
color: white;
`



//LIST
// Styled component for the unordered list (UL)
const StyledList = styled.ul`
  list-style-type: none;  // Removes default bullet points
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;  // Space between items
  display: flex;
  flex-direction: column;
  text-align: left;
`;

// Styled component for each list item (LI)
const StyledListItem = styled.li`
  display: inline-block;  // Makes the items display in a row
`;

// Styled component for the Link (Anchor Tag)
const StyledLink = styled(Link)`
  text-decoration: none;  // Removes the default underline from links
  color: #FFFFFF60;  // Set link color to blue
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;  // Smooth transition on hover

  &:hover {
    background-color: transparent;  // Change background color on hover
    color: #FFFFFF;  // Change text color on hover
  }
`;

//SOCIAL MEDIA CONTAINER

 const SocialMediaContainer = styled.div`

 display: flex;
 justify-content: start;
align-items: start;
// background-color: #00000020;
// border: 1px solid #CCC;
padding: 15px;
gap: 40px;
    h4{
        padding-top: 0px;
    }
@media (max-width: 769px) {
    gap: 20px;
    // padding: 10px 10px;
}
    width: auto;
 `

 const SocialIcon = styled.a`
  color: #FFFFFF40;          // Set icon color to black
  font-size: 32px;       // Set font size of the icon
  margin: 0 10px;        // Add horizontal spacing between icons
  transition: transform 0.3s, color 0.3s;  // Add smooth transitions

  &:hover {
    transform: scale(1.2);   // Scale up the icon on hover
    color: #FBAE62;          // Change color on hover (orange color)
  }
`;

///BOTTOM JOINT
const BottomInformation = styled.div`

${mobileDisplayContentVertical};
display: flex;
justify-content: start;
align-items: start;
// background-color: #00000020;
// border: 1px solid #CCC;
padding: 15px;
gap: 40px;
   h4{
       padding-top: 0px;
   }
@media (max-width: 769px) {
   gap: 20px;
   // padding: 10px 10px;
}
   width: auto;
`