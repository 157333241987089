import { Class } from "@mui/icons-material";
import { useSearchActions } from "third_party/redux/search/searchActions";
import { useSearch } from "third_party/redux/selectors";
import {parse, isValid,format, isAfter, isToday, formatISO, parseISO } from 'date-fns';

import GoogleMapsAddressInput from "components/Inputs/GoogleMapsAddressInput";


class SearchFunctions {

  
    static createDictionaryFromSearchCriteria(params ={}) {
      let updatedSearch = {};

       // Access properties directly from params
       const {place, date, duration } = params;
  
      // Only add properties if they have valid values
      if (place) {
        updatedSearch.location = place;
      }
      if (date) {
        updatedSearch.date = new Date(date);
      }
      if (duration) {
        updatedSearch.duration = parseInt(duration, 10);
      }
  
      return updatedSearch;
    }

    static createSearchCriteriaDictFromURLParams(params ={}) {
      const updatedSearch = {};

       // Access properties directly from params
       const {place, date, duration } = params;
  
      // Only add properties if they have valid values
      if (place) {
        updatedSearch.location = place;
      }
      if (date) {
        updatedSearch.date = new Date(date);
      }
      if (duration) {
        updatedSearch.duration = parseInt(duration, 10);
      }
      console.log("createSearchCriteriaDictFromURLParams: ",updatedSearch)
      return updatedSearch;
    }

    //###############
    //CART FUNCTIONS
    //###############


    static priceCartItems = (items) => {
        return '$0.00'
        //TODO: UPDATE

    }
    static countCartItems = (cart) => {
        return cart?.length || 0;
        //TODO: UPDATE

    }



    //###############
    //FUNCTIONS TO UPDATE SEARCH
    //###############
      static createEndDate = (date, durationInMinutes) => {
        const end = new Date(date)
        end.setMinutes(end.getMinutes()+ durationInMinutes)
        return end
      }

      //LOCATION
      static getAddressFromPlace = (place) => {
         // Check if place is null or empty
         if (!place || (typeof place === 'object' && Object.keys(place).length === 0)) {
          return "No Address Selected"; 
        }
        return this.getLine1FromPlace(place) + ', ' + this.getLine2FromPlace(place)
      }
      
      static getLine1FromPlace = (place) => {
         // Check if place is null or empty
        if (!place || (typeof place === 'object' && Object.keys(place).length === 0)) {
          return "Anywhere"; 
        }

        const streetNumber = place.streetNumber ? place.streetNumber + ' ' : '';
        const route = place.route ? place.route : '';
        return streetNumber + route.trim(); // Trim to remove any trailing whitespace

        
      };
      
      static getLine2FromPlace = (place) => {
        if (!place) return '';
        const locality = place.locality ? place.locality + ', ' : '';
        const adminArea1 = place.adminArea1 ? place.adminArea1 + ', ' : '';
        const postalCode = place.postalCode ? place.postalCode + ', ' : '';
        const country = place.country ? place.country : '';
        return locality + adminArea1 + postalCode + country;
      };
      
      //DATE
      static displayAbbreviatedWeekMonthDayTime = (displayDate) => {
        if (!displayDate) return 'Anytime';
    
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            weekday: 'short', // Adds the three-letter day of the week
            month: 'short',
            day: 'numeric',
        }).format(displayDate);
    
        const formattedTime = new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        }).format(displayDate);
    
        return `${formattedDate} ( ${formattedTime} )`;
    }

      static displayAbbreviatedMonthDayTime = (displayDate) => {

        const formattedDate = displayDate
        ? new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            }).format(displayDate)
        : 'Anydate';

        const formattedTime = displayDate
        ? new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            }).format(displayDate)
        : 'Anytime';
        return displayDate ? `${formattedDate} ( ${formattedTime} )` : 'Anytime';

      }

      static displayMonthDayYearTime = (date) => {

            const formattedDate = date
            ? new Intl.DateTimeFormat('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                }).format(date)
            : 'Anydate';

        const formattedTime = date
        ? new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            }).format(date)
        : 'Anytime';
        return date ? `${formattedDate} ( ${formattedTime} )` : 'Anytime';
      }

      static displayDate = (displayDate) => {

            const formattedDate = displayDate
            ? new Intl.DateTimeFormat('en-US', {
                // weekday: 'short',
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                }).format(displayDate)
            : 'Anydate';

            return displayDate ? `${formattedDate}` : 'Anydate';

      }

      static displayTime = (displayDate) => {

        const formattedTime = displayDate
        ? new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            }).format(displayDate)
        : 'Anytime';

            return displayDate ? `${formattedTime}` : 'Anytime';
      }

      static displayDuration = (displayDurationInMinutes) => {
        const hours = Math.floor(displayDurationInMinutes / 60);
        const minutes = displayDurationInMinutes % 60;
      
        if (hours === 0) {
          return `${minutes}m`;
        }
      
        return `${hours}hr${hours !== 1 ? 's' : ''}${minutes > 0 ? ` ${minutes}m` : ''}`;
      }

      static displayDateAndDuration = (date,duration) => {

        return this.displayMonthDayYearTime(date) + " | " + this.displayDuration(duration)

      }

      static getDateFromString = (isoString) => {
        try{
          const date = parseISO(isoString)
          if(date){
            return date
          }
          else{
            return null
          }
        }
        catch(error){
          console.log("error getting date from string: ",error)
          return null
        }
      }
  
      static getISOStringFromDate = (date) => {
        try{
            const isoString = formatISO(date)
            return isoString
        }
        catch(error){
            console.log("error getting ISOString from Date: ",error)
            return null
        }
      }

    //###############
    //FUNCTIONS TO UPDATE SEARCH
    //###############

    static getTimeNow = () => {
        const date = new Date();
        const minutes = date.getMinutes();
    
        // Calculate the additional minutes needed to round up to the nearest half hour
        const roundedMinutes = (Math.ceil(minutes / 30) * 30) % 60;
        const additionalHours = minutes >= 30 ? 1 : 0;
    
        // Set the minutes and increment hours if necessary
        date.setMinutes(roundedMinutes);
        if (roundedMinutes === 0) {
        date.setHours(date.getHours() + additionalHours);
        }
    
        return date;
    };

    static updateDateComponentWithDate = (date, newDate) => {
        // Logic to update date preserving existing time
        const currentDate = new Date(date);
        currentDate.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
        return currentDate;
      };

      static updateDateComponentWithTime = (date, newTime) => {
        // Logic to update time only
        const currentDate = new Date(date);
        //if isValid, added this clause
        if (newTime && isValid(newTime)) {
          currentDate.setHours(newTime.getHours(), newTime.getMinutes(), newTime.getSeconds());
        } else {
          // Reset time to midnight if newTime is cleared
          currentDate.setHours(0);
          currentDate.setMinutes(0);
          currentDate.setSeconds(0);
        }
        return currentDate;
      };


    static generateTimes = (referenceTime) => {
        const times = [];
        let startTime = 0; // 0 represents 12:00 AM
        
        // Check if the referenceTime is today
        const isReferenceTimeToday = isToday(referenceTime);
      
        for (let i = 0; i < 48; i++) {
          const hours = Math.floor(startTime / 60);
          const minutes = startTime % 60;
          const period = hours < 12 ? 'AM' : 'PM';
          const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
          const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
          const timeString = `${formattedHours}:${formattedMinutes} ${period}`;
      
          // Create a Date object for comparison
          const currentTime = new Date();
          currentTime.setHours(hours);
          currentTime.setMinutes(minutes);
          currentTime.setSeconds(0);
      
          if (isReferenceTimeToday) {
            // If the reference time is today, only include times after the current time
            if (isAfter(currentTime, new Date())) {
              times.push(timeString);
            }
          } else {
            // If the reference time is not today, include all times
            times.push(timeString);
          }
      
          startTime += 30; // Increment by 30 minutes
        }
      
        return times;
      };
      
      
      
      
    static formatTimeToString = (referenceTime) => {
        // Check if the referenceTime is valid
        if (!isValid(referenceTime)) {
          return 'now';
        }
        
        // Format the valid referenceTime into 'h:mm a'
        const formattedTime = format(referenceTime, 'h:mm a');
        
        return formattedTime;
      };

    static addMinutesToDate = (date, minutes) => {
        // Check if the date is valid, if not, use the current date
        const validDate = (date instanceof Date && !isNaN(date)) ? date : this.getTimeNow();
        
        // Check if minutes is a valid number, if not, default to 0
        const validMinutes = typeof minutes === 'number' && !isNaN(minutes) ? minutes : 0;
      
        const newDate = new Date(validDate.getTime() + validMinutes * 60000);  // 60000 ms in one minute
        return newDate;
      };


    //SEARCH FILTERS
    static filterServices = (services, filters) => {
        console.group("FILTER SERVICES FUNCTION")
        console.log("SERVICES: ",services)
        console.log("FILTERS: ",filters)
        console.groupEnd()
        // Return an empty array if services is undefined or not an array
        if (!Array.isArray(services) || services.length === 0) {
            return [];
        }
    
        return services.filter((item) => {
            // Category filters (e.g., string matching)
            const matchesCategory = Object.keys(filters.stringFilters).every((key) => {
                const filterValue = filters.stringFilters[key];
                // Check if the filter value is 'all' or if the filterValue exists in the item's category array
                return filterValue === 'all' || (filterValue ? item[key]?.includes(filterValue) : true);
            });
    
            // Range filters (e.g., price, quantity)
            const matchesRange = Object.keys(filters.rangeFilters).every((key) => {
                const range = filters.rangeFilters[key];
                // If range filter is defined, check if item value falls between min and max
                return range ? (item[key] >= range.min && item[key] <= range.max) : true;
            });
    
            // Boolean filters (e.g., canTravel)
            const matchesBoolean = Object.keys(filters.booleanFilters).every((key) =>
                filters.booleanFilters[key] !== undefined
                    ? item[key] === filters.booleanFilters[key]  // Apply boolean filter if defined
                    : true // If no filter value for this key, allow all items
            );
    
            // Return true if the item matches all the applied filters
            return matchesCategory && matchesRange && matchesBoolean;
        });
    };



    static searchParamsBasedOnDictionary = (paramsDict) => {
      const newParams = new URLSearchParams();
      if (paramsDict.location) {
        const address = SearchFunctions.getAddressFromPlace(paramsDict.location);
        if (address) newParams.set('address', JSON.stringify(address));
      }
      if (paramsDict.date) newParams.set('date', paramsDict.date.toISOString());
      if (paramsDict.duration) newParams.set('duration', paramsDict.duration.toString());
      return newParams.toString();
    }

  }
  
  export default SearchFunctions;