import React, { useRef, useEffect } from 'react';

import {styled, css, keyframes} from 'styled-components';
// import styled from 'styled-components';

import { NAVBAR_HEIGHT } from 'components/constants';
import { useState } from "react"

import GoogleMapsAddressInput from "components/Inputs/GoogleMapsAddressInput"

import GoogleMapsFunctions from 'utility/googleMapsFunctions';
// import {AddressSearchComponent} from "components/Inputs/AddressSearchComponent"
import SearchFunctions from 'utility/searchFunctions';
import { useSearch } from 'third_party/redux/selectors';
import { useSearchActions } from 'third_party/redux/search/searchActions';
import { useAppNavBarContext } from 'context/AppNavBarContext'; //search
// import { useUserAppSearchContext } from 'context/UserAppSearchContext';

import TopSearchPopUpEditor from '../SearchComponents/NewSearch/TopSearchPopUpEditor';
import { Z_INDEX_POPUP_MENUS } from 'components/constants';
import { useScreenContext } from 'context/AppContext/ScreenContext';

const SearchBarVertical = ({...props}) => {

   //context
   const tempSearchCriteria = useSearch().temp

   const {SEARCH_UPDATE,SEARCH_LOCATION} = useSearchActions()
const {isMobile, isMedium, isLarge} = useScreenContext()

   const {toggleSearchInput,setActiveSearchInput,//Determine which one is active, 'location','when','duration'
           resetContext,//reset all the contexts
           updateSearchLocationPredictions,//Search Predictions
           activeSearchInput//what is active
           // activeSearchInput, setActiveSearchInput
   } = useAppNavBarContext();

//use ref for location trigger joint
const locationInputRef = useRef(null);
// Use the `useEffect` hook to conditionally focus the input when the `shouldFocus` prop is true
useEffect(() => {
// if(isMedium || isLarge){
   console.log("is being triggered useEFFECT!")
   console.log("isLocationSearchVIsible: ", activeSearchInput==='location')
   console.log("locationInputRef.current: ", locationInputRef.current)

 if (activeSearchInput==='location' && locationInputRef.current) {
   console.log("HERE BRO!")
   locationInputRef.current.focus();

   // Move cursor to the end of the input field
   const inputLength = locationInputRef.current.value.length;
   locationInputRef.current.setSelectionRange(inputLength, inputLength);
 }
// }
}, [activeSearchInput]);


   //variables
   const [isDurationContentHighlighted, setIsDurationContentHighlighted] = useState();
   const [searchAddress ,setSearchAddress] = useState(""); //AJALON ADDED THIS



   const [popupPosition, setPopupPosition] = useState(null);
   const containerRef = useRef(null);
    const locationRef = useRef(null);
    const dateRef = useRef(null);
    const durationRef = useRef(null);


     // Function to handle place selection
     const handlePlaceSelected = (place) => {
       console.log('Place SELECTED BABY:', place);
       SEARCH_LOCATION(place)
       // closeSearchInputsViews()
       resetContext()
       setActiveSearchInput(null)
   };


   //Location Search Items Activated
   const LocationOnFocus = () => {
       // setActiveSearchInput('location')
       // setIsLocationSearchVisibleToVisible()
   }
   const LocationOnBlur = () => {
       // setIsLocationSearchVisible(false)
       setActiveSearchInput('location')

   }
   const handleLocationContainerClicked = () => {
        if (locationRef.current && containerRef.current) {
            const rect = locationRef.current.getBoundingClientRect();
            const containerRect = containerRef.current.getBoundingClientRect();

            console.log("rect: ",rect)
            console.log("containerRef: ", containerRect)
            setPopupPosition(rect.top + rect.height - containerRect.top); // Position it right below
        }
        
       toggleSearchInput('location')
       // setIsLocationSearchVisibleToVisible()
   }

   //When Activated
   const handleWhenContainerClicked = () => {
    if (dateRef.current && containerRef.current) {
        const rect = dateRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        setPopupPosition(rect.top + rect.height - containerRect.top); // Position it right below
    }
       toggleSearchInput('when')
       // toggleWhenSearch()
   }
   //Duration Activated
   const handleDurationContainerClicked = () => {

    if (durationRef.current && containerRef.current) {
        const rect = durationRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();

        setPopupPosition(rect.top + rect.height - containerRect.top); // Position it right below
    }
       toggleSearchInput('duration')
       // toggleDurationSearch()
   }
   const submitSearch = () => {
       // closeExpandedSearchEditMode()
       resetContext()
       SEARCH_UPDATE(tempSearchCriteria)
       console.log("TRIGGER SEARCH UPDATES")
   }



   const inputChange = (inputValue) => {
       console.log("SHIT CHANGED: ",inputValue)
       updateSearchLocationPredictions(inputValue)
       setActiveSearchInput('location')
     }

   return (
       <>
       <ExpandedContainer ref={containerRef} $isvisible={true} {...props}>

           <LocationContainer ref={locationRef} onClick={handleLocationContainerClicked} $isvisible={activeSearchInput==='location'}> 
               <InputContainer>
                   <SearchElementTitle>Where</SearchElementTitle>
                   <GoogleMapsAddressInputSpecial ref={locationInputRef} onPlaceSelected={handlePlaceSelected} value={SearchFunctions.getAddressFromPlace(tempSearchCriteria.location)} onFocus={LocationOnFocus} onBlur={LocationOnBlur} className='test' disableAutoComplete={true} onChange={inputChange}/>
                   {/* onSearchSubmit={handleSearchSubmit} */}
               </InputContainer>

           </LocationContainer>

           <Row>
            <DateContainer ref={dateRef} onClick={handleWhenContainerClicked} $isvisible={activeSearchInput==='when'}>
                <InputContainer>
                    <SearchElementTitle>When</SearchElementTitle>
                    <SearchElementP>{(SearchFunctions.displayAbbreviatedWeekMonthDayTime(tempSearchCriteria.date))}</SearchElementP>
                </InputContainer>
            </DateContainer>

            <DurationContainer ref={durationRef} onClick={handleDurationContainerClicked} $isvisible={activeSearchInput==='duration'}>
                    <InputContainer $isvisible={activeSearchInput==='location'}>
                        <SearchElementTitle>Duration</SearchElementTitle>
                        <SearchElementP>{SearchFunctions.displayDuration(tempSearchCriteria.duration)}</SearchElementP>
                    </InputContainer>
                </DurationContainer>
            </Row>

            <SearchButtonContainer>
                <SearchButton onClick={submitSearch}>
                    <img src="/images/searchicon.png" alt="searchicon" />
                    Update Search
                </SearchButton>
            </SearchButtonContainer>
            <TopSearchPopUpEditorSpecial $top={popupPosition}/>
       </ExpandedContainer>
       </>
   )
}
export default SearchBarVertical

const TopSearchPopUpEditorSpecial = styled(TopSearchPopUpEditor)`
width: 120% !important;
left: 0px !important;
top: ${props => props.$top ? `${props.$top}px !important` : '50% !important'};
box-shadow: 0 1px 3px 0 rgba(60, 64, 67, .3), 0 4px 8px 3px rgba(60, 64, 67, .15) !important;
border-radius: 25px;

`
const GoogleMapsAddressInputSpecial = styled(GoogleMapsAddressInput)`
    background-color: transparent;
    color: #00000080;
    font-size: .875em;
    height: auto;

    // &.input-please-work {
    //     background-color: red !important;
    //   }
`

const ExpandedContainer = styled.div`
display: flex;
position:relative;
flex-direction:column;
width: 100%;
top: ${props => (props.$isvisible ? '${NAVBAR_HEIGHT}' : '0')};
height: ${props => (props.$isvisible ? '${NAVBAR_HEIGHT}' : `0px`)};
opacity: ${props => (props.$isvisible ? '1' : '0')};
// margin: ${props => (props.$isvisible ? '15px 5px;' : '0px')};
align-items: center;
transition: opacity 0.3s ease, height 0.5s ease, top 0.3s ease;
border-radius: 15px;
// box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);
box-shadow: 0 0 1.5px #00000080;
background-color: ${props => (props.$isvisible ? '#EEE' : 'transparent')};
padding:5px;
gap: 5px;
// @media (max-width: 768px){
//     display: none;
// }

`

const InputContainer = styled.div`
display: flex;
flex-direction: column;
height: 80%;
width: 90%;
justify-content: center;
align-items: center;

`
const LocationContainer = styled.div`
display: flex;
flex-direction: column;
// min-width: calc(100% / 6 * 2);
width: 100%;
cursor: pointer;
min-height:${NAVBAR_HEIGHT};
padding: 5px 10px;
align-items: center;
justify-content: center;
background-color: ${props => (props.$isvisible ? 'white' : 'white')};
border-radius: 10px;
border: #CCC solid 1px;
z-index: ${props => (props.$isvisible ? `calc(${Z_INDEX_POPUP_MENUS} - 1)` : '0')};

&:hover {
    // background-color: #CCCCCC20;
    background-color: ${props => (props.$isvisible ? 'white' : '#CCCCCC20')};
    // border-radius: 25px;
}

`
const DateContainer = styled.div`
display: flex;
flex-direction: column;
// min-width: calc(100% / 6 * 2);
width: 100%;
cursor: pointer;
min-height:calc(${NAVBAR_HEIGHT});
padding: 5px 10px;
align-items: center;
justify-content: center;
background-color: ${props => (props.$isvisible ? 'white' : 'white')};
border-radius: 10px;
border: #CCC solid 1px;
flex-grow: 1;
flex-shrink: 1;
z-index: ${props => (props.$isvisible ? `calc(${Z_INDEX_POPUP_MENUS} - 1)` : '0')};


&:hover {
    // background-color: #CCCCCC20;
    background-color: ${props => (props.$isvisible ? 'white' : '#CCCCCC20')};
    // border-radius: 25px;
}

`

const DurationContainer = styled.div.attrs(props => ({
'data-highlighted': props.$ishighlighted ? 'true' : 'false',
  }))
`

display: flex;
flex-grow: 1;
flex-shrink: 1;
flex-direction: column;
// min-width: calc(100% / 6 * 2);
width: 60%;
cursor: pointer;
min-height:calc(${NAVBAR_HEIGHT});
padding: 5px 10px;
align-items: center;
justify-content: center;
background-color: ${props => (props.$isvisible ? 'white' : 'white')};
border-radius: 10px;
border: #CCC solid 1px;
z-index: ${props => (props.$isvisible ? `calc(${Z_INDEX_POPUP_MENUS} - 1)` : '0')};

&:hover {
    // background-color: #CCCCCC20;
    background-color: ${props => (props.$isvisible ? 'white' : '#CCCCCC20')};
    // border-radius: 25px;
}
`

const Row = styled.div`
display:flex;
width: 100%;
gap: 5px;
`
// const DurationContent = styled.div
//   `
// display: flex;
// flex-direction: column;
// flex-grow: 1;
// flex-shrink: 1;
// align-items: center;
// justify-content: center;


// `

const SearchButtonContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
min-width: calc(100% / 6);
width: 100%;
cursor: pointer;
align-items: center;
justify-content: center;
// padding: 15px;
img {
    display: block;
    width:30px;
    height:auto;
  }

`

const SearchButton = styled.div`
display: inline-flex;
align-items: center;
justify-content: center;
height: calc(${NAVBAR_HEIGHT});
width: 100%; /*wasn't 2 */
background-color: rgba(251, 174, 98, 1.0);
border-radius: 25px; /*50%*/
border: none;
cursor: pointer;
color: white;
font-weight: 600;
padding: 10px 40px;
max-width: 100%;
&:hover {
    background-color: rgba(251, 174, 98, 0.6);
}
`


const SearchElementP = styled.p`
color: #00000080;
font-size: .875em;
font-weight: 500;
margin: 0px;
`

const SearchElementTitle = styled.p`
color: #000000;
font-size: 12px;
font-weight: 200;
margin: 0px;
`