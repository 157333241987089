import React from 'react';

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

// Grid Section with dynamic layout
const GridSection = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => props.columns || 'repeat(4, 1fr)'};
  grid-template-rows: ${(props) => props.rows || 'repeat(3, 1fr)'};
  gap: ${(props) => props.gap || '15px'};
  padding-right: ${(props) => props.gap || '15px'};
  overflow: hidden;
`;

// GridItem: Container for both media and text
const GridItem = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #AAA;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  grid-column: ${(props) => props.gridColumn || 'span 1'};
  grid-row: ${(props) => props.gridRow || 'span 1'};
  // Ensure we do not pass these as plain DOM props
`;

// Styled Image
const GridItemImage = styled.img`
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover; // Ensures the image fills the space without distorting
  object-position: center; // Ensures the image is centered within its container
  // border-radius: 8px;
  overflow: hidden;

  // Dynamically apply grid column and row values
  // grid-column: ${(props) => props.gridColumn || 'span 1'};
  // grid-row: ${(props) => props.gridRow || 'span 1'};
`;

// Styled Video
// const GridItemVideo = styled.video`
//   width: 100%;
//   height: 100%;
//   min-width: 100%;
//   min-height: 100%;
//   object-fit: cover;
//   object-position: center;
//   overflow: hidden;
// `;

const GridItemVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  border: none; // Ensure no outline or border appears
  outline: none;
  display: block; // Prevent it from popping out of its container
`;

// Caption or Text Inside the Grid Item
const GridItemText = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.6); // Semi-transparent background for text visibility
  padding: 5px;
  border-radius: 4px;
`;

// List of file extensions for images and videos
const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'mkv', 'flv'];

const isVideo = (src) => {
  const ext = src.split('.').pop().toLowerCase(); // Extract the file extension
  return videoExtensions.includes(ext);
};




const ImageGridInteractive = ({ items, columns, rows, gap, ...props }) => {

  React.useEffect(() => {
    const videos = document.querySelectorAll('video');
    videos.forEach((video) => {
      video.muted = true; // Ensure it's muted
      video.play().catch(() => {
        console.log('Autoplay failed on mobile');
      });
    });
  }, []);


  return (
    <GridSection columns={columns} rows={rows} gap={gap} {...props}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <GridItem
            gridColumn={item.gridColumn}
            gridRow={item.gridRow}
          >
            {isVideo(item.src) ? (
              <GridItemVideo
                src={item.src}
                autoPlay
                loop
                muted
                playsInline
              />
            ) : (
              <GridItemImage
                src={item.src}
                alt={`item-${index}`}
              />
            )}
            {item.caption && <GridItemText>{item.caption}</GridItemText>}
          </GridItem>
        </React.Fragment>
      ))}
    </GridSection>
  );
};

export default ImageGridInteractive;




// <GoghNowExperienceGridItem>
// <ImageGridInteractive items={isMedium ? images1Mobile : images1} columns="repeat(3, 1fr)" rows="repeat(4, 1fr)" gap="5px" layout="custom1" />
// </GoghNowExperienceGridItem>
// <GoghNowExperienceGridItem>
// <SpecialImageGridInteractive items={isMedium ? images2Mobile : images2}columns="repeat(4, 1fr)" rows="repeat(4, 1fr)" gap="5px" layout="custom1" />
// </GoghNowExperienceGridItem>
// <GoghNowExperienceGridItem>
// <SpecialImageGridInteractive items={images3} columns="repeat(4, 1fr)" rows="repeat(4, 1fr)" gap="5px" layout="custom1" />
// </GoghNowExperienceGridItem>


// const GoghNowExperienceGridItem = styled.div`
//     display: flex;
//     position: relative;
//     // justify-content: center;
//     // align-items: center;
//     width: 100dvw; // Full viewport width for each section
//     height: 100dvh; // Full viewport height
//     overflow: hidden;
//     color: white;
//     h2{
//     color: white;
//     }
//     background-color: transparent; // Example background color for visual clarity
// `

//   //Page 1 Layout (4 Rows x 3 Columns = 12 Slots)
//   const images1 = [
//     { src: 'images/Home/homegallery/i-v-mobilebar.jpeg',caption:'Mobile Bar', gridColumn:' 2 / span 1', gridRow:'1 / span 2'}, // Larger image
//     { src: 'images/Home/homegallery/i-h-decorsetup.jpeg',caption:'Balloon Decor', gridColumn: '2/ span 2', gridRow: '3 / span 2' },
//     { src: 'images/Home/homegallery/v-v-violinistmiami.mp4',caption:'Violinist', gridColumn: '1 / span 1', gridRow: '1 / span 4' },
//     { src: 'images/Home/homegallery/v-v-flowers1.mp4',caption:'Flowers', gridColumn: '3 / span 1', gridRow: '1 / span 2' },
//   ];
  
//   const images1Mobile = [
//     { src: 'images/Home/homegallery/i-v-mobilebar.jpeg',caption:'Mobile Bar', gridColumn:' 3 / span 1', gridRow:'1 / span 2'}, // Larger image
//     // { src: 'images/Home/homegallery/i-h-decorsetup.jpeg',caption:'photo 1', gridColumn: '2/ span 2', gridRow: '3 / span 2' },
//     { src: 'images/Home/homegallery/v-v-violinistmiami.mp4',caption:'Violinist', gridColumn: '1 / span 2', gridRow: '1 / span 4' },
//     { src: 'images/Home/homegallery/v-v-flowers1.mp4',caption:'Flowers', gridColumn: '3 / span 1', gridRow: '3 / span 2' },
//   ];
//   //Page 2 Layout (4 Rows x 4 Columns = 12 Slots)

//   const images2 = [
//     { src: 'images/Home/homegallery/i-h-balloongarland2.jpeg',caption:'Balloon Arch', gridColumn:' 1 / span 2', gridRow:'1 / span 2'}, // Larger image
//     { src: 'images/Home/homegallery/i-h-flowers2.jpeg',caption:'Decorative Flowers', gridColumn: '2/ span 3', gridRow: '3 / span 2' },
//     { src: 'images/Home/homegallery/v-v-djrestaurant.mp4',caption:'DJ', gridColumn: '1 / span 1', gridRow: '3 / span 2' },
//     { src: 'images/Home/homegallery/v-h-trumpet.mp4',caption:'Musician (Accoustic)', gridColumn: '3 / span 2', gridRow: '1 / span 2' },
//   ];

//   const images2Mobile = [
//     { src: 'images/Home/homegallery/i-h-balloongarland2.jpeg',caption:'Balloon Arch', gridColumn:' 1 / span 2', gridRow:'1 / span 2'}, // Larger image
//     { src: 'images/Home/homegallery/i-h-flowers2.jpeg',caption:'Flowers', gridColumn: '4/ span 1', gridRow: '3 / span 2' },
//     { src: 'images/Home/homegallery/v-v-djrestaurant.mp4',caption:'DJ', gridColumn: '1 / span 3', gridRow: '3 / span 2' },
//     { src: 'images/Home/homegallery/v-h-trumpet.mp4',caption:'Musician', gridColumn: '3 / span 2', gridRow: '1 / span 2' },
//   ];
 
  

//   //Page 3 Layout (4 Rows x 4 Columns = 15 Slots)
//   const images3 = [
//     { src: 'images/Home/homegallery/v-h-mariachi2.mp4',caption:'Mariachi Band', gridColumn:' 1 / span 3', gridRow:'1 / span 2'}, // Larger image
//     { src: 'images/Home/homegallery/v-v-bartenders.mp4',caption:'Bartenders', gridColumn: '1/ span 1', gridRow: '3 / span 2' },
//     { src: 'images/Home/homegallery/i-h-sax.jpeg',caption:'Saxophonist', gridColumn: '2 / span 3', gridRow: '3 / span 2' },
//     { src: 'images/Home/homegallery/i-v-balloongarland.jpg',caption:'Balloon Garland', gridColumn: '4 / span 1', gridRow: '1 / span 2' },
//   ];
