import React, {useEffect } from 'react';
import styled, {css, keyframes} from 'styled-components';
import { NAVBAR_HEIGHT } from "components/constants";


const MobileSearchContainerComponent = ({leftText, rightText,titleText, isExpanded, toggleState,  children}) => {



return (

    <Container $isExpanded = {isExpanded}>
        <CompactContainer
            $isExpanded = {!isExpanded}
            onClick = {toggleState}
        >
        <p>{leftText}</p>
        <p>{rightText}</p>
        </CompactContainer>
        <ExpandedContainer $isExpanded = {isExpanded}>
            <SearchElementTitle>{leftText}</SearchElementTitle>
            {children}
        </ExpandedContainer>
    </Container>
)
}

export default MobileSearchContainerComponent

const Container = styled.div`
    display: flex;
    position: relative;
    min-height: ${NAVBAR_HEIGHT};
    width: 100%;
    max-height: 100%;
    // margin : 5px 15px;
    cursor: pointer;
    flex-grow: ${({ $isExpanded }) => ($isExpanded ? 1 : 0)};
    // background-color: red;
`
const CompactContainer = styled.div`
    display: ${({ $isExpanded }) => ($isExpanded ? 'flex' : 'none')};
    position: absolute;
    top: 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 35px;
    height: ${NAVBAR_HEIGHT};
    width: 100%;
    border-radius: 20px;
    background-color: #CCCCCC60;

    p{
    font-size: 0.875em;
    }
`

const ExpandedContainer = styled.div`
    display: ${({ $isExpanded }) => ($isExpanded ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    background-color: white;
    flex-grow: 1;
    align-items: center;
    // justify-content: center;
    overflow-y: scroll;

`
const SearchElementTitle = styled.p`
color: #AAA;
font-size: 14px;
font-weight: 200;
// margin: 0px;
`
// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//     display: flex;
//     height: calc(${NAVBAR_HEIGHT}); //was 0 initially
//   }
//   to {
//     opacity: 1;
//     height: calc(${NAVBAR_HEIGHT});
//   }
// `;

// const fadeOut = keyframes`
//   from {
//     opacity: 1;
//     height: calc(${NAVBAR_HEIGHT});
//   }
//   to {
//     opacity: 0;
//     height: 0;
//     display: none;
//   }
// `;



// const expandedfadeIn = keyframes`
//   from {
//     opacity: 0;
//     display: flex;
//     min-height: calc(${NAVBAR_HEIGHT}); //was 0 initially
//   }
//   to {
//     opacity: 1;
//     height: 100%;
//   }
// `;

// const expandedfadeOut = keyframes`
//   from {
//     opacity: 1;
//     height: 10%;
//   }
//   to {
//     opacity: 0;
//     min-height: ${NAVBAR_HEIGHT};
//     display: none;
//   }
// `;