
import SearchFunctions from "utility/searchFunctions";
import { ObjectSearchCriteriaClass } from "./objectSearchCriteria";
import { BaseModel } from "model/base";




export interface objectSearch {
  current: ObjectSearchCriteriaClass;  // This should use ObjectSearchCriteriaClass
  temp: ObjectSearchCriteriaClass;     // Same here
  results: any[];                      // Define the results type properly, using any[] for now
  searchType: string;
  aiUserPrompt:string;
  aiResponse:string;
  aiRecommendations:any[];
}

export class ObjectSearchClass extends BaseModel implements objectSearch {
  current: ObjectSearchCriteriaClass;  // Correct class reference here
  temp: ObjectSearchCriteriaClass;
  results: any[];
  searchType: string = 'AI';
  aiUserPrompt:string = '';
  aiResponse:string = '';
  aiRecommendations:any[];

  constructor(data: Partial<objectSearch> = {}) {
    super(data); // Call the constructor of BaseModel with 'data'
    this.current = data.current || new ObjectSearchCriteriaClass();
    this.temp = data.temp || new ObjectSearchCriteriaClass();
    this.results = data.results || [];  // Default empty array for results
    this.searchType = data.searchType || 'Search';
    this.aiUserPrompt = data.aiUserPrompt ||'';
    this.aiResponse = data.aiResponse || '';
    this.aiRecommendations = data.aiRecommendations || [];
  }
}