
//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//Constants
import { NAVBAR_HEIGHT } from 'components/constants';

//ELEMENTS
import {buttonSubmitStyle} from 'components/Buttons/ButtonStyles'
import TextField from '@mui/material/TextField';
import { textFieldStyle } from "components/Inputs/InputStyles";

//useScreen
import { useScreenContext } from 'context/AppContext/ScreenContext';
import { useState } from 'react';


interface UserData {
    first_name: string;
    last_name: string;
  }
  
  const UpdateLegalName = () => {
    const [data, setData] = useState<UserData>({
      first_name: "",
      last_name: "",
    });
  
    const handleChange = (value: string, field: keyof UserData) => {
      setData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };
  
    const handleSubmit = () => {
      console.log("Updated Data:", data);
      // Call your API or perform the necessary action with the updated data
    };
  
    return (
      <Container>
            <ValueText>Make sure this matches the name on your government ID.</ValueText>
        <ContentContainer>
          <TextFieldCustom
            id="first_name"
            label="First Name"
            value={data.first_name}
            onChange={(e) => handleChange(e.target.value, "first_name")}
            placeholder="First Name"
            sx={textFieldStyle}
          />
          <TextFieldCustom
            id="last_name"
            label="Last Name"
            value={data.last_name}
            onChange={(e) => handleChange(e.target.value, "last_name")}
            placeholder="Last Name"
            sx={textFieldStyle}
          />
        </ContentContainer>
        <SubmitButton onClick={handleSubmit}>Update</SubmitButton>
      </Container>
    );
  };

export default UpdateLegalName

const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
min-height: ${NAVBAR_HEIGHT};
`
const ValueText = styled.p`
  color:#00000080;
`
const ContentContainer = styled.div`
display: flex;
width: 100%;
gap:15px;
`

const TextFieldCustom = styled(TextField)`
width: 100%;
flex: 1;
`

//END OF NAVBAR
const SubmitButton = styled.button`
${buttonSubmitStyle}
margin-top: 10px;
width: 100%;
margin-top: 20px;
`