import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuthContext } from "./AppContext/AuthContext";
import GoogleMapsFunctions from "utility/googleMapsFunctions";

const AppNavBarContext = createContext();
export const useAppNavBarContext = () => useContext(AppNavBarContext);

export const AppNavBarManagerProvider = ({ children }) => {
    const { resetAllContexts } = useAuthContext();

    const [searchBarState, setSearchBarState] = useState("compact");
    const [activeSearchInput, setActiveSearchInput] = useState(null);
    // const [isCartMenuOpen, setIsCartMenuOpen] = useState(false);
    const [searchLocationInput, setSearchLocationInput] = useState("");
    const [searchLocationPredictions, setSearchLocationPredictions] = useState([]);
    const [canToggleBasedOnScroll, setCanToggleBasedOnScroll] = useState(true);//ADDED

    // const toggleCartMenu = () => {
    //     setIsCartMenuOpen(prev => (prev === false ? true : false));
    // };

    //update canToggleScroll When searchBarState is updated
    useEffect(() => {
        if (activeSearchInput === null) {
            setCanToggleBasedOnScroll(true); // Don't allow toggling when the state is 'interactive'
            console.log("canToggleBasedOnScroll INSIDE CONTEXT: ",true)

        } else{
            setCanToggleBasedOnScroll(false); // Allow toggling when the state is 'compact'
            console.log("canToggleBasedOnScroll INSIDE CONTEXT: ",false)

        }
    }, [activeSearchInput]); // This effect runs whenever searchBarState changes


    const toggleSearchInput = (input) => {
        // setActiveSearchInput(prev => (prev === input ? null : input));
        setActiveSearchInput(prev => {
            const newState = prev === input ? null : input;
            if (newState) {
                setSearchBarState('interactive'); // Set the search bar state to 'interactive' when toggling an input on
            }
            return newState;
        });
    };

    const resetContext = () => {
        setSearchBarState("compact");
        setActiveSearchInput(null);
        // setIsCartMenuOpen(false);
    };

    useEffect(() => {
        if (resetAllContexts) resetContext();
    }, [resetAllContexts]);

    const updateSearchLocationPredictions = async (search) => {
        setSearchLocationInput(search);
        try {
            const predictions = await GoogleMapsFunctions.fetchPredictions(search);
            setSearchLocationPredictions(predictions);
        } catch (error) {
            console.error("Error fetching predictions:", error);
            setSearchLocationPredictions([]);
        }
    };

    return (
        <AppNavBarContext.Provider
            value={{
                searchBarState,
                setSearchBarState,
                // toggleSearchBarState,
                activeSearchInput,
                toggleSearchInput,setActiveSearchInput,
                // isCartMenuOpen,
                // setIsCartMenuOpen,
                // toggleCartMenu,
                resetContext,
                searchLocationInput,
                searchLocationPredictions,
                updateSearchLocationPredictions,
                canToggleBasedOnScroll,
                setCanToggleBasedOnScroll
            }}
        >
            {children}
        </AppNavBarContext.Provider>
    );
};

// import React, { createContext, useState, useContext, useEffect } from 'react';
// // import { useCart } from './CartContext';
// import { useAuthContext } from './AppContext/AuthContext';
// import GoogleMapsFunctions from 'utility/googleMapsFunctions';

// const AppNavBarContext = createContext();

// export const useAppNavBarContext = () => useContext(AppNavBarContext);
// // export const WidgetManagerContext = createContext();


// export const AppNavBarManagerProvider = ({ children}) => {

//     const { resetAllContexts} = useAuthContext();
   
//     //NEED
//     const [isInteractiveSearchBarVisible, setIsInteractiveSearchBarVisible] = useState(true);
    

//     const [isCompactSearchBarVisible, setIsCompactSearchBarVisible] = useState(false);
//     const [canToggleBasedOnScroll, setCanToggleBasedOnScroll] = useState(true);
//     const [showOverlay, setShowOverlay] = useState(false);

    
//     // const toggleInteractiveSearchBar = () => {
//     //     console.log("searchBarBeingToggled")
//     //     setIsInteractiveSearchBarVisible(prevState => !prevState);
//     //     setIsCompactSearchBarVisible(prevState => !prevState);
//     // }



//   //END OF SCROLL FUNCTION HANDLER

//      //Reset Context
//     const resetContext = () => {
//         closeExpandedSearchEditMode();
//         closeCartMenu();
//     }


//     useEffect(() => {
//     if (resetAllContexts) {
//         resetContext();
//     }
//     }, [resetAllContexts]);


//     const scrollToOpenInteractiveSearch = () => {

//         setIsInteractiveSearchBarVisible(true);
//         setIsCompactSearchBarVisible(false);
//     }

//     const scrollToCondenseInteractiveSearch = () => {
        
//         setIsInteractiveSearchBarVisible(false);
//         setIsCompactSearchBarVisible(true);
//         closeSearchInputsViews();
//     }

//     const openExpandedSearch = ({ location = false, when = false, duration = false } = {}) => {
        
//         setCanToggleBasedOnScroll(false)
//         setShowOverlay(true)
//         //
//         setIsInteractiveSearchBarVisible(true);
//         setIsCompactSearchBarVisible(false);

//         if (location){
//             toggleLocationSearch();
//             return;
//         }
//         if (when){
//             toggleWhenSearch();
//             return;
//         }

//         if (duration){
//             toggleDurationSearch();
//             return;
//         }

//     }
//     const closeExpandedSearchEditMode = () => {
//         setCanToggleBasedOnScroll(true) //this is new
//         setShowOverlay(false)
//         //
//         setIsInteractiveSearchBarVisible(false);
//         setIsCompactSearchBarVisible(true);
//         closeSearchInputsViews();
//     }



//     //SEARCH INPUT STATES
//     const [isLocationSearchVisible, setIsLocationSearchVisible] = useState(false);
//     const [isWhenSearchVisible, setIsWhenSearchVisible] = useState(false);
//     const [isDurationSearchVisible, setIsDurationSearchVisible] = useState(false);

//     const toggleLocationSearch = () => {
//         setIsLocationSearchVisible(prevState => !prevState);
//         setIsWhenSearchVisible(false)
//         setIsDurationSearchVisible(false)
//         // console.log("toggleLocationSearch: ")
//     }
//     //below is a special case for address bar
//     const setIsLocationSearchVisibleToVisible = () => {
//         setIsLocationSearchVisible(true);
//         setIsWhenSearchVisible(false)
//         setIsDurationSearchVisible(false)
//     }

//     const toggleWhenSearch = () => { 
//         setIsLocationSearchVisible(false)
//         setIsWhenSearchVisible(prevState => !prevState);
//         setIsDurationSearchVisible(false)
//         // console.log("toggleWhenSearch: ",isWhenSearchVisible)

//     }
//     const toggleDurationSearch = () => {
//         setIsLocationSearchVisible(false)
//         setIsWhenSearchVisible(false)
//         setIsDurationSearchVisible(prevState => !prevState);
//         // console.log("toggleDurationSearch")

//     }

//     const closeSearchInputsViews = () => {
//         setIsLocationSearchVisible(false);
//         setIsWhenSearchVisible(false);
//         setIsDurationSearchVisible(false);

//         //CLEAR THE SEARCH CRITERIA JOINT
//     }

//     //CART MENU STATES
//     const [isCartMenuOpen, setIsCartMenuOpen] = useState(false);
//     const toggleCartMenu = () => {
//         setIsCartMenuOpen(prevState => !prevState);
//         console.log('isCartMenuOpen', isCartMenuOpen);
//     };
//     const closeCartMenu = () => setIsCartMenuOpen(false);


//     // const { cartItems } = useCart();
//     const [isAnySearchInputVisible, setIsAnySearchInputVisible] = useState(false);
//     useEffect(() => {
//         setIsAnySearchInputVisible(isLocationSearchVisible || isWhenSearchVisible || isDurationSearchVisible);
//       }, [isLocationSearchVisible, isWhenSearchVisible, isDurationSearchVisible]);



//     //Search Location
//     const [searchLocationInput, setSearchLocationInput] = useState('')
//     const [searchLocationPredictions, setSearchLocationPredictions] = useState([])

//     const updateSearchLocationPredictions = (search) => {
//         setSearchLocationInput(search)
//         GoogleMapsFunctions.fetchPredictions(search)
//         .then(predictions => {
//           console.log('Predictions:', predictions);
//           setSearchLocationPredictions(predictions)
//         })
//         .catch(error => {
//           console.error('Error fetching predictions:', error);
//           setSearchLocationPredictions([])
//         });
//     }

//     return (
//         <AppNavBarContext.Provider
//             value={{ 
//                 //SEARCH ITEMS HERE
//                 isInteractiveSearchBarVisible,
//                 isCompactSearchBarVisible,
//                 openExpandedSearch,
//                 closeExpandedSearchEditMode,
//                 // handleScroll,
//                 //CART MENU STATES
//                 isCartMenuOpen,
//                 toggleCartMenu,
//                 closeCartMenu,
//                 //SEARCH INPUT STATES
//                 setIsLocationSearchVisibleToVisible,
//                 isLocationSearchVisible,
//                 isWhenSearchVisible,
//                 isDurationSearchVisible,
//                 toggleLocationSearch,
//                 toggleWhenSearch,
//                 toggleDurationSearch,
//                 closeSearchInputsViews,
//                 setCanToggleBasedOnScroll,
//                 canToggleBasedOnScroll,
//                 scrollToOpenInteractiveSearch,
//                 scrollToCondenseInteractiveSearch,
//                 showOverlay,
//                 setShowOverlay,
//                 isAnySearchInputVisible,
//                 updateSearchLocationPredictions,
//                 searchLocationPredictions
//              }}
//         >
//             {children}
//         </AppNavBarContext.Provider>
//     );
// };