//CONSTANTS
import { NAVBAR_HEIGHT } from "components/constants";

//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//React
import {useState } from "react";
import { useNavigate, Link } from 'react-router-dom';


import { HorizontalLine, BORDERS, ContainerGeneral, InnerContainer } from 'apps/User/UserAppConstants';
import { mobileDisplayContentVertical } from "components/emotionStyles";

const UserProfilePublicComponent= ({name = 'Ajalon E.',bookings = 0, services = 0, details=null, profile_picture=''}) => {

    return (
        <Container>
            <MainProfileContainer>

                <ProfileAndNameContainer>
                    <ProfilePicture>
                        <img src={profile_picture}/>
                    </ProfilePicture>
                    <Name>{name}</Name>
                </ProfileAndNameContainer>

                <StatContainer>
                    <BookingsContainer>
                        <Value>{bookings}</Value>
                        <ValueDescription>Bookings</ValueDescription>
                    </BookingsContainer>
  
                    <BookingsContainer>
                        <Value>{services}</Value>
                        <ValueDescription>Services Booked</ValueDescription>
                    </BookingsContainer>
                </StatContainer>


            </MainProfileContainer>
            <HorizontalLine/>
            <SpecialInnerContainer>
                <DescriptionText $isProvided={details !== null}>
                    {details !== null ? details : "No Information Provided"}
                </DescriptionText>
            </SpecialInnerContainer>
        </Container>
    )
} 

export default UserProfilePublicComponent

const Container = styled.div`

${ContainerGeneral};
// border: ${BORDERS.elementBorder};
border-radius: 25px;
p{
color: black;
}
`

//TOP LAYER ITEMS
const MainProfileContainer = styled.div`
display:flex;
padding: 15px;
align-items: center;
// border: ${BORDERS.elementBorder};
margin: 25px;
border-radius: 50px;
background-color: #AAAAAA20;
justify-content: space-between;
gap: 25px;
`
//PROFILE PIC AND NAME
const ProfileAndNameContainer = styled.div`
display:flex: 
flex-direction: column;
`

const ProfilePicture = styled.div`
display: flex;
width: 150px;
aspect-ratio: 1 / 1;
border: ${BORDERS.elementBorder};
border-radius: 50%;
 background-image: url('/images/apps/user/no-pic.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  img {
    width: 100%; /* Ensure the image covers the full width */
    height: 100%; /* Ensure the image covers the full height */
    object-fit: cover; /* Maintain aspect ratio and fill the container */
    object-position: center; /* Center the image */
  }
`

const Name = styled.h3`
color: black;
margin-left: auto;
margin-right: auto;
width: 100%;
text-align: center;
`

//BOOKINGS CONTAINER
const StatContainer = styled.div`
${mobileDisplayContentVertical};
display:flex;
flex-grow:1;
justify-content: space-evenly;

`
const BookingsContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
cursor: pointer;
// color: black;
`
const Value = styled.h2`
color: black;
margin: 0px;
font-weight: bold;
`
const ValueDescription = styled.p`
color:  #00000080 !important;
margin-top: 5px;
text-align: center;
`

const SpecialInnerContainer = styled.div`
${InnerContainer}
`

const DescriptionText = styled.p`
  color: ${(props) => (props.$isProvided ? 'black' : '#00000060')};
`;