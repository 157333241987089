// import React from 'react';
import { css } from '@emotion/react';

let mobileMax = '450px' //769px
let mediumMax = '1024px'
let largeMin = '1025px'
// let mediumMax = '1024'

//Hides mobile styled
export const mobileSizeDisplayNone = css`
@media (max-width: ${mobileMax}) {
    display: none;
    // background-color: red;
}
`

export const mobileSizeDisplayFlex = css`
@media (max-width: ${mobileMax}) {
    display: flex;
    // background-color: green;

}
@media (min-width: ${mobileMax}) {
    display: none !important;
    // background-color: orange;
}
`

export const mobileSizeDisplayBlock = css`
@media (max-width: ${mobileMax}) {
    display: block;
}`
export const mobileDisplayContentVertical = css`
  @media (max-width: ${mobileMax}) {
    flex-direction: column;
}
`

export const mobileDisplayContentHorizontal = css`
  @media (max-width: ${mobileMax}) {
    flex-direction: row;
}
`;

export const mediumDisplayContentHorizontal = css`
  @media(max-width: ${mediumMax}) {
  
  flex-direction:row;
  }

`


const mobile = css`
  @media (max-width: ${mobileMax}) {
    display: none;
  }
`;


//medium styles
export const medium = css`
  @media (min-width: ${mobileMax}) and (max-width: ${mediumMax}) {
    background-color: lightyellow;
    padding: 15px;
  }
`;


//large styles
export const large = css`
  @media (min-width: ${largeMin}) {
    background-color: lightgreen;
    padding: 20px;
  }
`;


//Examples
export const baseContainerStyle = css`
  width: 100%;
  padding: 20px;
`;

export const mediaMaxWidth768 = css`
  @media (max-width: ${mobileMax}) {
    background-color: lightpink;
    padding: 10px;
  }
`;

export const mediaMinWidth769MaxWidth1024 = css`
  @media (min-width: ${mobileMax}) and (max-width: ${mediumMax}) {
    background-color: lightyellow;
    padding: 15px;
  }
`;

export const mediaMinWidth1025 = css`
  @media (min-width: ${largeMin}) {
    background-color: lightgreen;
    padding: 20px;
  }
`;


//ADD TO EVERY BACKGROUND TO MAKE THE SITE CLEANER
export const frostedGlassStyle = css`
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.3);
  // border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #000; // Text color
`;

// Frosted Container Style
export const frostedContainerStyle = css`

background: 
    radial-gradient(circle at 15% 20%, #ecd8ba80 7%, transparent 25%), /* Small splash */
    radial-gradient(circle at 85% 15%, #fdd4a575 8%, transparent 12%), /* Smaller splash, moved right */
    radial-gradient(circle at 72% 85%, #a3d3d790 5%, transparent 28%), /* Medium splash, moved down and left */
    radial-gradient(circle at 10% 70%, #8f86a875 3%, transparent 17%), /* Medium splash, moved left */
    #ffffff; /* Predominantly white background */
  // background-blend-mode: lighten, normal;
  background-size: cover;
  background-repeat: no-repeat;

  animation: gradient-shift 10s ease infinite;

  @keyframes gradient-shift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;