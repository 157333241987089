import { get, post, put } from 'api/apiRequests'; // Assuming your `apiCalls.js` file is in the same directory
import { ObjectUserProfile } from 'model/objectUserProfile';
import { Dict } from 'styled-components/dist/types';

// let getProfileURL = 'user/profile'
// let saveProfileURL = 'user/profile'


type ProfileResult = ObjectUserProfile

//URLS
let urlGetProfile = "provider/profile"; // GET
let urlSaveProfile =  "provider/profile"; //POST
    
class ProfileServiceAPI {

    getProfile = async(): Promise<ProfileResult> =>{
        try {
          const url = urlGetProfile
          const params =  { } //noParams, passed through token
          const response = await get(url,params);
          return response //.success; // Assuming the API returns a JSON with a 'success' boolean
        } catch (error) {
          console.error('Error validating invite code:', error);
          throw error; // Re-throw the error for handling in the calling component
        }
      }



      savePofile = async(data : {}): Promise<ProfileResult> =>{
        try {
          const url = urlSaveProfile
          const params =  data //noParams, passed through token
          const response = await put(url,params);
          return response //.success; // Assuming the API returns a JSON with a 'success' boolean
        } catch (error) {
          console.error('Error validating invite code:', error);
          throw error; // Re-throw the error for handling in the calling component
        }
      }

}

export default ProfileServiceAPI