//React
import React, {useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

//HomeURLProvider
import { HomeURLProvider } from "context/URL/HomeURLContext";


//Common Components
// import { PageContainer } from "components/Page/Elements";
import {PageContentContainer} from "components/Page/Elements"

//Redux
import { useCartActions } from "third_party/redux/cart/cartActions";
import { useCart } from "third_party/redux/selectors";

import {useSearch } from "third_party/redux/selectors";
import { useSearchActions } from "third_party/redux/search/searchActions";

import { useSearchFilter } from "third_party/redux/selectors";
import { useSearchFilterActions } from "third_party/redux/searchFilter/searchFilterActions";

import {useAppNavBarContext} from "context/AppNavBarContext";
import { useUserServiceContext } from "api/User/Deprecated/UserServiceAPIContext";

// import {keyframes} from "styled-components"; //CHANGE TO EMOTION
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

import IconNavBarComponent from 'pages/home/components/NavBarIconComponents/IconNavBarComponent';
import TrendyPackageComponent from './ContentSections/TrendingPackagesComponents/TrendyPackageComponent';
import EventServicesComponent from './ContentSections/EventServicesComponents/EventServicesComponent';
//CART CONTEXT
import CartMenu from "components/Cart/CartMenu"

//JUST ADDED 
// import NavBarLogin from "../../pages/home/header/NavBarLogin"
// import SearchInputContainer from "pages/home/header/PopUpInteractiveComponents/SearchInputContainer";

//INPUT COMPONENTS
import DateTimeInput from './SearchComponents/DateTimeSearchComponent/DateTimeInput';
import DurationInput from "./SearchComponents/DurationSearchComponent/DurationInput";
import LocationInput from "./SearchComponents/LocationSearchComponent/LocationInput";


import CartButton from "components/Cart/CartButton"
import { NAVBAR_HEIGHT } from "components/constants";
import { useScreenContext } from 'context/AppContext/ScreenContext';
import MobileSearchContainer from 'apps/User/SearchComponents/MobileSearchContainer';
import Overlay from "components/Containers/Overlay";

import ConstantsAPI from "api/User/ConstantsAPI";



// import { useDispatch } from 'react-redux';
// import { deleteCartItem } from 'third_party/redux/cart/cartActions';

import SearchFunctions from "utility/searchFunctions";
import NewTopSearchComponent from './SearchComponents/NewSearch/TopSearch';
import NewSideSearchComponent from './SearchComponents/NewSearch/SideSearch';

const UserAppContent = (props) => {

//control search bar

    //navigate
    const navigate = useNavigate();
    const [initialLoad, setInitialLoad] = useState(true); // Track if it's the first load

    //Search Context For Items
    const {SEARCH_UPDATE_TEMP, SEARCH_LOCATION, SEARCH_RESULTS_UPDATE, SEARCH_AI_USER_PROMPT} = useSearchActions()
    const searchCriteria = useSearch()?.current; //|| {}; // Safe fallback to an empty object
    const all_available_services = useSearch().results
    const searchData = useSearch()
    const [filteredServices, setFilteredServices] = useState([])

    //make things appear or disappear based on this
    const isLocationSet = searchCriteria.location && Object.keys(searchCriteria.location).length > 0;


    //Search Filter
    const {FILTER_RESET} = useSearchFilterActions()
    const searchFilters = useSearchFilter()

    const pageContentRef = useRef(null);  // Ref for PageContentContainer
    const containerRef = useRef(null);
    const handleClickOutside = (event) => {
        console.log("HERE!!!")
        if (containerRef.current && containerRef.current === event.target) {
            // closeExpandedSearchEditMode()

            //NEW
            // Reset search bar state to compact
            setSearchBarState("compact");

            // Reset active search input
            setActiveSearchInput(null);
            //canToggleBasedOnScroll is True TODO: ADD THIS

            // Close the cart menu if it's open
            // setIsCartMenuOpen(false);


            console.log('Container closed (click outside)');
          } else {
            console.log('Container open (click inside)');
          }
      };
      const setLocation = (place) => {
        SEARCH_LOCATION(place)
        console.log("LOCATION BEING SET BABY")
        // closeSearchInputsViews()
        toggleSearchInput(null)
      }

    //CART AND INTERACTIVE JOINT
    const {isCartMenuOpen,setIsCartMenuOpen,toggleCartMenu,
      toggleSearchInput,
      setSearchBarState,searchBarState, //compact or interactive
      activeSearchInput, setActiveSearchInput, //which input is showing
      canToggleBasedOnScroll,//adjusted based on setSearchBarState :) 
      setCanToggleBasedOnScroll,
  } = useAppNavBarContext()
        //car
        const cartItems = useCart()
        const {CART_DELETE} = useCartActions()
        //screen
    const {isMobile, isMedium, isLarge} = useScreenContext()
    


    const internalApiService = new ConstantsAPI();

      const [servicesAreLoading, setServicesAreLoading] = useState(true)
      // const [availableServices, setAvailableServices] = useState([])

      //=============================================================
      //USE EFFECT IS HOW YOU LOAD THINGS ON INITIAL SCREEN LOAD!
      //BREAK LOADING OUT INTO FUNCTIONS AND THAT why you can do page
      //REFRESH WITH CERTAIN THINGS... IE FILTERS, ETC
      useEffect(() => {
        const loadInitialServices = async () => {
          setServicesAreLoading(true);
          // Simulate a 2-second delay
          setTimeout(async () => {
            try {
              const updated_services = await internalApiService.getEventServices(); // Fetch data asynchronously
              // setAvailableServices(services);
              console.log('UPDATED SEARCH SERVICES: ',updated_services)
              SEARCH_RESULTS_UPDATE(updated_services);
              setFilteredServices(updated_services,searchFilters)
              setServicesAreLoading(false); // Stop loading after data is fetched
              setInitialLoad(false)
            } catch (error) {
              console.error('Error fetching services:', error);
              // setAvailableServices([]);
              SEARCH_RESULTS_UPDATE([]);
              setFilteredServices([],searchFilters)
              setServicesAreLoading(false); // Stop loading after data is fetched
              setInitialLoad(false)
            }
          }, 1000); // 2-second delay
        };
        loadInitialServices()
      }, []);

      //NEED A USE EFFECT LIKE, SEARCH SHOULD UPDATE, and we should grab search, not in this component though
      const handleDeleteItemFromCart = (item) => {
        // dispatch(deleteCartItem(item));
        CART_DELETE(item)
    };

    //USE EFFECT THAT UPDATES THE SEARCH
    //==================================
    useEffect(()=> {

      if (servicesAreLoading!==true && initialLoad===false) {
        console.log("NEED TO UPDATE JOINTS")
          setServicesAreLoading(true)
        setTimeout(async () => {
          try {
            const updated_services = await internalApiService.getEventServices(); // Fetch data asynchronously
            // setAvailableServices(services);
            // FILTER_RESET() //RESETING FILTERS
            SEARCH_RESULTS_UPDATE(updated_services);
            setFilteredServices(SearchFunctions.filterServices(updated_services,searchFilters))

            setServicesAreLoading(false); // Stop loading after data is fetched

          } catch (error) {
            console.error('Error fetching services:', error);
            // setAvailableServices([]);
            SEARCH_RESULTS_UPDATE([]);
            setServicesAreLoading(false); // Stop loading after data is fetched
          }
        },1000)
        }

    }, [searchCriteria]);

    const handleSearchLocationOpened = () =>{
      console.log("search location pressed")
      toggleSearchInput('location')
    }
    //FILTER UPDATES
    //==================================
    useEffect(()=>{
      if (servicesAreLoading!==true && initialLoad===false) {

        console.log("THIS SHOULDN'T BE TRIGGERED ")
        setServicesAreLoading(true)
        setTimeout(async () => {
          //DO UPDATING STUFF HERE
          const services_filtered= SearchFunctions.filterServices(all_available_services,searchFilters)

          setFilteredServices(services_filtered)
          console.log("SEARCH FILTERS ARE THIS:",services_filtered)
          setServicesAreLoading(false)

          },100
        )
      }

    },[searchFilters])

    const handleCartMenuClose = () =>{
      setIsCartMenuOpen(false)
    }


    const handleCartCheckOut = () => {
      console.group('Checkout Details');
      console.log('Cart Items:', cartItems);
      console.log('Search Criteria:', searchCriteria);
      console.groupEnd();
  
      // navigate('/book', {state: {searchCriteria, cartItems}});
    }

    const [aiData,setAiData] = useState({})
    const handleAIInputChange = (e, newValue) => {

      const { id, value } = e.target;
      const newValueOrEvent = newValue !== undefined ? newValue : value;
      console.log("id: ",id," | ", value)

        setAiData((prevState) => ({
              ...prevState,
              [id]: newValueOrEvent,
          }));
  };

  const submitAISearch = () => {
    //save aiUserPrompt
    console.log("aiData.aiUserPrompt: ", aiData.aiUserPrompt || '')
    SEARCH_AI_USER_PROMPT(aiData.aiUserPrompt || '')
    //TODO: ADD SEARCHAPI HERE

  }

    const renderSearchComponents = () => {
      
      if(isMedium || isMobile) return (
        <>
          <NewTopSearchComponent/>
          <IconNavBarComponent/>
          <EventServicesComponent services={filteredServices} isLoading={servicesAreLoading}/>
        </>
      )
      if (isLarge) return (
        <ContainerFlexWithSidePanel>
          <SidePanel>
            {/* <StickyDiv> */}
              <FixedSidePanel>
                <NewSideSearchComponent/>
              </FixedSidePanel>
            {/* </StickyDiv> */}
          </SidePanel>
          <SearchContainerResultsPanel>
            <IconNavBarComponent/>
            <EventServicesComponent services={filteredServices} isLoading={servicesAreLoading}/>


          </SearchContainerResultsPanel>
        </ContainerFlexWithSidePanel>
      )
    }
    const renderSearchBody = () =>{
      if (isLocationSet) return (
        
        renderSearchComponents()
        
      )
      if (!isLocationSet) return (
        <>
        <NewTopSearchComponent data={searchData} onChange={handleAIInputChange}/>
        <NoSearchLocationContainer $isMobile={isMobile} $isMedium ={isMedium} $isLarge={isLarge}>
          <NoSearchInnerContainer onClick={handleSearchLocationOpened}>
            <NoSearchLocationImageContainer>
              <img src="https://cdn-icons-png.flaticon.com/512/966/966632.png"/>
            </NoSearchLocationImageContainer>
            <NoSearchLocationHeader>Enter Your Event Location</NoSearchLocationHeader>          
            <NoSearchLocationP>To explore available event services in your area, please provide your event location.</NoSearchLocationP>
          </NoSearchInnerContainer>
        </NoSearchLocationContainer>
        </>
      )
    }
console.log("UserAppContent searchBarState: ",searchBarState)
    return (
    <>
                <SpecialPageContentContainer ref={pageContentRef}>

                  {renderSearchBody()}

                </SpecialPageContentContainer>

                {(isMobile) && 
                    <MobileSearchContainer isVisible={searchBarState==='interactive'}/>
                }
    </>
    );
};

//contains the flex and the content which should equal total height of page
// const PageContainer = styled.div`
// dislpay: flex;
// flex-direction: column;
// height: 100vh;
// `

//LARGE SEARCH SHIT
const ContainerFlexWithSidePanel = styled.div`
display: flex;
position:relative;
width: 100%;
// min-height: 100%;
`
const SidePanel = styled.div`
display:flex;
flex-direction:column;
position: relative;
min-width: 400px;
max-width: 400px;
flex-grow:0;
flex-shrink:0;
height: 100%;
`

const StickyDiv = styled.div`
display:flex;
flex-direction: column;
width: 100%;
position: sticky;
top:0px;
left:0px;
`

const FixedSidePanel = styled.div`
display:flex;
flex-direction: column;
position: fixed;
width: 100%;
position: sticky;
top:0px;
left:0px;
`


const SearchContainerResultsPanel = styled.div`
display:flex;
flex-direction: column;
width: 100%;
min-height: 100%;
`






const SpecialPageContentContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
flex-grow: 1;
flex-shrink: 1;
overflow-y: scroll;
overflow-x: hidden;

  /* Hide scrollbar in WebKit browsers (Chrome, Safari, etc.) */
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Optional: to ensure the background is still clear */
  }

  /* For Firefox */
  scrollbar-width: none; /* hides scrollbar */
  -ms-overflow-style: none;  /* hides scrollbar in IE and Edge */
`

const CartContainer = styled.div`
position: fixed; 
display: flex;
bottom: 10px;/*calc(${NAVBAR_HEIGHT}/4);*/
left: calc(50% - 25px);
// transform: translateX(-50%);
z-index:5789789;
overflow:visible;
`


//SEARCH INPUT CONTAINER ITEMS
// const SearchInputHolder = styled.div`
// display: flex;
// position: sticky;
// top: 0px;
// left: 0px;
// height: 100%;
// width: 100%;
// // background-color: black;
// align-items: center;
// z-index: 1000;
// `
//isOverlayVisible
const CloseableOverlay = styled.div`
display: flex;
position: fixed;
width: 100%;
height: 100%;
align-items: start;
z-index: 999;
// cursor: ;
background-color: red;
  ${({ $isOpen }) => `
    background-color: ${$isOpen ? 'rgba(0, 0, 0, 0.5)' : 'transparent'} !important;
    opacity: ${$isOpen ? 1 : 0};
    display: ${$isOpen ? 'block' : 'none'} !important;
    position: ${$isOpen ? 'fixed' : 'relative'} !important;
    transition: opacity 0.3s ease;
  `}

`
const SearchInputContainer = styled.div`
display:flex;
position: absolute;
top: 0px;
left: 10%;
height: auto;
max-height:100%;
width: 80%; 
margin-left: auto;
margin-right: auto;
background-color: transparent;
align-items: start;
justify-content: center;
z-index: 1000;
overflow-y:scroll;

`

const CustomLocationInput = styled(LocationInput)`
min-height: 50vh !important;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
height: 50vh;
overflow-y: scroll;

`

const CustomDurationInput = styled(DurationInput)`
min-height: 50vh !important;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15) !important;
height: 50vh;
overflow-y: scroll;
`


const CustomDateTimeInput = styled(DateTimeInput)`
min-height: 50vh !important;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
height: 50vh;
overflow-y: scroll;
`

const SearchInputOverlay = styled.div`
display:flex;
position: absolute;
top: 0px;
left: 0px;
height: 100%;
width: 100%; 
background-color: #00000080;
align-items: start;
justify-content: center;

${(props) =>
    props.$isOpen
      ? css`
          animation: ${fadeIn} 0.5s forwards;
          display:flex;
        `
      : css`
          animation: ${fadeOut} 0.3s forwards;
          animation-delay: 0s; /* No delay for fade out */
          display: none;
          opacity:0;
        `}
`

const NoSearchLocationContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
align-items: center;
justify-content: center;
background-color: #CCC;
text-align:center;
padding:${props => {
  if (props.$isMobile) return '10px 15px';
  if (props.$isMedium) return '10px 100px';
  if (props.$isLarge) return '10px 300px';
  return '10px'; // Default to large if none match
  }};
`

const NoSearchInnerContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
height: auto;
padding: 50px;
border: solid 1px #AAA;
border-radius: 25px;
cursor: pointer;
max-width:800px;
`

const NoSearchLocationImageContainer = styled.div`
display: flex;
width: 100px;
height: 100px;
margin-bottom: 15px;

img{
    width: 100%; /* Ensure the image covers the full width */
    height: 100%; /* Ensure the image covers the full height */
    object-fit: cover; /* Maintain aspect ratio and fill the container */
    object-position: center; /* Center the image */}
`
const NoSearchLocationHeader = styled.h3`
color:black;
margin: 0px;
font-weight: bold;
`

const NoSearchLocationP = styled.p`
color: #00000080;

`
// // KEYFRAMES
// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;
// const fadeOut = keyframes`
//   from {
//     opacity: 1;
//   }
//   to {
//     opacity: 0;
//   }
// `;


// Define fade in and fade out animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;


export default UserAppContent;