

//Hemlet
import { Helmet } from 'react-helmet-async';

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

//context
import {useState, useEffect, useRef } from "react";
import { useNavigate, Link } from 'react-router-dom';

//navbar
import { PageContentContainer } from 'components/Page/Elements';
import { AppNavBarManagerProvider } from 'context/AppNavBarContext';

import { ScrollProvider } from 'pages/home/header/ScrollContext';
//Get from here
import NavBarLogout from 'pages/home/header/NavBarLogout';

//Footer
import FooterLoggedOut from 'components/Footer/FooterLoggedOut';

//Container


const HelpArticleContainer = ({children, navBar = NavBarLogout,...props}) => {


      const scrollToTop = () =>{
        console.log("this is working?")
        // setTimeout(() => contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    } 

    return (
        <ScrollProvider>
            <SpecialNavBarLogout handleNavigateHome={scrollToTop}/>
                <SpecialPageContentContainer>
                    {children}
                    <FooterLoggedOut/>
                </SpecialPageContentContainer>
        </ScrollProvider>
    )
}


export default HelpArticleContainer

const SpecialNavBarLogout = styled(NavBarLogout)`
    // position:fixed;
    // background-color: transparent !important;
    background-color: #DDDDDD;
`

const SpecialPageContentContainer = styled(PageContentContainer)`
background-color: white !important;
`