
import axios from 'axios';
// import cookie from 'react-cookies'; // Import react-cookies
import apiUtils from 'api/apiUtils';
// Define the configuration directly here
const config = {
    production: {
        apiBaseUrl: "http://appspot.goghnow/api/",
    },
    development: {
        apiBaseUrl: "http://127.0.0.1:8082/",
    },
};

const getConfig = () => {
    const env = process.env.NODE_ENV || 'development';
    return config[env];
};

// Create an instance of axios with base URL
const AxiosService = axios.create({
    baseURL: getConfig().apiBaseUrl,
    timeout: 10000,
});


let isRefreshing = false;
let refreshSubscribers = [];
let refreshTokenURL = '/auth/refresh-token'

//ADDED BELOW
// Add Axios request interceptor to include token in the Authorization header
AxiosService.interceptors.request.use(
    (config) => {
        const token = apiUtils.getToken(); // Get token from cookies
        console.log("interceptors.request.use")
        if (config.url.includes(refreshTokenURL)) {
            const refreshToken = apiUtils.getRefreshToken();
            if (refreshToken) {
                config.headers.Authorization = `Bearer ${refreshToken}`;
            }
        } else {
            const token = apiUtils.getToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => {
        console.error("error request use")
        return Promise.reject(error);
    }
);

const onRefreshed = (token) => {
    refreshSubscribers.forEach((callback) => callback(token));
    refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};

AxiosService.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.log("interceptors.RESPONSE.use")
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            console.log('initial was 401 AUTH ERROR WE HERE')
            if (isRefreshing) {
                // Queue requests until the token is refreshed
                return new Promise((resolve) => {
                    addRefreshSubscriber((token) => {
                        originalRequest.headers.Authorization = `Bearer ${token}`;
                        resolve(AxiosService(originalRequest));
                    });
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const refreshToken = apiUtils.getRefreshToken();
                if (!refreshToken) throw new Error('No refresh token available.');

                // let params = {'refresh_token':refreshToken}
                const response = await AxiosService.post(refreshTokenURL, { refreshToken });
                console.log("new token response: ",response)
                const newToken = response.data.token;
                apiUtils.saveToken(newToken);
                onRefreshed(newToken);

                isRefreshing = false;

                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return AxiosService(originalRequest);
            } catch (err) {
                //if the refresh fails it deletes all tokens, sends user to login * rejects promise
                isRefreshing = false;
                apiUtils.deleteTokens();
                window.location.href = '/login';
                return Promise.reject(err);
            }
        }

        return Promise.reject(error);
    }
);



// Add Axios response interceptor to handle token refresh
// AxiosService.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config;

//         // Check if the error is due to an expired token
//         if (error.response?.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true; // Prevent infinite retries

//             try {
//                 // const token = apiUtils.getToken()
//                 const refreshToken = apiUtils.getRefreshToken(); // Get refresh token from cookies
//                 // console.log("refreshToken!",refreshToken)
//                 // console.log("refreshToken!",token)

//                 // Refresh the token
//                 const response = await AxiosService.post('/auth/refresh-token', { refreshToken }); // Update this route if needed
//                 const newToken = response.token;

//                 // Update cookies with the new token
//                 apiUtils.saveToken(newToken);

//                 // Retry the original request with the new token
//                 originalRequest.headers.Authorization = `Bearer ${newToken}`;
//                 return AxiosService(originalRequest);
//             } catch (err) {
//                 console.error('Token refresh failed:', err);
//                 // Forward the error to the calling function
//                 return Promise.reject(error);
//             }
//         }
//         // If not handled, reject the error
//         return Promise.reject(error);
//     }
// );

export default AxiosService;