
import React, { useState } from 'react';
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'
import { motion } from 'framer-motion';



const lineIconComponent = ({icon, title, description}) => {

    return (
        <Container>
            <IconContainer><img src={icon}/></IconContainer>
            <TextContainer>
                <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
                <Description dangerouslySetInnerHTML={{ __html: description }}></Description>
            </TextContainer>
        </Container>
    )
}
export default lineIconComponent


const Container = styled.div`
display: flex;
width: 100%;
gap: 10px;
`

const IconContainer = styled.div`
display: flex;
  align-items: start;
  justify-content: center;
  width: 10%;
//   height: 100px;
   img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`

const TextContainer = styled.div`
display: flex;
flex-direction: column;
flex-grow:1;
width: 80%;
color: black;
align-items: start;
justify-content: start;
text-align: left;

p{
margin:0px;
}
`

const Title = styled.p`
font-size: 1.275em;
font-weight:bold;
`
const Description = styled.p`
font-size:1.0em;
`