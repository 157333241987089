//Hemlet
import { Helmet } from 'react-helmet-async';

//CONSTANTS
import { NAVBAR_HEIGHT } from "components/constants";

//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//SVG
import { ReactComponent as GoghNowLogo } from 'assets/cta-logo-one.svg';
import { ReactComponent as GoghNowLogoSM } from 'assets/cta-logo-oneSM.svg';

//Navbar
import NavBarAccount from 'apps/User/NavBarComponents/NavBarAccount';

// //AppNav w cart shit
import { AppNavBarManagerProvider } from "context/AppNavBarContext";

//Sceen
import { useScreenContext } from 'context/AppContext/ScreenContext';

//React
import {useState } from "react";
import { useNavigate, Link } from 'react-router-dom';


import BreadcrumbNavigation from '../Components/BreadcrumbNavigation';
//ACCOUNT MENU
// import AccountMenu from './AccountSettingsPageComponents';

//Icons
// import profileIcon from 'assets/profile-icon.svg';
// import accountIcon from 'assets/account-icon.svg';

//Profile Edit Items
// import EditAccountItem from 'apps/User/AccountSettings/Account/Components/EditAccountItem';

//UPDATE ITEMS
import UpdateLegalName from 'apps/User/pages/AccountSettings/Account/Components/UpdateLegalName';
import UpdatePreferredName from 'apps/User/pages/AccountSettings/Account/Components/UpdatePreferredName';
import UpdateEmail from 'apps/User/pages/AccountSettings/Account/Components/UpdateEmail';
import UpdatePhone from 'apps/User/pages/AccountSettings/Account/Components/UpdatePhone';


//UserProfile
import UserProfilePublicComponent from 'apps/User/pages/AccountSettings/Profile/Components/UserProfilePublicComponent';

//DATA
import { useUser } from 'third_party/redux/selectors';

const UserProfile = ({...props}) => {

    //USER DATA TO POPULATE PROFILE
    const UserData = useUser()

    const name = UserData.preferred_name 
    ? UserData.preferred_name 
    : `${UserData.firstName || ''} ${UserData.lastName ? UserData.lastName.charAt(0) + '.' : ''}`.trim() || 'Anonymous';
    const details = UserData.details || null
    const bookings = UserData.bookings || 0
    const services = UserData.services || 0
    const profile_picture = UserData.picture || null //'https://turkeyanaclinic.com/wp-content/uploads/2023/05/Baby-Face-02.jpg'
    //Navigation
    const navigate = useNavigate();

    const {isMobile, isMedium, isLarge} = useScreenContext()

    const [activeItem, setActiveItem] = useState(null);

    const handleAccountItemBeingEdited = (item) =>{
        setActiveItem(item)
    }

    const BREADCRUMBS = [
        { name: "account settings", link: "/account-settings" },
        { name: "profile", link: "/account-settings/profile" },
      ]


      const handleEditProfile = () => {
        navigate('/account-settings/profile/edit')
      }
      

    return (
        <>
            <Helmet>
                <title>GoghNow the App: Explore Event Services Near You | GoghNow</title>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="GoghNow | Account Settings | Profil" />
                <meta name="description" content="GoghNow view, update, and modify your profile" />
                <meta property="og:image" content="images/cta-logo-one.png" />
            </Helmet>
            <AppNavBarManagerProvider>
            <SpecialPageContainer>
                <SpecialNavBarLogin/>
                <SpecialPageContentContainer>
                    <Container $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        {(isLarge || isMedium) && (
                            <BreadcrumbNavigation breadcrumbs={BREADCRUMBS}/>
                        )}
                        <HeadingContainer>
                            <Heading>Profile</Heading>
                            <HeadingEditButton onClick={handleEditProfile}>edit</HeadingEditButton>
                            
                        </HeadingContainer>
                        <FlexContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <FlexContentContainerLeft>
                                <ProfileContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    
                                    {/* IM GOING HOME */}
                                    <SpecialUserProfilePublicComponent
                                    name={name}
                                    details={details}
                                    bookings={bookings}
                                    services={services}
                                    profile_picture={profile_picture}
                                    />

                                </ProfileContentContainer>
                            </FlexContentContainerLeft>
                            <FlexContentContainerRight $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                <InnerFlexContentContainerRight $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <InnerFlexIconContainer>
                                        <img src="/images/apps/user/eye1.png"></img>
                                    </InnerFlexIconContainer>
                                    <InnerFlexHeader>This part of your profile providers can see</InnerFlexHeader>
                                    <InnerFlexBody>Showcase your personality to providers and other users. This helps providers get to know you a little bit before they deliver their services to you</InnerFlexBody>
                                    <HorizontalLine></HorizontalLine>

                                </InnerFlexContentContainerRight>
                            </FlexContentContainerRight>
                        </FlexContentContainer>
                    </Container>
                </SpecialPageContentContainer>
            </SpecialPageContainer>
            </AppNavBarManagerProvider>

        </>
    )
}

export default UserProfile

// const SpecialEditAccountItem = styled(EditAccountItem)`
//   position: relative;
//   z-index:${props => {
//     if (props.$isActive) return '9999999999999999';
//     return '1';
//   }};
//   transition: z-index 0.3s ease-in-out;
// `


const SpecialPageContainer = styled.div`
display: flex;
flex-direction: column;
height:100dvh;
width: 100dvw;
overflow:hidden;
background-color: white;
`

const SpecialNavBarLogin = styled(NavBarAccount)`
    // position:fixed;
    background-color: transparent !important;
    // top:0px;
`

const SpecialUserProfilePublicComponent = styled(UserProfilePublicComponent)`

`

const SpecialPageContentContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
flex-grow: 1;
flex-shrink: 1;
overflow-y: scroll;
overflow-x: hidden;
align-items: center;
width: 100dvw;
`

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content:center;
max-width: 1080px;
width:100%;
padding: ${props => {
    if (props.$isMobile) return '5px 5px';
    if (props.$isMedium) return '15px 15px';
    if (props.$isLarge) return '25px 25px';
    return '25px 25px'; // Default to large if none match
  }};
  margin-left: auto;
  margin-right: auto;
`
const HeadingContainer = styled.div`
display: flex;
align-items: center;
gap: 25px;
`
const Heading = styled.h1`
color: black;
margin-bottom: 15px;
`
const HeadingEditButton = styled.p`
padding: 10px 15px;
width: auto;
background-color: #AAAAAA60;
color: black;
font-weight: bold;
cursor: pointer;
border-radius: 15px;
overflow: hidden;

&:hover{
background-color: #AAAAAA;
}

`


const FlexContentContainer = styled.div`
padding-top: 15px;
display: flex;
min-height: 75vh;
`

const FlexContentContainerLeft = styled.div`
display: flex;
flex-grow:1;
`

const ProfileContentContainer = styled.div`
position: relative;
display:flex;
flex-direction:column;
width: 100%;
// background-color: #CCC;
min-height:100%;
`

const FlexContentContainerRight = styled.div`
display:${props => {
    if (props.$isMobile) return 'none';
    return 'flex'; // Default to large if none match
  }};
width: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return 'calc(100% / 3 + 40px)';
    if (props.$isLarge) return 'calc(100% / 3)';
    return 'calc(100% / 3)'; // Default to large if none match
  }};
margin-left: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return '10px';
    if (props.$isLarge) return 'calc(100% / 3 / 8)';
    return 'calc(100% / 3 / 8)'; // Default to large if none match
  }};
  max-width: 100%;
background-color: transparent;
min-height:100%;
border: 1px solid #AAAAAA80;
border-radius: 15px;
overflow: hidden;
`

const InnerFlexContentContainerRight = styled.div`
// background-color: #EEEEEE80;
width: 100%;
height: 100%;
margin: ${props => {
    if (props.$isMobile) return '5px 5px';
    if (props.$isMedium) return '15px 15px';
    if (props.$isLarge) return '25px 25px';
    return '25px 25px'; // Default to large if none match
  }};
`

const InnerFlexHeader = styled.h3`
color: black;
margin-bottom: 10px;
margin-top: 10px;
`
const InnerFlexBody = styled.p`
color:black;
margin-top: 0px;
`
const InnerFlexIconContainer = styled.div`
display:flex;
width: 50%;
height:auto;
 display: block;
    img{
        width: 100%; /* Ensures image keeps its aspect ratio */
        height: 100%;
        padding:2.5px;
        object-position: center; /* Center the image */
           // object-fit: cover; /* Maintain aspect ratio */
}
`
const HorizontalLine = styled.hr`
  border: none;
  border-top: 1px solid #AAAAAA40;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 35px;
`;
