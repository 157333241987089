type Article = {
    id: string;
    js: string;
    title: string;
    description: string;
};

type HelpArticleCategory = {
    [key: string]: Article[];
};

// Enum to represent all article keys
export enum ArticleEnum {
    USER_CREATE_USER_ACCOUNT = "create-user-account",
    USER_HOW_IT_WORKS = "how-it-works",
    PROVIDER_CREATE_PROVIDER_ACCOUNT = "create-provider-account",
    BUSINESS_REGISTER_YOUR_BUSINESS = "register-your-business",
    BUSINESS_START_TODAY = "start-today",
}

// Enum to represent the type of user (user, provider, business)
export enum CategoryEnum {
    USER = "user",
    PROVIDER = "provider",
    BUSINESS = "business"
}

export const HelpArticleData: HelpArticleCategory = {
    user: [
        { 
            id: "create-user-account",
            js:"CreateUserAccount",
            title: "Create an account",
            description:"Learn how to create a GoghNow account in just a few steps, so you can start planning your perfect event experience today.",
        },
        { 
            id: "how-it-works",
            js: "HowItWorksCreatingExperiences",
            title: "How It Works: Creating Experiences",
            description: "Discover how GoghNow helps you bring your event ideas to life. From selecting services to booking and managing your event, we make the process seamless and enjoyable.",
        }
    ],
    provider: [
        { 
            id: "create-provider-account",
            js:"CreateProviderAccount",
            title: "Create a provider account",
            description:"Creating a GoghNow provider account is free and easy, to sign up, visit Goghnow.com/provider",
        },
    ],
    business: [
        { 
            id: "register-your-business",
            js:"RegisterYourBusiness",
            title: "Register Your Business",
            description:"Registering your business is free and simple. Sign up to start offering event services and earning through client bookings.",
        },
        { 
            id: "start-today",
            js:"StartUsingGoghNowForBusiness",
            title: "Start using GoghNow for Business today",
            description:"Learn how to get started with GoghNow today while keeping your existing process. Sign up, streamline client bookings, and simplify event service selection effortlessly.",
        },
        { 
            id: "start-today-communities",
            js:"StartUsingGoghNowForCommunities",
            title: "Start using GoghNow for Communities today",
            description:"Learn how to get started with GoghNow for Communities today. Simplify tenant and management events while earning revenue from tenant booked services.",
        },
    ]
};

class ArticleManager {
    // Method to get HelpArticleData
    static HelpArticleData(): HelpArticleCategory {
        return HelpArticleData;
    }

    // Method to get article link
    static getArticleLink(categoryEnum: CategoryEnum,articleEnum: ArticleEnum): string {
        // Split enum value into category and id
        const category = categoryEnum || null;
        const id = articleEnum || null; // Getting the ID by parsing the enum

        // Validate category existence in HelpArticleData
        if (!category) {
            return `/help/not-found`; // Return fallback link for category not found
        }

        // Find article by ID
        if (!id) {
            return `/help/${category}/not-found`; // Return fallback link for article not found
        }

        // Return the correct article link
        return `/help/${category}/${id}`;
    }

    // Helper method to get category by enum
    private static getCategoryByEnum(articleEnum: ArticleEnum): string {
        return articleEnum.split("_")[0].toLowerCase();
    }
}

export default ArticleManager;
