import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

// import {useState, useEffect, useRef } from "react";
// import { useNavigate, Link } from 'react-router-dom';

import { NAVBAR_HEIGHT } from "components/constants";

// import SubmitButton from "components/Buttons/SubmitButton";
import OrSeparator from 'components/Dividers/OrSeparator';


import { useScreenContext } from 'context/AppContext/ScreenContext';
// import InputContainer from 'components/Containers/InputContainer';

import SignupPhoneInput from 'components/Form/SignupPhoneInput';
import SignupPhoneAccessCode from 'components/Form/SignupPhoneAccessCode';
import SignupBusinessInfo from 'components/Form/SignupBusinessInfo';
import {buttonRoundCloseStyle, buttonSubmitStyle} from 'components/Buttons/ButtonStyles'

import SignupServiceAPI from 'api/User/SignupServiceAPI';
//class data
import { ObjectUserStaging, ObjectUserStagingClass } from 'model/objectUserStaging';
import PopUpNotification from 'components/Popups/PopUpNotification';

//Logo
// import { ReactComponent as GoghNowLogo } from 'assets/cta-logo-one.svg';

//NAVBAR
import PlainLogoNavBar from 'components/navbar/PlainLogoNavBar';
//screen context

    interface ContentProps {
        $isMobile: boolean;
        $isMedium: boolean;
        $isLarge: boolean;
    }
    interface InputContainerProps {
        $isMobile: boolean;
        $isMedium: boolean;
        $isLarge: boolean;
    }
    interface SizeProps {
        $isMobile: boolean;
        $isMedium: boolean;
        $isLarge: boolean;
    }

    interface SignupProps {}

    interface ImageBackgroundProps {
        imgUrl: string;
      }
// const Signup = (props) => {

    // Type definition for props passed to GridSquare
    // interface GridSquareProps {
    //     bgX: number;
    //     bgY: number;
    //   }

enum Screens {
    EnterPhoneNumber = 0,
    ValidateAccessCode = 1,
    EnterInformation = 2,
    SucessLogin = 3,
  }


const SignupUser: React.FC<SignupProps> = () => {


    
    const navigate = useNavigate();

    // const Logo = require("assets/cta-logo-one.svg") as string;

    //Sign Up Serices Bro!
    const signupService = new SignupServiceAPI();



    //Screen Size
    const {isMobile, isMedium, isLarge} = useScreenContext()


    const [formData, setFormData] = useState<ObjectUserStaging>(new ObjectUserStagingClass());


    const [error, setError] = useState<string | null>(null);
    const [showNotification, setShowNotification] = useState(false);


    const [screen, setScreen] = useState(0)
    const numberOfSteps = Object.values(Screens).filter(Number.isInteger).length;
    const progress = ((screen + 1) / (numberOfSteps - 1)) * 100;


    const handleInputChange = (value:any, field: string) => {
        // Your logic to update state
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));

        console.log('formData: ',formData)
    };

      // Using useEffect to log formData when it changes
  useEffect(() => {
    console.log('Updated formData:', formData);
  }, [formData]);  // This will run every time formData changes


    const handlePrevious = () => {
        setScreen(prevScreen => prevScreen - 1);
        console.log('Previous', screen - 1);
    }

    const handleNext = async(e: React.FormEvent) => {

        e.preventDefault();  // Prevent the default form submission behavior

        try{
            let success = false;
            if(screen === Screens.EnterPhoneNumber){
                //GET ACCESS CODE
                console.log('GET ACCESS CODE')
                success = await getAccessCode(formData.phone_number || '')

            }
            else if(screen === Screens.ValidateAccessCode){
                //VALIDATE ACCESS CODE
                console.log('VALIDATE ACCESS CODE')
                success = await confirmAccessCode(formData.phone_number || '', formData.access_code || '')
            }else if (screen === Screens.EnterInformation){
                //SEND Account Data to Server
                console.log('CREATE ACCOUNT WITH INFO')
                success = await saveAccountInfo(
                    formData.phone_number || '',
                    formData.access_code || '',
                    formData.invite_code || '',
                    formData.password || '',
                    formData.first_name || '',
                    formData.last_name || '',
                    formData.email || '')
            }

            else if(screen === Screens.SucessLogin){
                console.log("GO TO THE DASHBOARD")
                //LOGIN FUNCTION
                success = true

                return;
                // navigate to dashboard

            }


            // If all API calls are successful, increment the screen
            if(success){
                setScreen(prevScreen => prevScreen + 1);
            }

        }
        catch(error){
            console.log("ERROR WAS THROWN! ", error)
            
            //DISPLAY POPUP HERE
            setError(error as string)
        }

    }


    //**************************************
    //API FUNCTIONS
    //************************************** 
    const getAccessCode = async (phoneNumber: string) => {
        try {
            const result = await signupService.getAccessCode(phoneNumber)
            console.log('getAccessCode Result:', result);
            return true;
        }
        catch (error){
            console.log('getAccessCode Error: ', error)
            throw error;
        }
    }

    const confirmAccessCode = async (phoneNumber: string, accessCode: string) => {
        try {
            const result = await signupService.confirmAccessCode(formData.phone_number || '', formData.access_code || '');
            console.log('confirmAccessCode Result:', result);
            return true;
        }
        catch (error){
            console.log('confirmAccessCode Error: ', error)
            throw error;
        }
    }

    const saveAccountInfo = async (phone_number: string, access_code: string, invite_code: string, password: string, first_name: string, last_name: string, email: string ) => {
        try {
            const result = await signupService.saveAccountInfo(
                phone_number || '',
                access_code || '',
                invite_code || '',
                password || '',
                first_name || '',
                last_name || '',
                email || '')
            console.log('saveAccountInfo Result:', result);
            return true
        }
        catch (error){
            console.log('saveAccountInfo Error: ', error)
            throw error
        }
    }


    const handleClosePopup = () => {
        setError(null);  // Close the popup by clearing the error state
      };

      const scrollToTop = () =>{
        console.log("this is working?")
        // setTimeout(() => contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    } 


    const handleLogin = () => {
        console.log('signup button pressed')
        navigate('/Login'); // Redirect to the SignUp page

    }
 const handleLogoClick = () =>{
    console.log('logo click')
    navigate('/')
 }

 const GoToLoginScreen = () => {
    navigate('/login-user') //TODO make sure this is user
 }

    return (
    <>
        <PageContainer>
        {/* <SpecialNavBarLogout handleNavigateHome={scrollToTop}/> */}

        <Content $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
            <Form>

                    
                    
                        {/* STEP 1 */}
                            {screen === Screens.EnterPhoneNumber && (
                                <>
                                    <StepContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                        <InputContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                            <HeaderContainer>
                                            {screen > 0 && (
                                                    <ButtonClose onClick={handlePrevious}>←</ButtonClose>
                                                )}
                                                <BIGBrand $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} onClick={handleLogoClick}>GOGHNOW</BIGBrand>
                                                <ProgressBarContainer>
                                                    <ProgressBarFill progress={progress} />
                                                </ProgressBarContainer>
                                            </HeaderContainer>
                                            {/* <SQImageContainer>
                                                    <img src="images/Signup/phonesimple.png"></img>
                                            </SQImageContainer> */}
                                            <FormContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                                {/* <SubText><h1>Create an Account</h1></SubText> */}
                                                <WelcomeText>Create an Account</WelcomeText>
                                                <MainText><h4>Enter your phone number</h4></MainText>
                                                <SignupPhoneInput // Use the interface for type safety
                                                    data={formData}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Phone Number" // Provide placeholder prop
                                                    field='phone_number'
                                                />
                                                <NextButton onClick={handleNext}>
                                                    {'Next'}
                                                </NextButton>
                                                {/* <SeparatorOR/> */}
                                                <SignupButtonCustom onClick={handleLogin}>Already have an account? Sign in</SignupButtonCustom>
                                                <DescriptionContainer>

                                                {/* <DescriptionText>Create an account to create your event experiences stress-free!</DescriptionText> */}
                                                <ul>
                                                    <li><strong>Faster Checkout:</strong> Enjoy quicker checkouts with saved details.</li>
                                                    <li><strong>Track Your Bookings:</strong> Easily monitor and organize all your plans.</li>
                                                    <li><strong>Exclusive Deals:</strong> Access special promotions and discounts.</li>
                                                    <li><strong>Saved Preferences:</strong> Rebook services in seconds.</li>
                                                    <li><strong>Personalized Recommendations:</strong> Discover services tailored to your needs.</li>
                                                </ul>
                                                </DescriptionContainer>
                                            </FormContainer>
                                        </InputContainer>
                                        <SideContainer>
                                            <ImageBackground imgUrl={'/images/Signup/signup1.png'}>
                                            {/* {Array.from({ length: 9 }).map((_, idx) => (
                                                <GridSquare key={idx} />
                                            ))} */}
                                            </ImageBackground>
                                        </SideContainer>
                                    </StepContainer>
                                </>
                            )}
                        {/* STEP 2 */}
                        {screen === Screens.ValidateAccessCode && (
                            <>
                                <StepContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <InputContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                            <HeaderContainer>
                                                {screen > 0 && (
                                                    <ButtonClose onClick={handlePrevious}>←</ButtonClose>
                                                )}
                                                <BIGBrand $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} onClick={handleLogoClick}>GOGHNOW</BIGBrand>
                                                <ProgressBarContainer>
                                                    <ProgressBarFill progress={progress} />
                                                </ProgressBarContainer>
                                            </HeaderContainer>
                                            {/* <SQImageContainer>
                                                    <img src="images/Signup/accesssimple.png"></img>
                                            </SQImageContainer> */}
                                            <FormContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                                {/* <SubText><p>Access Code</p></SubText> */}
                                                <MainText><h4>Enter your access code</h4></MainText>
                                                <SignupPhoneAccessCode
                                                    data={formData}
                                                    onChange={handleInputChange}
                                                    placeholder='Access Code'
                                                    field="access_code"
                                                />
                                                <NextButton onClick={handleNext}>
                                                    {'Next'}
                                                </NextButton>
                                            </FormContainer>
                                    </InputContainer>
                                    <SideContainer>
                                        <ImageBackground imgUrl={'/images/Signup/phonedesign2.png'}>
                                        {/* {Array.from({ length: 9 }).map((_, idx) => (
                                            <GridSquare key={idx} />
                                        ))} */}
                                        </ImageBackground>
                                    </SideContainer>
                                </StepContainer>
                            </>
                        )}

                        {/* STEP 3 */}
                        {screen === Screens.EnterInformation && (
                            <>
                            <StepContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <InputContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                            <HeaderContainer>
                                                {screen > 0 && (
                                                    <ButtonClose onClick={handlePrevious}>←</ButtonClose>
                                                )}
                                                <BIGBrand $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} onClick={handleLogoClick}>GOGHNOW</BIGBrand>
                                                <ProgressBarContainer>
                                                    <ProgressBarFill progress={progress} />
                                                </ProgressBarContainer>
                                            </HeaderContainer>
                                            {/* <SQImageContainer>
                                                    <img src="images/Signup/accesssimple.png"></img>
                                            </SQImageContainer> */}
                                            <FormContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                                <SubText><p>Account Info</p></SubText>
                                                <MainText><h4>Enter your account information</h4></MainText>
                                                <SignupBusinessInfo
                                                    data={formData}
                                                    onChange={handleInputChange}
                                                />
                                                <NextButton onClick={handleNext}>
                                                        {'Create Account'}
                                                </NextButton>
                                            </FormContainer>
                                    </InputContainer>
                                    <SideContainer>
                                        <ImageBackground imgUrl={'/images/Signup/profiledesign1.png'}>
                                        {/* {Array.from({ length: 9 }).map((_, idx) => (
                                            <GridSquare key={idx} />
                                        ))} */}
                                        </ImageBackground>
                                    </SideContainer>
                            </StepContainer>
                            </>
                        )}
                        {/* STEP 4 */}
                        {screen === Screens.SucessLogin && (
                            <>
                            <StepContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <InputContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                            <HeaderContainer>
                                                <BIGBrand $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} onClick={handleLogoClick}>GOGHNOW</BIGBrand>
                                                {/* <ProgressBarContainer>
                                                    <ProgressBarFill progress={progress} />
                                                </ProgressBarContainer> */}
                                            </HeaderContainer>
                                            {/* <SQImageContainer>
                                                    <img src="images/Signup/accesssimple.png"></img>
                                            </SQImageContainer> */}
                                            <FormContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                            <h1>SUCCESS<br/></h1>
                                                {/* <h2>GET STARTED</h2> */}
                                                {/* <MainText><h4>Enter your account information</h4></MainText> */}
                                                <SubText><p>Build event experiences in minutes. Handpick unique local vendors and à la carte services on-demand</p></SubText>

                                                {/* <SubText><h1>Sucess</h1></SubText>
                                                <MainText><p>You've successfully created an account</p></MainText> */}
                                                <NextButton onClick={GoToLoginScreen}>
                                                    {'Login to Get Started'}
                                                </NextButton>
                                            </FormContainer>
                                    </InputContainer>
                                    <SideContainer>
                                        <ImageBackground imgUrl={'/images/Signup/successlogin.png'}>
                                        {/* {Array.from({ length: 9 }).map((_, idx) => (
                                            <GridSquare key={idx} />
                                        ))} */}
                                            <ImageBackgroundContentContainer>
                                                {/* <h1>WELCOME<br/></h1>
                                                <h2>Build event experiences in minutes</h2>
                                                <p>Handpick unique local vendors and à la carte services on-demand</p> */}
                                            </ImageBackgroundContentContainer>
                                        
                                        </ImageBackground>
                                    </SideContainer>
                                </StepContainer>
                            </>
                        )}
                        


                    {/* {screen < 4 && (

                        <NextButton onClick={handleNext}>
                            {screen < 3 ? 'Next' : 'Go to Dashboard'}
                        </NextButton>
                    )} */}
                    

                {/* </InputContainer> */}


    
                </Form>
            </Content>

                <BgImage/>
                <BgImageOverlay/>
        </PageContainer>

        {/* Conditionally render the popup when there is an error */}
        {error && (
            <PopUpNotification
            titleText="Error"
            descriptionText={error}
            buttonText="Close"
            buttonAction={handleClosePopup}  // Close popup on button click
            />
        )}
    </>
    );
};


export default SignupUser;

//======================
//======================
//NavBar
//======================
//======================
const SpecialNavBarLogout = styled(PlainLogoNavBar)`
    position:fixed;
    background-color: transparent !important;
    color:black !important;
`

const ProgressBarContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: -16px;
  height: 8px;
  background-color: #e0e0e0;
`;

const ProgressBarFill = styled.div<{ progress: number }>`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background-color: #4caf50; /* Color of the progress bar */
  transition: width 0.3s ease-in-out;
`;



const ButtonClose = styled.button`
${buttonRoundCloseStyle}
`

//END OF NAVBAR
const NextButton = styled.button`
${buttonSubmitStyle}
margin-top: 10px;
width: 100%;
margin-top: 20px;
`
const WelcomeText = styled.h3`
color:black;
font-weight:600;
width: 100%;
text-align: left;
`

const SignupButtonCustom = styled.h4`
  margin-top: 18px;

  width: 100%;
  color: #FBAE6260;
  cursor: pointer;

  &:hover {
        color: #FBAE62;
   }
`;


const SeparatorOR = styled(OrSeparator)`
padding: 36px 0px;
`



const Content = styled.div<ContentProps>`
width: 100vw;
position: relative;
box-sizing: border-box;
display: flex;
justify-content: ${props => {
    if (props.$isMobile) return 'none';
    if (props.$isMedium) return 'center';
    if (props.$isLarge) return 'center';
    return 'center'; // Default to large if none match
}}; 
align-items: center;
flex-direction: column;
height: 100%;
background-color: #FFFFFF90;
`;


const FormContainer = styled.div<InputContainerProps>`



    // flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    transition-timing-function: ease-out;
    transition: opacity 0.2s;
    display: flex;
    background-color: white;
    padding: 0px 50px;
    border-radius: 5px;
    // border: solid 1px #CCC;
    overflow-y: scroll;
    height:100%;
    width:100%;
    flex-grow:1;
    // flex: 1;
    text-align: center;
    // box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7); /* Optional styling for emphasis */
    h1 {
    color: black;
    }
    p {
    color: black;
    }

`
const DescriptionContainer = styled.div`
display: flex;
flex-direction: column;

  ul {
    text-align: left;
    list-style-type: disc; /* Optional: style for list bullets */
    margin: 0; /* Optional: adjust spacing */
    padding-left: 20px; /* Optional: adjust indentation */
    font-size: .875em;
    color: #000000;
  }
`
const DescriptionText = styled.p`
 color: black;
`
const StepContainer = styled.div<SizeProps>`
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return '100%';
        if (props.$isMedium) return '100%';
        if (props.$isLarge) return '100%';
        return '100%'; // Default to large if none match
    }}; 
    // min-height: 80vh;
    justify-content:center;
    height:100%;
  `
const InputContainer = styled.div<InputContainerProps>`
display: flex;
flex-direction:column;
background-color: transparent;
justify-content: center;
width:550px;
// margin: 25px;
align-items:center;
gap: 25px;
height:100%;
`
const BIGBrand = styled.h1<InputContainerProps>`
font-size: 2.5em;
position:relative;
font-weight: 400;
letter-spacing: ${props => {
    if (props.$isMobile) return '2px';
    if (props.$isMedium) return '4px';
    if (props.$isLarge) return '10px';
    return '10px'; // Default to large if none match
  }};
cursor: pointer;
  margin:0px;
  color: black;
`
const SideContainer = styled.div`
    display:flex;
    flex-grow:1;
    flex-direction:column;
    text-align: center;
    background-color: white;
    width: 500px;
    height:100%;

     @media (max-width: 850px) {
    display: none;
  }
`

//============================
const ImageBackground = styled.div<ImageBackgroundProps>`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 0px; /* The gaps between the squares */
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  background-color: white; /* The color of the gaps */
  position: relative;
  overflow: hidden;
    background-image: ${({ imgUrl }) => `url(${imgUrl})`};
    background-size: cover;
    background-position: center;
    filter: brightness(1.5);
`;
const ImageBackgroundContentContainer = styled.div`
display: flex;
flex-direction: column;
position: absolute;
width: 100%;
height: 100%;
justify-content: center;
align-items: center;
    h1{
    color: black;
    margin: 0px;
    }
    p{
    color: black;
    margin: 10px;
    }
    h2{
    margin:0px;
    }
`

const GridSquare = styled.div`
  display:flex;
  border: 5px solid white;
`;

//========================================
const Form = styled.form`
width: 100%;
height:100%;
// padding: 15px;
background-color: white;

`
const SubText = styled.div`
color: black;
justify-content: start;
display: flex;
width: 100%;
h1{
    font-size: 3em;
    color: #DDD;
    margin-bottom: 0px;
}
`
const SQImageContainer = styled.div`
display:flex;
aspect-ratio: 1 / 1;
width: 100%;
overflow:hidden;
align-items: center;
justify-content: center;
img{

    display: block;
   width: auto;
height: auto;
    max-width:100%;
    max-height:100%;
   
}
`
const MainText = styled.div`
display: flex;
width: 100%;
h4{
color: black;
margin-top: 0px;
}
`
const PageContainer = styled.section`

overflow: hidden;
position: relative;
display: flex;
flex-grow:1;
flex-direction: column;
text-align: left;
height: calc(100vh);
// top: ${NAVBAR_HEIGHT};
width: 100vw;
background-color: #FFFFFF90;
`;


const BgImage = styled.div`
background-image: url("/images/web-gradient-100.jpg");
height: 100%;
background-position: top;
background-size: cover;
background-repeat: no-repeat;
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: -1;
`;
const BgImageOverlay = styled.div`
height: 100%;
opacity: 0.5;
background-color: white;
background-position: top;
background-size: cover;
background-repeat: no-repeat;
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: -1;
`;






const LogoContainer = styled.a`
padding: 0px;
width: 150px;
margin-top: 36px;
margin-bottom: 36px;
max-height: 70px;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}
`
const HeaderContainer = styled.div`
display: flex;
position:relative;
// flex-direction:column;
width: 100%;
height: ${NAVBAR_HEIGHT};
align-items: center;
justify-content:inherit;
height: ${NAVBAR_HEIGHT};
flex-grow: 0;
flex-shrink:0;
gap:15px;
border-bottom: 1.5px solid black;
// box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7); /* Optional styling for emphasis */
`

const FlexItem = styled.div`
  flex: 1;
  max-width: 33.3%;
  display: flex;
  justify-content: start;
  align-items: center;

    &:nth-of-type(1) {
    justify-content: start;
  }
`;