import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import { useAuthContext } from 'context/AppContext/AuthContext';
import { useAuth } from 'third_party/redux/selectors';
//UPDATED ITEMS
import { useUserActions } from 'third_party/redux/user/userAction';
import { useSearchActions } from 'third_party/redux/search/searchActions';
import { useSearch} from 'third_party/redux/selectors';
import SearchFunctions from 'utility/searchFunctions';

import GoogleMapsFunctions from 'utility/googleMapsFunctions';
// import { useGuestLoginWithPlace } from 'third_party/redux/convenientFunctions';
// import {getAddressFromAddressOrCoordinates} from "components/Inputs/GoogleMapsAddressInput"


const HomeURLContext = createContext();

export const useHomeURLContext = () => useContext(HomeURLContext) 



export const HomeURLProvider = ({ children }) => {

  const urlLocation = useLocation();
  const navigate = useNavigate();

  //NEW
  const {USER_UPDATE} = useUserActions()
  const {SEARCH_UPDATE} = useSearchActions()
  const searchCriteria = useSearch().current //THIS SHOULD BE REFERRED TO CURRENT
  // const loginAsGuestWithPlace = useGuestLoginWithPlace()
  //OLD
  // const { isLoggedIn, loggedInWithAddress } = useAuthContext();
  const {isLoggedIn} = useAuth()
  

  //is this initially loading the app
  const [isInitialLoad, setIsInitialLoad] = useState(true);








  // Parse URL on initial load
  //=========================
  useEffect(() => {

      console.log('useEffect Initial Load: ',searchCriteria)
      //function to parse URL
      const initializeSearchCriteriaFromURL = async () => {
      const params = new URLSearchParams(urlLocation.search);
      const address = params.get('address');
      const lat = params.get('lat');
      const lng = params.get('lng');
      const date = params.get('date') ? new Date(params.get('date')) : null;
      const duration = params.get('duration');
  
      let place = searchCriteria.location;
  
      try {
        if (address || (lat && lng)) {
          const returnedPlace = await GoogleMapsFunctions.getPlaceFromAddressOrCoordinates({
            address,
            lat,
            lng,
          });
          if (returnedPlace) {
            place = returnedPlace;
            // USER_UPDATE({ lat: place.lat, lng: place.lng });
            // loginAsGuestWithPlace(place);
          }
        }
        const updatedSearch = SearchFunctions.createDictionaryFromSearchCriteria({ place, date, duration });
        SEARCH_UPDATE(updatedSearch);
      } catch (error) {
        console.error('Error fetching place:', error);
      }
    };
    
    //calling the function and then setIsInitialLoad to False
    if (isInitialLoad) {
      initializeSearchCriteriaFromURL().then(() => {
        setIsInitialLoad(false);
      });
    }
  }, [isInitialLoad, urlLocation.search]);
  


  // Sync state to URL
  //=========================
  useEffect(() => {
    if (!isInitialLoad) {
      console.log('!initialLoad: ',searchCriteria)
      const params = SearchFunctions.searchParamsBasedOnDictionary(searchCriteria);
      navigate({ search: params }, { replace: true });
    }
  }, [searchCriteria, isInitialLoad]);


  //used to update link
  // const searchParamsBasedOnDictionary = (paramsDict) => {
  //   const newParams = new URLSearchParams();
  //   if (paramsDict.location) {
  //     const address = SearchFunctions.getAddressFromPlace(paramsDict.location);
  //     if (address) newParams.set('address', JSON.stringify(address));
  //   }
  //   if (paramsDict.date) newParams.set('date', paramsDict.date.toISOString());
  //   if (paramsDict.duration) newParams.set('duration', paramsDict.duration.toString());
  //   return newParams.toString();
  // };

    
      //================================
      //=================================

        return (
            <HomeURLContext.Provider value={{  }}>
            {children}
            </HomeURLContext.Provider>
        );
    };
