import React, { useRef, useEffect } from 'react';

import {styled, css, keyframes} from 'styled-components';
// import styled from 'styled-components';

import { NAVBAR_HEIGHT } from 'components/constants';
import { useState } from "react"

import GoogleMapsAddressInput from "components/Inputs/GoogleMapsAddressInput"

import GoogleMapsFunctions from 'utility/googleMapsFunctions';
// import {AddressSearchComponent} from "components/Inputs/AddressSearchComponent"
import SearchFunctions from 'utility/searchFunctions';
import { useSearch } from 'third_party/redux/selectors';
import { useSearchActions } from 'third_party/redux/search/searchActions';
import { useAppNavBarContext } from 'context/AppNavBarContext'; //search
// import { useUserAppSearchContext } from 'context/UserAppSearchContext';

import TopSearchPopUpEditor from './NewSearch/TopSearchPopUpEditor';
import { Z_INDEX_POPUP_MENUS } from 'components/constants';
import { useScreenContext } from 'context/AppContext/ScreenContext';
const SearchBarExpanded = ({isVisible = false, className, props}) => {

    //screen context
    const {isMobile, isMedium, isLarge} = useScreenContext()
    //context
    const tempSearchCriteria = useSearch().temp

    const {SEARCH_UPDATE,SEARCH_LOCATION} = useSearchActions()

    // const { closeSearchInputsViews,
    //     setIsLocationSearchVisibleToVisible,
    //     closeExpandedSearchEditMode,
    //     toggleLocationSearch,
    //     toggleWhenSearch,
    //     toggleDurationSearch,
    //     isLocationSearchVisible,
    //     isWhenSearchVisible,
    //     isDurationSearchVisible,
    //     updateSearchLocationPredictions
    // } = useAppNavBarContext();

    const {toggleSearchInput,setActiveSearchInput,//Determine which one is active, 'location','when','duration'
            resetContext,//reset all the contexts
            updateSearchLocationPredictions,//Search Predictions
            activeSearchInput,//what is active
            // activeSearchInput, setActiveSearchInput
    } = useAppNavBarContext();

//use ref for location trigger joint
const locationInputRef = useRef(null);
// Use the `useEffect` hook to conditionally focus the input when the `shouldFocus` prop is true
useEffect(() => {
// if (isMedium || isLarge){
    console.log("is being triggered useEFFECT!")
    console.log("isLocationSearchVIsible: ", activeSearchInput==='location')
    console.log("locationInputRef.current: ", locationInputRef.current)

  if (activeSearchInput==='location' && locationInputRef.current) {
    console.log("HERE BRO!")
    locationInputRef.current.focus();

    // Move cursor to the end of the input field
    const inputLength = locationInputRef.current.value.length;
    locationInputRef.current.setSelectionRange(inputLength, inputLength);
  }
// }
}, [activeSearchInput]);

// }, [isLocationSearchVisible]);

    //variables
    const [isDurationContentHighlighted, setIsDurationContentHighlighted] = useState();
    const [searchAddress ,setSearchAddress] = useState(""); //AJALON ADDED THIS


    // const handleSearchSubmit = async (address) => {
    //     try{
    //         const place = await GoogleMapsFunctions.returnPlaceFromAddress({address})
    //         SEARCH_LOCATION(place)
    //     } catch(error){
    //         console.error('Error fetching place from address:', error);
    //     }
    //   };
    
      // Function to handle place selection
      const handlePlaceSelected = (place) => {
        console.log('Place selected:', place);
        SEARCH_LOCATION(place)
        // closeSearchInputsViews()
        resetContext()
    };


    //Location Search Items Activated
    const LocationOnFocus = () => {
        // setActiveSearchInput('location')
        // setIsLocationSearchVisibleToVisible()
    }
    const LocationOnBlur = () => {
        // setIsLocationSearchVisible(false)
        setActiveSearchInput('location')

    }
    const handleLocationContainerClicked = () => {
        toggleSearchInput('location')
        // setIsLocationSearchVisibleToVisible()
    }

    //When Activated
    const handleWhenContainerClicked = () => {
        toggleSearchInput('when')
        // toggleWhenSearch()
    }
    //Duration Activated
    const handleDurationContainerClicked = () => {
        toggleSearchInput('duration')
        // toggleDurationSearch()
    }
    const submitSearch = () => {
        // closeExpandedSearchEditMode()
        resetContext()
        SEARCH_UPDATE(tempSearchCriteria)
        console.log("TRIGGER SEARCH UPDATES")
    }



    const inputChange = (inputValue) => {
        console.log("SHIT CHANGED: ",inputValue)
        updateSearchLocationPredictions(inputValue)
        setActiveSearchInput('location')
      }

    return (
        <>
        {isVisible && (
        <>
        <ExpandedContainer $isvisible={isVisible} className={`input-please-work ${className}`} {...props}>

            {/* <LocationContainer onClick={handleLocationContainerClicked} $isvisible={isLocationSearchVisible}>  */}
            <LocationContainer onClick={handleLocationContainerClicked} $isvisible={activeSearchInput==='location'}> 
                <InputContainer>
                    <SearchElementTitle>Where</SearchElementTitle>
                    <GoogleMapsAddressInputSpecial ref={locationInputRef} onPlaceSelected={handlePlaceSelected} value={SearchFunctions.getAddressFromPlace(tempSearchCriteria.location)} onFocus={LocationOnFocus} onBlur={LocationOnBlur} className='test' disableAutoComplete={true} onChange={inputChange}/>
                    {/* onSearchSubmit={handleSearchSubmit} */}
                </InputContainer>
            </LocationContainer>

            {/* <DateContainer onClick={handleWhenContainerClicked} $isvisible={isWhenSearchVisible}> */}
            <DateContainer onClick={handleWhenContainerClicked} $isvisible={activeSearchInput==='when'}>
                <InputContainer>
                    <SearchElementTitle>When</SearchElementTitle>
                    <SearchElementP>{(SearchFunctions.displayAbbreviatedWeekMonthDayTime(tempSearchCriteria.date))}</SearchElementP>
                </InputContainer>
            </DateContainer>

            {/* <DurationContainer $ishighlighted={isDurationContentHighlighted} $isvisible={isDurationSearchVisible}> */}
            <DurationContainer $ishighlighted={isDurationContentHighlighted} $isvisible={activeSearchInput==='duration'}>
                <DurationContent onClick={handleDurationContainerClicked} onMouseEnter={() => setIsDurationContentHighlighted('true')} onMouseLeave={() => setIsDurationContentHighlighted()}>
                    {/* <InputContainer $isvisible={isLocationSearchVisible}> */}
                    <InputContainer $isvisible={activeSearchInput==='location'}>
                        <SearchElementTitle>Duration</SearchElementTitle>
                        <SearchElementP>{SearchFunctions.displayDuration(tempSearchCriteria.duration)}</SearchElementP>
                    </InputContainer>
                </DurationContent>

                <SearchButtonContainer>
                    <SearchButton onClick={submitSearch}>
                        Update Search
                        {/* <img src="/images/searchicon.png" alt="searchicon" /> */}
                    </SearchButton>
                </SearchButtonContainer>

            </DurationContainer>
        </ExpandedContainer>
        <TopSearchPopUpEditor/>
        </>
        )}
        </>
    )
}

export default SearchBarExpanded

const GoogleMapsAddressInputSpecial = styled(GoogleMapsAddressInput)`
    background-color: transparent;
    color: #00000080;
    font-size: .875em;
    height: auto;

    // &.input-please-work {
    //     background-color: red !important;
    //   }
`

const ExpandedContainer = styled.div`
display: flex;
width: 70%;
top: ${props => (props.$isvisible ? '${NAVBAR_HEIGHT}' : '0')};
height: ${props => (props.$isvisible ? '${NAVBAR_HEIGHT}' : `0px`)};
opacity: ${props => (props.$isvisible ? '1' : '0')};
// margin: ${props => (props.$isvisible ? '15px 5px;' : '0px')};
align-items: center;
transition: opacity 0.3s ease, height 0.5s ease, top 0.3s ease;
border-radius: 25px;
// box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);
box-shadow: 0 0 1.5px #00000080;
background-color: ${props => (props.$isvisible ? '#EEEEEE80' : 'transparent')};


// @media (max-width: 768px){
//     display: none;
// }

`

const InputContainer = styled.div`
display: flex;
flex-direction: column;
height: 80%;
width: 90%;
justify-content: center;
align-items: center;
`
const LocationContainer = styled.div`
display: flex;
flex-direction: column;
min-width: calc(100% / 6 * 2);
cursor: pointer;
min-height:${NAVBAR_HEIGHT};
padding: 5px 10px;
align-items: center;
justify-content: center;
background-color: ${props => (props.$isvisible ? 'white' : 'transparent')};
border-radius: 25px;
z-index: ${props => (props.$isvisible ? `calc(${Z_INDEX_POPUP_MENUS} - 1)` : '0')};

&:hover {
    // background-color: #CCCCCC20;
    background-color: ${props => (props.$isvisible ? 'white' : '#CCCCCC60')};
    border-radius: 25px;
}

`
const DateContainer = styled.div`
display: flex;
flex-direction: column;
min-width: calc(100% / 6 * 2);
cursor: pointer;
min-height:calc(${NAVBAR_HEIGHT});
padding: 5px 10px;
align-items: center;
justify-content: center;
background-color: ${props => (props.$isvisible ? 'white' : 'transparent')};
border-radius: 25px;
z-index: ${props => (props.$isvisible ? `calc(${Z_INDEX_POPUP_MENUS} - 1)` : '0')};

&:hover {
    // background-color: #CCCCCC20;
    background-color: ${props => (props.$isvisible ? 'white' : '#CCCCCC60')};
    border-radius: 25px;
}

`

const DurationContainer = styled.div.attrs(props => ({
'data-highlighted': props.$ishighlighted ? 'true' : 'false',
  }))
`

display: flex;
flex-direction: row;
min-width: calc(100% / 6 * 2);
cursor: pointer;
// overflow: none;
height:100%;//calc(${NAVBAR_HEIGHT});
padding: 5px 10px;
background-color: ${props => (props.$isvisible ? 'white' : 'transparent')};
border-radius: 25px;
z-index: ${props => (props.$isvisible ? `calc(${Z_INDEX_POPUP_MENUS} - 1)` : '0')};


    ${({ $ishighlighted }) => $ishighlighted && css`
    // background-color: #cccccc20;
    background-color: ${props => (props.$isvisible ? 'white' : '#CCCCCC60')};
    border-radius: 25px;
    `}
`
const DurationContent = styled.div
  `
display: flex;
flex-direction: column;
flex-grow: 1;
flex-shrink: 1;
align-items: center;
justify-content: center;

`

const SearchButtonContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
// min-width: calc(100% / 6);
cursor: pointer;
align-items: center;
justify-content: center;

img {
    display: block;
    width:50%;
  }

`

const SearchButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: calc(${NAVBAR_HEIGHT});
width: calc(2 * ${NAVBAR_HEIGHT}); /*wasn't 2 */
background-color: rgba(251, 174, 98, 1.0);
border-radius: 25px; /*50%*/
border: none;
cursor: pointer;
color: white;
font-weight: 600;
&:hover {
    background-color: rgba(251, 174, 98, 0.6);
}
`


const SearchElementP = styled.p`
color: #00000080;
font-size: .875em;
font-weight: 500;
margin: 0px;
`

const SearchElementTitle = styled.p`
color: #000000;
font-size: 12px;
font-weight: 200;
margin: 0px;
`