import React from 'react';
// import { AppNavBarContext } from './AppNavBarContext';
import { ScreenProvider } from './AppContext/ScreenContext';
import { AuthProvider } from './AppContext/AuthContext';
import {UserProvider} from './AppContext/UserContext'
// import {GoogleMapsAddressProvider } from '../utility/googleMapsFunctions'
import { GeneralSignUpMenuProvider } from 'components/SignUpMenu/GeneralSignUpMenu';

import {UserServiceAPIProvider} from 'api/User/Deprecated/UserServiceAPIContext'
import { BusinessServiceAPIProvider } from 'api/Business/BusinessService/BusinessServiceAPIContext';
import { Provider } from 'react-redux';
import store from 'third_party/redux/store';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider

export const AppProvider = ({ children }) => {
    return (
        
        <Provider store = {store}>
            {/* <GoogleMapsAddressProvider> */}
            <HelmetProvider>
                <ScreenProvider>
                    <AuthProvider>
                        <UserProvider>
                            <GeneralSignUpMenuProvider> 
                                    <UserServiceAPIProvider>
                                        <BusinessServiceAPIProvider>
                                            {children}
                                        </BusinessServiceAPIProvider>
                                    </UserServiceAPIProvider>
                            </GeneralSignUpMenuProvider> 
                        </UserProvider>
                    </AuthProvider>
                </ScreenProvider>
            </HelmetProvider>
            {/* </GoogleMapsAddressProvider> */}
         </Provider>

    );
  };
  
  export default AppProvider;