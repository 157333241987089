import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';

import { Helmet } from 'react-helmet-async';



import NavBarLogout from 'pages/home/header/NavBarLogout';
import { ScrollProvider } from 'pages/home/header/ScrollContext';

// import NavBarLogoutGeneral from 'components/navbar/NavBarLogoutGeneral';


import {PageContentContainer} from "components/Page/Elements"

import { buttonSubmitStyle } from 'components/Buttons/ButtonStyles';
import { useScreenContext } from 'context/AppContext/ScreenContext';



const BizDevRepFairfax = () => {

    const {isMobile, isMedium, isLarge} = useScreenContext()


    const jobtitle = 'Business Development Rep I - GoghNow For Business'
    const jobsubtitle = 'Business Development I'
    const joblocation = 'Fairfax County/Remote'
    const jobsubtitledescription = 'Part Time/Project-Based'

    const jobDescription = `Join GoghNow and be a key driver in launching GoghNow for Business, our cutting-edge platform revolutionizing how businesses deliver on-demand event services to their clients. As a Growth Architect, you'll be at the forefront of introducing a game-changing solution that empowers businesses to seamlessly integrate instant access to event services. You'll also play a pivotal role in driving our expansion through generating high-quality leads, cultivating strategic partnerships, and setting appointments with key business decision-makers.

    This is a unique opportunity to shape the future of business services, working directly with the CEO and COO to forge strategic partnerships and drive explosive growth. You will introduce them to our platform, and schedule high-value meetings that drive adoption of GoghNow across multiple industries. If you're a results-oriented innovator with a passion for disrupting markets by making immediate event service access a reality, this is your chance to make a monumental impact in a fast-paced, dynamic startup environment.`;
    const jobDescriptionSplit = jobDescription.split('\n\n');

    const WhatYoullDo = [
        "<b>Generate high-quality leads and cultivate strategic partnerships</b>, identifying businesses that can benefit from GoghNow’s event services platform.",
        "<b>Set up and schedule meetings</b> with business decision-makers for the CEO and COO.",
        "<b>Engage with businesses that rent event spaces</b>, introducing them to our platform and encouraging client adoption.",
        "<b>Follow up on inbound and outbound leads</b>, nurturing potential partnerships through strong communication and relationship-building.",
        "<b>Work seamlessly with cross-functional teams (Sales, Marketing, Operations) to ensure seamless handoff of qualified leads.</b>",
        "<b>Track and manage outreach efforts</b>, maintaining accurate records in CRM tools.",
        "<b>Provide feedback to leadership</b>, sharing insights on market trends and customer needs.",
        "<b>Contribute to the development of scalable processes.</b>"
      ];

      const Requirements = [
        "<b>1-3 years of experience</b> in sales, lead generation, or business development (B2B experience preferred)",
        "Strong communication and interpersonal skills with the ability to <b>engage and build rapport quickly.</b>",
        "<b>Proven ability to set appointments and generate quality leads</b> that drive business growth.",
        "High level of <b>organization and attention to detail</b> to track leads and manage follow-ups effectively.",
        "A <b>self-motivated, goal-oriented mindset</b> with a passion for sales and business development.",
        "<b>Adaptability and problem-solving skills</b> to thrive in a fast-paced startup environment.",
      ];

      const ExperienceRequirements = [
        "Experience in multi-family real estate (e.g., property management, leasing, community management).", 
        "Experience in hospitality (e.g., hotel sales, event services, venue management).", "Experience in high-end restaurants (e.g., private dining, event coordination).",
        "Previous sales and account management experience.",
        "Existing event industry experience (corporate or independent).",
        "Experience as an Executive Assistant or Office Manager."
      ];

      const Benefits = [
        "Be part of a groundbreaking company transforming how events are managed.",
        "Work in a dynamic, fast-paced environment where your efforts directly impact growth.",
        "Collaborate with a passionate and talented team that’s shaping the future of event services.",
        "Make a real impact on the company’s expansion.",
        "Enjoy competitive compensation with potential growth opportunities."
      ];

      const HowToApply = "If you’re a <b>motivated sales professional</b> with experience in <b>multi-family, hospitality, or high-end restaurants</b>, we’d love to hear from you! Apply now and let’s connect. "

      const handleClick = () => {
        window.location.href = `mailto:careers@goghnow.com?subject=GoghNow Applicant: Business Development Rep I`;
      };

      const scrollToTop = () =>{
        console.log("this is working?")
        // setTimeout(() => contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' }), 10);
      } 

    return (
        <>
            <Helmet>
                <title>GoghNow Careers | Explore GoghNow Careers</title>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="GoghNow Careers | Build The Future of Events" />
                <meta name="description" content="GoghNow is a one-stop-shop for event services" />
                <meta property="og:image" content="images/cta-logo-one.png" />
            </Helmet>
            <ScrollProvider>
            <SpecialNavBarLogout handleNavigateHome={scrollToTop}/>
            <PageContentContainerCustom>
                <MainPost>
                    <JobTitle>{jobtitle}</JobTitle>
                    <JobSubTitle>{jobsubtitle} in {joblocation}</JobSubTitle>
                    <JobSubTitleDescription>{jobsubtitledescription}</JobSubTitleDescription>
                    <ApplyButton onClick={handleClick} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Submit Resume Today</ApplyButton>
                    <ParagraphContainer>resumes can be submitted to <b>careers@goghnow.com</b></ParagraphContainer>
                    <Line />

                    <MiniHeader>About This Role</MiniHeader>
                        <ParagraphContainer>
                            {jobDescriptionSplit.map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))}
                        </ParagraphContainer>

                    <MiniHeader>What You’ll Do:</MiniHeader>
                    <StyledList>
                        {WhatYoullDo.map((task, index) => (
                            <StyledListItem key={index} dangerouslySetInnerHTML={{ __html: task }} />
                        ))}
                    </StyledList>

                    <MiniHeader>What You’ll Need:</MiniHeader>
                    <StyledList>
                        {Requirements.map((requirement, index) => (
                            <StyledListItem key={index} dangerouslySetInnerHTML={{ __html: requirement }} />
                        ))}
                    </StyledList>

                    <MiniHeader>Preferred Industry Experience</MiniHeader>
                    <StyledList>
                        {ExperienceRequirements.map((niceToHave, index) => (
                            <StyledListItem key={index} dangerouslySetInnerHTML={{ __html: niceToHave }} />
                        ))}
                    </StyledList>
                    <ParagraphContainer>Candidates with prior experience as a <b>General Manager, Property Manager, or Events Manager</b> are strongly encouraged to apply. </ParagraphContainer>


                    <MiniHeader>Why Join GoghNow</MiniHeader>
                    <StyledList>
                        {Benefits.map((benefit, index) => (
                            <StyledListItem key={index} dangerouslySetInnerHTML={{ __html: benefit }} />
                        ))}
                    </StyledList>
                    <MiniHeader>How to Apply</MiniHeader>
                    <ParagraphContainer dangerouslySetInnerHTML={{ __html: HowToApply }} />

                    <Line />
                    <ApplyButton onClick={handleClick} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Submit Resume Today</ApplyButton>
                    <ParagraphContainer>resumes can be submitted to <b>careers@goghnow.com</b></ParagraphContainer>

                </MainPost>
            </PageContentContainerCustom>
            </ScrollProvider>
        </>
    )
}

export default BizDevRepFairfax;

const SpecialNavBarLogout = styled(NavBarLogout)`
    position:fixed;
    background-color: transparent !important;
`

const PageContentContainerCustom = styled(PageContentContainer)`
background-color: white;
color: black;
padding: 48px 24px 36px;
align-items: center;
`
const MainPost = styled.div`
display: flex;
flex-direction: column;
max-width: 800px;
`

const JobTitle = styled.h1`
margin-bottom: 0px;
`
const JobSubTitle= styled.h3`
margin-top: 0px;
margin-bottom: 0px;
`
const JobSubTitleDescription = styled.h3`
margin-top: 0px;
`

const MiniHeader = styled.p`
  font-weight: bold;
`

const NormalText = styled.p`

`

const ParagraphContainer = styled.div`
  p {
    margin-bottom: 1em;
  }
`;



// Styled unordered list and list item
const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 20px; /* Adds padding to indent bullet points */
`;

const StyledListItem = styled.li`
  margin-bottom: 0.5em; /* Adds space between list items */
`;


const ApplyButton = styled.button`
${buttonSubmitStyle}
  width: ${props => {
    if (props.$isMobile) return '100%';
    if (props.$isMedium) return '75%';
    if (props.$isLarge) return '50%';
    return '50%'; // Default to large if none match
  }}; 
margin: 25px 0px 0px 0px;
`

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin-top: 10px; /* Adjust the spacing above the line */
`;