
import React from "react";
import { useNavigate } from "react-router-dom";


//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';


const BreadcrumbNavigation = ({ breadcrumbs }) => {
  const navigate = useNavigate();

  const handleNavigation = (link) => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <Box>
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          {index !== breadcrumbs.length - 1 ? (
            <>
              <Link onClick={() => handleNavigation(breadcrumb.link)}>
                {breadcrumb.name}
              </Link>
              <Text>/</Text>
            </>
          ) : (
            <TextSelected>{breadcrumb.name}</TextSelected>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default BreadcrumbNavigation;

const Box = styled.div`
  display: flex;
  gap: 8px;
`;

const Text = styled.p`
  color: black;
`;

const TextSelected = styled.p`
  color: black;
  font-weight: bold;
`;

const Link = styled.p`
  // margin: 0px 8px;
  cursor: pointer;
  color: #00000060;
  border: none;
  background-color: transparent;
  &:hover {
    color: black;
    border-bottom: 1px solid black;
  }
`;
// import React from "react";
// import { useNavigate } from "react-router-dom";


// //emotions
// import styled from '@emotion/styled'
// import { css, cx, keyframes } from '@emotion/css';


// const BreadcrumbNavigation = ({currentPage}) => {
//   const navigate = useNavigate();

//   const handleAccountSettingsClick = () => {
//     navigate("/account-settings");
//   };

//   return (
//     <Box>
//       <Link
//         onClick={handleAccountSettingsClick}
//       >
//         account-settings
//       </Link>
//       <Text>/</Text>
//       <TextSelected>{currentPage}</TextSelected>
//     </Box>
//   );
// };



// export default BreadcrumbNavigation;

// const Box = styled.div`
// display: flex;
// gap: 8px;
// `
// const Text = styled.p`
// color: black;
// `
// const TextSelected = styled.p`
// color: black;
// // font-weight: bold;
// `
// const Link = styled.p`
//     magin: 0px 8px;
//     cursor:pointer;
//     // font-weight: bold;
//     color: #00000060;
//     border: none;
//         background-color: transparent;
//     &:hover{
//     color:black;
//     border-bottom: 1px solid black;
//     }
// `