import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

import React, { useEffect, useRef, useState } from "react";


import { useScroll } from 'pages/home/header/ScrollContext';


//DELETE THIS ONE
export const PageContainer = styled.div`
display: flex;
flex-direction: column;
height: 100vh;
height: 100dvh;
// width: 100vw;
// width: 100dvw;
background-color: white;
overflow-y: hidden;
overflow-x:hidden;
`


export const GoghNowPageContentContainer = styled.div`
display:inline-block;
// flex-direction: column;
// flex: 1 1;
width: 100vw;
position:relative;
background-color: black;
// height: 100vh;
// overflow-y: auto; /* Enable vertical scrolling */

`


export const PageContentContainer = ({ children, ...props }) => {
    const {isScrolledFromTop, handleScrollStateChangeFromTop} = useScroll()
    const contentRef = useRef(null);
  
    useEffect(() => {
      const handleScroll = () => {
          const scrollPosition = window.scrollY; // Tracks body scroll position
          const isScrolled = scrollPosition > 1;
          if (isScrolled !== isScrolledFromTop) {
              // Update context state only if the scroll state changes
              handleScrollStateChangeFromTop(isScrolled);
          }
      };

      // Add scroll listener
      window.addEventListener("scroll", handleScroll);

      return () => {
          // Clean up scroll listener
          window.removeEventListener("scroll", handleScroll);
      };
  }, [isScrolledFromTop, handleScrollStateChangeFromTop]);

    return (
      <GoghNowPageContentContainer ref={contentRef} {...props}>
        {children}
      </GoghNowPageContentContainer>
    );
  };
  