import { Cookies } from 'react-cookie';

class ReactCookie {
    // Make cookies a static property
    static cookies = new Cookies();
    static TOKEN_KEY = 'token';
    static REFRESH_TOKEN_KEY = 'refresh_token';
    static IS_FIRST_TIME_VISITOR = 'first_time_visitor'

    // Save a cookie
    static saveCookie(name, value, options = {}) {
        this.cookies.set(name, value, {
            path: '/', // Default to root path
            ...options, // Allow custom options like expires, httpOnly, etc.
        });
    }

    // Get a cookie
    static getCookie(name) {
        return this.cookies.get(name);
    }

    // Delete a cookie
    static deleteCookie(name, options = {}) {
        this.cookies.remove(name, {
            path: '/', // Default to root path
            ...options, // Allow custom options for deletion
        });
    }

    //======================
    // Save token to cookies
    //======================
    static saveToken(token, options = { path: '/' }) {
        return this.saveCookie(this.TOKEN_KEY, token, options);
    }

    // Save refresh token to cookies
    static saveRefreshToken(refreshToken, options = { path: '/' }) {
        return this.saveCookie(this.REFRESH_TOKEN_KEY, refreshToken, options);
    }

    // Load token from cookies
    static getToken() {
        return this.getCookie(this.TOKEN_KEY);
    }

    // Load refresh token from cookies
    static getRefreshToken() {
        return this.getCookie(this.REFRESH_TOKEN_KEY);
    }

    // Clear token from cookies
    static deleteToken(options = { path: '/' }) {
        this.deleteCookie(this.TOKEN_KEY, options);
    }

    // Clear refresh token from cookies
    static deleteRefreshToken(options = { path: '/' }) {
        this.deleteCookie(this.REFRESH_TOKEN_KEY, options);
    }

    // General method to save both token and refresh token
    static saveTokens(token, refreshToken, options = { path: '/' }) {
        if (token) this.saveToken(token, options);
        if (refreshToken) this.saveRefreshToken(refreshToken, options);
    }

    // General method to load both token and refresh token
    static getTokens() {
        return {
            token: this.getToken(),
            refreshToken: this.getRefreshToken(),
        };
    }

    // General method to clear both token and refresh token
    static deleteTokens(options = { path: '/' }) {
        this.deleteToken(options);
        this.deleteRefreshToken(options);
    }

    static isFirstTimeVisitor() {
        const value = this.getCookie(this.IS_FIRST_TIME_VISITOR);
        // Return true if the cookie is undefined or set to "true", otherwise return false
        
        return value === undefined || value === "true";
    }
    static markAsVisited(options = {path: '/'}){
        // Save the value `false` as a string
        this.saveCookie(this.IS_FIRST_TIME_VISITOR, "false", options);
        return false; // Optionally return `false` directly
    }

}

export default ReactCookie;
