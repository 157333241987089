import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'
import { NAVBAR_HEIGHT, SECTION_PADDING } from 'components/constants';
import { useState, Component } from "react"

import EventServiceImageContainer from "./EventServiceImageContainer"


const EventServiceItem = ({item, addToCart, removeFromCart, cartItems, toggleCartItem, ...props}) => {

    const {title, description,id,name,picture} = item
    const isSelected = cartItems.some(cartItem => cartItem.id === id);

    // const images = item.images
    const toggleCartItemSelection = (e,item) => {

        e.stopPropagation();
        toggleCartItem(item)
    }

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const showToolTip = () =>{

        setIsTooltipVisible(true)
        console.log("tooltip should be visible")
    }

    return (
        <Container {...props} $isSelected={isSelected} onClick={(e) => toggleCartItemSelection(e,item)}>
                <ContainerContent>
                    <HeaderPanel>
                        
                        <ImageContentPanelContainer onMouseEnter={showToolTip} onMouseLeave={() => setIsTooltipVisible(false)}>
                                    <img src={picture} alt="Logo"/>
                        </ImageContentPanelContainer>
                        <Tooltip $visible={isTooltipVisible}><p>{name}</p></Tooltip>

                        <HeaderPanelContentContainer>
                            <Title>{title}</Title>
                        </HeaderPanelContentContainer>

                    </HeaderPanel>
                    <EventServiceImageContainerSpecial item={item} addToCart={addToCart} removeFromCart={removeFromCart} toggleCartItem={toggleCartItem} cartItems={cartItems} isSeleted={isSelected} aspectRatio='1 / 1'>
                        
                        <ContentOnImage>
                            <ContentRowOnImage>
                        <HeaderPanelImageContainer>
                            <img src={item.categoryIcon} alt="Logo"/>
                        </HeaderPanelImageContainer>
                            </ContentRowOnImage>
                        </ContentOnImage>
                    </EventServiceImageContainerSpecial>
                    <TextContainer>
                        <Description>
                            {description}
                        </Description>
                    </TextContainer>
                </ContainerContent>
        </Container>
    )
}
export default EventServiceItem;

//NEW NEW
const Tooltip = styled.div`
  position: absolute;
  background-color: black;
  color: white;
  padding: 5px 10px;;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  display: ${(props) => (props.$visible ? 'flex' : 'none')};
  left: 30px;
  top: -30px;
  height: auto;
  width: auto;
  z-index: 40;
`;
 //NEW SHIT
const EventServiceImageContainerSpecial = styled(EventServiceImageContainer)`
padding: 0px;
color: white;
border-radius:15px;
`
const HeaderPanel = styled.div`
display: flex;
height: auto;
// max-height: 60px;
width: 100%;
max-width:100%;
padding: 5px;
`
const HeaderPanelImageContainer = styled.div`
height: 25px;
padding:2.5px;
aspect-ratio: 1 / 1;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
overflow: hidden;
// cursor: pointer;
background-color: #E0CAA4;

img{
 display: block;
        
        width: 100%; /* Ensures image keeps its aspect ratio */
        height: 100%;
        padding:2.5px;
        object-position: center; /* Center the image */
        &:hover {
            opacity:0.7;
        }
}
`

const ContentOnImage = styled.div`
display:flex;
flex-direction:column;
align-items: start;
justify-content:end;
width: 100%;
height:100%;
padding:5px;

`
const ContentRowOnImage = styled.div`
position:relative;
display:flex;
width:100%;
`

const ImageContentPanelContainer = styled.div`
height: 35px;
aspect-ratio: 1 / 1;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
overflow: hidden;
border: solid 1px #EEEEEE;
background-color: black;
cursor: pointer;

img{
 display: block;
        // min-height: 100%;
        // min-width: 100%;
        width: 100%; /* Ensures image keeps its aspect ratio */
        height: 100%;
        // object-fit: cover; /* Maintain aspect ratio */
        object-position: center; /* Center the image */
        &:hover {
            opacity:0.7;
        }
}
`

const HeaderPanelContentContainer = styled.div`
display: flex;
flex-direction:column;
align-items: space-evenly; 
justify-content:center;
min-height: 100%;
padding-left: 5px;
`

 //END OF FNEW SHIT


 const Container = styled.div`
 position: relative;
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 100%;
 border-radius: 5%;
 box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
 padding: 5px;
 overflow: visible; // Allow Tooltip to be displayed outside
`;

const ContainerContent = styled.div`
 display: flex;
 flex-direction: column;
 width: 100%; 
 max-width: 100%; // Adjust as needed
 height: auto;
 padding: 5px;
 overflow-x: hidden; 
//  z-index: 1; // Optional: If needed to manage layering
`;
// const Container = styled.div`
// position:relative;
// display: flex;
// flex-direction: column;
// width: 100%;
// max-width: 100%;
// overflow-x: visible;
// border-radius: 5%;
// box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
// margin: 5px;
// `
const TextContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 5em;
justify-content: start;
align-items: start;
padding: 10px 5px 5px 5px;
gap: 5px;
`

const Title = styled.p`
font-size: 14px;
font-weight: 300;
text-align: left;
color: black;
margin: 0px;
`
const Description = styled.p`
font-size: 12px;
font-weight: 200;
color: #00000080;
margin: 0px;
text-align: left;
`










