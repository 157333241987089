
import { get, post, put } from 'api/apiRequests'; // Assuming your `apiCalls.js` file is in the same directory
import ReactCookie from 'third_party/cookies/ReactCookies';
import apiUtils from 'api/apiUtils';
// import { useStateManagementFunctions } from 'third_party/redux/stateManagementFunctions';

//TYPE ALIAS
type LoginResult = {
    token: string;
    refresh_token: string
};

type LogoutResult = {
    message: string
};

type RefreshTokenResult = {
    token: string;
};



export let loginURL = 'user/auth/login'
export let logoutURL = 'user/auth/logout'
export let refreshTokenURL = 'user/auth/refresh-token' //REMOVED THE GET IN HERE AND JUST SIMPLIFY 'auth/get/token'
// export let validateTokenURL = 'auth/validate-token' //REMOVED THE GET IN HERE AND JUST SIMPLIFY 'auth/get/token'




class AuthServiceAPI {

    
login = async(phone_number:string,email:string,password:string): Promise<LoginResult> => {

    try{
        const url = loginURL
        const params = {
            email:email,
            phone_number:phone_number,
            password:password,
        }
        const response = await post(url,params)
        
        console.log("login response: ",response)
        // Save tokens in cookies
        apiUtils.saveTokens(response.token,response.refresh_token)

        //return data
        return response
    } catch(error){
        console.error('Error logging in:', error);
        // const {logout} = useStateManagementFunctions()
        // logout();
        throw error; // Re-throw the error for handling in the calling component
    }
}

// logout = async(token:string,refresh_token:string): Promise<LogoutResult> => {

logout = async(): Promise<LogoutResult> => {

    try{
        const url = logoutURL
        const token = apiUtils.getToken() || ''
        const refresh_token = apiUtils.getRefreshToken() || ''

        console.log("logout() token: ",token)
        console.log("logout() refresh_token: ",refresh_token)

        const params = {
            'token':token,
            'refresh_token':refresh_token
        }
        console.log("logout tokens: ",params)
        // const params = {
        //     token:token,
        //     refresh_token:refresh_token,
        // }
        const response = await post(url,params)

        //
        // const {logout} = useStateManagementFunctions()
        // logout();
        //DELETES TOKENS AND AUTH SHIT

        console.log("response:",response)
        //return message
        return response
    } catch(error){
        console.error('Error signing out:', error);
        throw error; // Re-throw the error for handling in the calling component
    }
}


// refresh_token = async(token:string,refresh_token:string): Promise<RefreshTokenResult> => {
refresh_token = async(refresh_token:string): Promise<RefreshTokenResult> => {

    try{
        const url = refreshTokenURL
        console.log('refresh_token:',refresh_token)
        const params = {
            // token:token,
            refresh_token:refresh_token,
        }
        const response = await post(url,params)
        
        //save token in cookie :) 
        // const {login} = useStateManagementFunctions()
        // login(response.token,refresh_token,response.user_type)

        //return joint
        return response.data
    } catch(error){
        console.error('Error validating invite code:', error);
        throw error; // Re-throw the error for handling in the calling component
    }
}



// validate_token = async(token:string,refresh_token:string): Promise<RefreshTokenResult> => {

//     try{
//         const url = validateTokenURL
//         const params = {
//             token:token,
//             refresh_token:refresh_token,
//         }
//         const response = await post(url,params)
        
//         //save token in cookie :) 
//         apiUtils.saveToken(response.token)

//         //return joint
//         return response.data
//     } catch(error){
//         console.error('Error validating invite code:', error);
//         throw error; // Re-throw the error for handling in the calling component
//     }
// }



}

export default AuthServiceAPI
