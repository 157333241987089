import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import { buttonSubmitStyle } from 'components/Buttons/ButtonStyles';
import { NAVBAR_HEIGHT } from 'components/constants';
import { Z_INDEX_POPUP_MENUS } from 'components/constants';

import { frostedGlassStyle, frostedContainerStyle } from 'components/emotionStyles';
const SignUpPopUpNotification = ({ formRef, titleText, descriptionText, buttonText , zIndex=Z_INDEX_POPUP_MENUS,onClose, isOpen: externalIsOpen, children}) => {
    const [isOpen, setIsOpen] = useState(true);

    // Sync internal state with external isOpen prop if provided
    useEffect(() => {
      if (externalIsOpen !== undefined) {
        setIsOpen(externalIsOpen);
      }
    }, [externalIsOpen]);

    

    
    const handleClose = () => {
        setIsOpen(false);
        if (onClose) {
          onClose();
        }
      };


  return (
    <>
    {isOpen &&(
      <OverlayWrapper $isVisible={isOpen} $zIndex={zIndex}>
          <PopUpContainer>
            <HeaderRow>
                <button onClick={handleClose}>x</button>
            </HeaderRow>
            <Title>{titleText}</Title>
            <Description>{descriptionText}</Description>
            {children}
          </PopUpContainer>
      </OverlayWrapper>
    )}
    </>
  );
};

export default SignUpPopUpNotification;


// Keyframes for the fade-in and slide-in animations
const fadeIn = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const slideIn = css`
  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;


const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000090;
  display: flex;
  z-index: ${props => (props.$zIndex)};
  justify-content: center;
  align-items: center;

      ${frostedGlassStyle}

  // Apply fade-in animation when visible
  animation: fadeIn 0.5s ease forwards;
  ${fadeIn}
`;

const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  min-height: 25vh;
  max-width: 600px;
  background-color: #fff;
  border-radius: 10px;   
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 35px 10%;
  gap:25px;

${frostedContainerStyle}
  
  // Apply slide-in animation from the bottom
  animation: slideIn 0.5s ease forwards;
  ${slideIn}
`;

const HeaderRow = styled.div`
display:flex;
position:absolute;
right:15px;
top:10px;
align-items: center;
justify-content: end;
// width: 100%;

button {
    background-color: transparent;
    display: flex;
    align-items:center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-weight: 200;
    font-size: 2.0em;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
        background-color: #CCCCCC;
    }
`
const Title = styled.h2`
  display: flex;
  text-align: center;
  font-weight: bold;
  width: 100%;
  color: black;
  margin-bottom: 0px;
  justify-content: center;
`;
const Description = styled.p`
  display: flex;
  text-align: center;
//   width: 100%;
  color: black;
  margin-top:0px;
`;



