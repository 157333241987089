import { useParams } from "react-router-dom";
import { lazy, Suspense } from "react";
import ArticleManager from "./articles/HelpArticleData";

import HelpArticleContainer from "./components/HelpArticleContainer";
import { NAVBAR_HEIGHT } from "components/constants";

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

const HelpArticle = () => {
    const { category, postId } = useParams();

    //check if the category exists
    const blogsInCategory = ArticleManager.HelpArticleData()[category]

    // Find the specific blog post in the category
    const blogPost = blogsInCategory?.find(post => post.id === postId);

    if(!blogPost) {
        return <h1>Blog post not found</h1>;
    }
   
        // Dynamically import the blog component using the JS file name from the data
        const BlogComponent = lazy(() => import(`pages/help/articles/${category}/${blogPost.js}.js`));


        return (
            <HelpArticleContainer>
                <Container>
                    <Suspense fallback={<div>Loading...</div>}>
                        <BlogComponent />
                    </Suspense>
                </Container>
            </HelpArticleContainer>
        );
   

};

export default HelpArticle;

const Container = styled.div`
width: 100%;
min-height: 100vh;
margin-top: ${NAVBAR_HEIGHT};
margin-bottom:${NAVBAR_HEIGHT};
`