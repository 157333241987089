//POST, GET, PUT, and DELETE

//#############
//###CART######
//#############
export const CART_ADD_ITEM = 'POST_CART_ITEM';
export const CART_REMOVE_ITEM = 'DELETE_CART_ITEM';
export const CART_TOGGLE_ITEM = 'TOGGLE_CART_ITEM';
export const CART_REPLACE_ITEMS = 'PUT_CART_ITEMS';


//#############
//###SIGNUP####
//#############
export const SIGNUP_UPDATE_ITEMS = 'UPDATE_SIGNUP_ITEMS';


//#############
//###SEARCH####
//#############
export class SearchActionTypes {
static SEARCH_UPDATE_CRITERIA = 'SEARCH_UPDATE';
static SEARCH_UPDATE_TEMP_CRITERIA = 'SEARCH_UPDATE_TEMP';
static SEARCH_RESET_CRITERIA = 'SEARCH_RESET_CRITERIA';

static SEARCH_SET_LOCATION = 'SEARCH_UPDATE_LOCATION';
static SEARCH_SET_DATE = 'SEARCH_UPDATE_DATE';
static SEARCH_SET_TIME = 'SEARCH_UPDATE_TIME';
static SEARCH_SET_DURATION = 'SEARCH_UPDATE_DURATION';

static SEARCH_SET_SEARCH_TYPE = 'SEARCH_SET_SEARCH_TYPE';
static SEARCH_SET_AI_USER_PROMPT = 'SEARCH_SET_AI_USER_PROMPT';
static SEARCH_SET_AI_RESPONSE = 'SEARCH_SET_AI_RESPONSE';
static SEARCH_SET_AI_RECOMMENDATIONS = 'SEARCH_SET_AI_RECOMMENDATIONS';


static SEARCH_UPDATE_RESULTS = "SEARCH_UPDATE_RESULTS";
}

//#############
//###AUTH####
//#############
export const AUTH_LOGIN_GUEST = "AUTH_LOGIN_GUEST"
export const AUTH_LOGIN_USER = "AUTH_LOGIN_USER"
export const AUTH_LOGOUT= "AUTH_LOGOUT"


//#############
//###USER####
//#############
export const USER_UPDATE_ITEMS = "USER_UPDATE_ITEMS"
export const USER_SAVE_NEW = "USER_REPLACE"

//#############
//###FILTERS####
//#############
export const FILTER_BY_CATEGORY = 'FILTER_UPDATE_CATEGORY'
export const FILTER_RESET = 'FILTER_RESET'
