//Hemlet
import { Helmet } from 'react-helmet-async';

//CONSTANTS
import { NAVBAR_HEIGHT } from "components/constants";

//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//SVG
import { ReactComponent as GoghNowLogo } from 'assets/cta-logo-one.svg';
import { ReactComponent as GoghNowLogoSM } from 'assets/cta-logo-oneSM.svg';

//Navbar
import NavBarAccount from 'apps/User/NavBarComponents/NavBarAccount';

// //AppNav w cart shit
import { AppNavBarManagerProvider } from "context/AppNavBarContext";

//Sceen
import { useScreenContext } from 'context/AppContext/ScreenContext';

//React
import {useState } from "react";
import { useNavigate, Link } from 'react-router-dom';


import BreadcrumbNavigation from '../Components/BreadcrumbNavigation';
//ACCOUNT MENU
// import AccountMenu from './AccountSettingsPageComponents';

//Icons
// import profileIcon from 'assets/profile-icon.svg';
// import accountIcon from 'assets/account-icon.svg';

//Profile Edit Items
import EditAccountItem from './Components/EditAccountItem';

//UPDATE ITEMS
import UpdateLegalName from './Components/UpdateLegalName';
import UpdatePreferredName from './Components/UpdatePreferredName';
import UpdateEmail from './Components/UpdateEmail';
import UpdatePhone from './Components/UpdatePhone';

//CONSTANTS
import { HorizontalLine } from 'apps/User/UserAppConstants';



const AccountEdit = ({...props}) => {


    //Navigation
    const navigate = useNavigate();

    const {isMobile, isMedium, isLarge} = useScreenContext()

    const [activeItem, setActiveItem] = useState(null);

    const handleAccountItemBeingEdited = (item) =>{
        setActiveItem(item)
    }

    const BREADCRUMBS = [
        { name: "account settings", link: "/account-settings" },
        { name: "account", link: "/account-setting/account" },
      ]

    return (
        <>
            <Helmet>
                <title>GoghNow the App: Explore Event Services Near You | GoghNow</title>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="GoghNow | Account Settings | Profil" />
                <meta name="description" content="GoghNow view, update, and modify your profile" />
                <meta property="og:image" content="images/cta-logo-one.png" />
            </Helmet>
            <AppNavBarManagerProvider>
            <SpecialPageContainer>
                <SpecialNavBarLogin/>
                <SpecialPageContentContainer>
                    <Container $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        {(isLarge || isMedium) && (
                            <BreadcrumbNavigation breadcrumbs={BREADCRUMBS}/>
                        )}
                        <Heading>Account</Heading>
                        <FlexContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <FlexContentContainerLeft>
                                <EditableContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <SpecialEditAccountItem
                                    Property={'Legal Name'}
                                    Value={"Ajalon Elliott"}
                                    propertyBeingEdited={handleAccountItemBeingEdited}
                                    $isActive ={activeItem === 'Legal Name'}
                                    onEditComplete={() => console.log("Legal Name Editing completed.")}>
                                        <UpdateLegalName/>
                                    </SpecialEditAccountItem>

                                    <SpecialEditAccountItem
                                    Property={'Preferred Name'}
                                    Value={"Not Provided"}
                                    propertyBeingEdited={handleAccountItemBeingEdited}
                                    $isActive ={activeItem === 'Preferred Name'}
                                    onEditComplete={() => console.log("Preferred Name Editing completed.")}>
                                       <UpdatePreferredName/>
                                    </SpecialEditAccountItem>

                                    <SpecialEditAccountItem
                                    Property={'Email Address'}
                                    Value={"ajalonde@gmail.com"}
                                    propertyBeingEdited={handleAccountItemBeingEdited}
                                    $isActive ={activeItem === 'Email Address'}
                                    onEditComplete={() => console.log("Email Editing completed.")}>
                                        <UpdateEmail/>
                                    </SpecialEditAccountItem>

                                    <SpecialEditAccountItem
                                    Property={'Phone Number'}
                                    Value={"5408198922"}
                                    propertyBeingEdited={handleAccountItemBeingEdited}
                                    $isActive ={activeItem === 'Phone Number'}
                                    onEditComplete={() => console.log("Phone Editing completed.")}>
                                        <UpdatePhone/>
                                    </SpecialEditAccountItem>

                                    {activeItem && <Overlay $isActive={activeItem !== null}/>} {/* Optionally add an overlay when item is active */}
                                </EditableContentContainer>
                            </FlexContentContainerLeft>
                            <FlexContentContainerRight $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                <InnerFlexContentContainerRight $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <InnerFlexIconContainer>
                                        <img src="/images/apps/user/lock1.png"></img>
                                    </InnerFlexIconContainer>
                                    <InnerFlexHeader>We dont share your information</InnerFlexHeader>
                                    <InnerFlexBody>GoghNow doesn't share your information with any third party companies. We keep your information internal and use it to contact you</InnerFlexBody>
                                    <HorizontalLine></HorizontalLine>

                                </InnerFlexContentContainerRight>
                            </FlexContentContainerRight>
                        </FlexContentContainer>
                    </Container>
                </SpecialPageContentContainer>
            </SpecialPageContainer>
            </AppNavBarManagerProvider>

        </>
    )
}

export default AccountEdit

const SpecialEditAccountItem = styled(EditAccountItem)`
  position: relative;
  z-index:${props => {
    if (props.$isActive) return '9999999999999999';
    return '1';
  }};
  transition: z-index 0.3s ease-in-out;
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75); /* Adjust opacity as needed */
  z-index: 10; /* Slightly lower than the active EditAccountItem */
//   pointer-events: ${({ isActive }) => (isActive ? "auto" : "none")};
pointer_events:${props => {
    if (props.$isActive) return 'auto';
    return 'none';
  }};
`;


const SpecialPageContainer = styled.div`
display: flex;
flex-direction: column;
height:100dvh;
width: 100dvw;
overflow:hidden;
background-color: white;
`

const SpecialNavBarLogin = styled(NavBarAccount)`
    // position:fixed;
    background-color: transparent !important;
    // top:0px;
`

const SpecialPageContentContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
flex-grow: 1;
flex-shrink: 1;
overflow-y: scroll;
overflow-x: hidden;
align-items: center;
width: 100dvw;
`

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content:center;
max-width: 1080px;
width:100%;
padding: ${props => {
    if (props.$isMobile) return '5px 5px';
    if (props.$isMedium) return '15px 15px';
    if (props.$isLarge) return '25px 25px';
    return '25px 25px'; // Default to large if none match
  }};
  margin-left: auto;
  margin-right: auto;
`

const Heading = styled.h1`
color: black;
margin-bottom: 15px;
`


const FlexContentContainer = styled.div`
padding-top: 15px;
display: flex;
min-height: 75vh;
`

const FlexContentContainerLeft = styled.div`
display: flex;
flex-grow:1;
`

const EditableContentContainer = styled.div`
position: relative;
display:flex;
flex-direction:column;
width: 100%;
// background-color: #CCC;
min-height:100%;
`

const FlexContentContainerRight = styled.div`
display:${props => {
    if (props.$isMobile) return 'none';
    return 'flex'; // Default to large if none match
  }};
width: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return 'calc(100% / 3 + 40px)';
    if (props.$isLarge) return 'calc(100% / 3)';
    return 'calc(100% / 3)'; // Default to large if none match
  }};
margin-left: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return '10px';
    if (props.$isLarge) return 'calc(100% / 3 / 8)';
    return 'calc(100% / 3 / 8)'; // Default to large if none match
  }};
  max-width: 100%;
background-color: transparent;
min-height:100%;
border: 1px solid #AAAAAA80;
border-radius: 15px;
overflow: hidden;
`

const InnerFlexContentContainerRight = styled.div`
// background-color: #EEEEEE80;
width: 100%;
height: 100%;
margin: ${props => {
    if (props.$isMobile) return '5px 5px';
    if (props.$isMedium) return '15px 15px';
    if (props.$isLarge) return '25px 25px';
    return '25px 25px'; // Default to large if none match
  }};
`

const InnerFlexHeader = styled.h3`
color: black;
margin-bottom: 10px;
margin-top: 10px;
`
const InnerFlexBody = styled.p`
color:black;
margin-top: 0px;
`
const InnerFlexIconContainer = styled.div`
display:flex;
width: 50%;
height:auto;
 display: block;
    img{
        width: 100%; /* Ensures image keeps its aspect ratio */
        height: 100%;
        padding:2.5px;
        object-position: center; /* Center the image */
           // object-fit: cover; /* Maintain aspect ratio */
}
`
// const HorizontalLine = styled.hr`
//   border: none;
//   border-top: 1px solid #AAAAAA40;
//   width: 100%;
//   margin-top: 35px;
//   margin-bottom: 35px;
// `;
