import React from 'react';
import styled from 'styled-components';

interface PhotoPreviewProps {
  file: File | null; // File to preview
  onAccept: () => void; // Handle the "Accept" action
  onCancel: () => void; // Handle the "Cancel" action
//   onReUpload: () => void; // Handle the "ReUpload" action
}

const onCancel = () => {
    onCancel()
}
const PhotoPreview: React.FC<PhotoPreviewProps> = ({
  file,
  onAccept,
  onCancel,
//   onReUpload,
}) => {
  if (!file) return null;

  return (
    <>
    {file && (
    <Overlay>
      <ContentContainer>
        <TopContainer><button onClick={onCancel}>x</button></TopContainer>
        <Header>Preview Your Photo</Header>
        <PictureContainer>
            <PreviewImage src={URL.createObjectURL(file)} alt="Preview" />
        </PictureContainer>
        <ButtonContainer>
          <Cancel onClick={onCancel}>Cancel</Cancel>
          <Button onClick={onAccept}>Accept</Button>
        </ButtonContainer>
      </ContentContainer>
    </Overlay>
    )}
    </>      
  );
};

export default PhotoPreview;

const Header = styled.h3`
    color: black;
`
const Overlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.6);
display: flex;
justify-content: center;
align-items: center;
z-index: 9999;
`;

const ContentContainer = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #fff;
padding: 20px;
border-radius: 15px;
text-align: center;
box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
max-width: 500px;
width: 90%;
gap: 25px;
`;

const TopContainer = styled.div`
display: flex;
width: 100%;
padding: 15px 15px;
position: absolute;
top: 0px;
left: 0px;
justify-content: end;

    button {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-weight: 600;
        font-size: 1.5em;
        border: 1px solid transparent;
        cursor: pointer;

        &:hover {
            background-color: #CCCCCC;
        }
    }
`

const PictureContainer = styled.div`
display: flex;
width: 300px;
aspect-ratio: 1 / 1;
overflow: hidden;
align-items: center;
justify-content: center; 
border-radius: 150px;
border: 3px solid #AAA;
`
const PreviewImage = styled.img`
    width: 100%; /* Ensure the image covers the full width */
    height: 100%; /* Ensure the image covers the full height */
    object-fit: cover; /* Maintain aspect ratio and fill the container */
    object-position: center; /* Center the image */
`;


const ButtonContainer = styled.div`
display: flex;
width: 100%;
justify-content:center;
`
const Cancel = styled.button`
margin: 10px;
padding: 10px 20px;
border: none;
border-radius: 4px;
cursor: pointer;
background-color: transparent;
color: #b02a3780;
font-size: 14px;
    &:hover{
    color: #B93D44;
    }
`
const Button = styled.button`
margin: 10px;
padding: 15px 60px;
border: none;
border-radius: 4px;
cursor: pointer;
background-color: #0056b3;
color: #fff;
font-size: 14px;

&:hover {
  background-color: #007bff;
}

`;