
import React, {useState} from 'react';

//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//Constants
import { NAVBAR_HEIGHT } from 'components/constants';

//ELEMENTS
import {buttonSubmitStyle} from 'components/Buttons/ButtonStyles'
import TextField from '@mui/material/TextField';
import { textFieldStyle } from "components/Inputs/InputStyles";

//useScreen
import { useScreenContext } from 'context/AppContext/ScreenContext';
import PopUpNotification from 'components/Popups/PopUpNotification';


interface UserData {
    email: string;
    access_code: string;
  }
  

  enum Screens {
    EnterEmail = 0,
    VerifyEmail = 1,
    Success = 2,
  }

  // Define the props interface
interface UpdateEmailProps {
    onEditComplete: () => void; // Adjust the type as needed if it accepts arguments
  }

  const UpdateEmail: React.FC<UpdateEmailProps> = ({ onEditComplete }) => {


    const [screen, setScreen] = useState(0)
    const [error, setError] = useState<string | null>(null);
    const handleClosePopup = () => {
      setError(null);  // Close the popup by clearing the error state
    };


    const [data, setData] = useState<UserData>({
      email: "",
      access_code: ""
    });
  
    const handleChange = (value: string, field: keyof UserData) => {
      setData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };
  

    const handlePrevious = () => {
        setScreen(prevScreen => prevScreen - 1);
        console.log('Previous', screen - 1);
    }

    const handleNext = async() => {

        try{
            let success = false
            if (screen ===Screens.EnterEmail) {
                //save to staging part and send get access code
                success = true
            }
            else if (screen ===Screens.VerifyEmail){
                //confirm the access code sent
                success=true
            }
            else if (screen === Screens.Success){

                //send message to show they have successfully created the account
            }
            //SOMETHING HERE THAT HANDLES TOGGLING CANCEL BUTTON AND ALSO SETTING SCREENS TO 0

            if(success){
                setScreen(prevScreen => prevScreen + 1);
            }

        }
        catch(error){
            console.log("ERROR WAS THROWN! ", error)
            
            //DISPLAY POPUP HERE
            setError(error as string)
        }
    }

    const handleSuccessfullyUpdated = () => {
        if (onEditComplete) onEditComplete(); // Notify the parent
        setScreen(0); // Reset to initial screen
        setError(null); // Clear any errors
      };

    const handleSubmit = () => {
      console.log("Updated Data:", data);
      // Call your API or perform the necessary action with the updated data
    };
  
    return (
    <>
      <Container>
        
        {screen === Screens.EnterEmail && (
        <>
            <ValueText>Use an address you’ll always have access to.</ValueText>
            <ContentContainer>
                <TextFieldCustom
                    id="email"
                    label="Email"
                    value={data.email}
                    onChange={(e) => handleChange(e.target.value, "email")}
                    placeholder="Email"
                    sx={textFieldStyle}
                />
            </ContentContainer>
            <SubmitButton onClick={handleNext}>Verify</SubmitButton>

        </>
        )}
        
        {screen === Screens.VerifyEmail && (
        <>
        <ValueText>Use the access code we sent to your email address to verify your email.</ValueText>
        <ContentContainer>
            <TextFieldCustom
            id="access_code"
            label="Access Code"
            value={data.access_code}
            onChange={(e) => handleChange(e.target.value, "access_code")}
            placeholder="Access Code"
            sx={textFieldStyle}
            />
        </ContentContainer>
        <SubmitButton onClick={handleNext}>Confirm Access Code</SubmitButton>
        </>
        )}
        
        {screen === Screens.Success && (
        <>
        <ValueText>You've successfully updated your email address!</ValueText>
        <ContentContainer>
        </ContentContainer>
        <SubmitButton onClick={handleSuccessfullyUpdated}>Close</SubmitButton>
        </>
        )}

      </Container>
      {error && (
        <PopUpNotification
        titleText="Error"
        descriptionText={error}
        buttonText="Close"
        buttonAction={handleClosePopup}  // Close popup on button click
        />
    )}
    </>

    );
  };

export default UpdateEmail

const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
min-height: ${NAVBAR_HEIGHT};
`
const ValueText = styled.p`
  color:#00000080;
`
const ContentContainer = styled.div`
display: flex;
width: 100%;
gap:15px;
`

const TextFieldCustom = styled(TextField)`
width: 100%;
flex: 1;
`

//END OF NAVBAR
const SubmitButton = styled.button`
${buttonSubmitStyle}
margin-top: 10px;
width: 100%;
margin-top: 20px;
`
