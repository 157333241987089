import { Z_INDEX_NOTIFICATIONS } from 'components/constants';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

// import config from 'config'

import GoogleMapsFunctions from 'utility/googleMapsFunctions';


//SHOULD BE LOCATION SHIT LATER
import SearchFunctions from 'utility/searchFunctions';
import { TextField } from '@mui/material';



const GoogleMapsAddressInputComponent = forwardRef(({
  // onSearchSubmit,
  onPlaceSelected,
  onFocus,
  onChange,
  onBlur,
  value,
  className,
  disableAutoComplete = false,
  placeholder = "Enter event address",
  id = 'search',
  required = false,
  key="google-maps-address-input",  // Ensure key stays constant unless you want to trigger remount
  ...props

},ref) => {


  //ADDED
  const autocompleteInitialized = useRef(false);

  //OR JUST EXPORT FROM THE CONTEXT
//loadGoogleMapsApi = useGoogleMapsAddressContext()
  //added
  const internalSearchInputRef = useRef(null);
  // Use external ref if provided, else fallback to internal ref
  useImperativeHandle(ref, () => internalSearchInputRef.current);


  const [searchAddress, setSearchAddress] = useState(value || '');

  const [isFocused, setIsFocused] = useState(false); //new state to track focus

  const clearButtonRef = useRef(null);

  useEffect(() => {

    const onChangeAddress = (autocomplete) => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.log("No details available for input: '" + place.name + "'");
        return;
      }
      const addressObject = GoogleMapsFunctions.extractAddress(place);
      const formattedAddress = SearchFunctions.getLine1FromPlace(addressObject) + SearchFunctions.getLine2FromPlace(addressObject);
      setSearchAddress(formattedAddress);
      if (onPlaceSelected) {
        onPlaceSelected(addressObject);
      }
    };


    // const initAutocomplete = () => {
    //   if (!internalSearchInputRef.current || disableAutoComplete) return;

    //   const autocomplete = new window.google.maps.places.Autocomplete(internalSearchInputRef.current);
    //   autocomplete.setFields(['address_component', 'geometry']);
    //   autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete));
    // };

    // GoogleMapsFunctions.loadGoogleMapsApi()
    //   .then(initAutocomplete)
    //   .catch(error => {
    //     console.error('Failed to load Google Maps API:', error);
    //   });
    if (autocompleteInitialized.current) return;  // Skip if already initialized

  const initAutocomplete = () => {
    if (!internalSearchInputRef.current || disableAutoComplete) return;

    const autocomplete = new window.google.maps.places.Autocomplete(internalSearchInputRef.current);
    autocomplete.setFields(['address_component', 'geometry']);
    autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete));
  };

  GoogleMapsFunctions.loadGoogleMapsApi()
    .then(initAutocomplete)
    .catch(error => {
      console.error('Failed to load Google Maps API:', error);
    });

  autocompleteInitialized.current = true;  // Mark as initialized


  }, [disableAutoComplete]);

  useEffect(() => {
    setSearchAddress(value);
  }, [value]);


  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchAddress(inputValue);
    console.log("input: ",inputValue)
    // GoogleMapsFunctions.fetchPredictions(inputValue) UNDO
    console.log("onChange: ",onChange)
    onChange && onChange(inputValue);
  };

  const handleClear = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    console.log("input cleared")
    setSearchAddress('');



    if (internalSearchInputRef.current) {
      internalSearchInputRef.current.focus();
    }
  // Call the onChange callback, passing the empty string since we're clearing the input
  if (onChange && typeof onChange === 'function') {
    onChange && onChange(' ');  // Pass an empty string to clear the input
  }
  };


  const handleFocus = (e) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(e); // Call external onFocus handler
    }
  };

  const handleBlur = (e) => {
      // If the blur event is not triggered by clicking on the clear button
      if (clearButtonRef.current && !clearButtonRef.current.contains(e.relatedTarget)) {
        setIsFocused(false);
      }
      if (onBlur) {
        onBlur(e);
      }
    };


  return (
    <>
      <GlobalStyles /> {/*Inject global styles*/}
      <Wrapper>
              <SearchInput
        className={`input-please-work ${className}`}
          ref={internalSearchInputRef}
          value={searchAddress || ''}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type="text"
          id={id}
          placeholder={placeholder}
          required={required}
          autoComplete={disableAutoComplete ? "off" : "on"}
          {...props}
        />
        {searchAddress && isFocused && (
          <ClearButton ref={clearButtonRef} onClick={handleClear}>×</ClearButton>
        )}
        </Wrapper>
    </>
  );
});

    const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    & fieldset {
      border: none; /* Removes border */
    }
    &:hover fieldset {
      border: none; /* Prevents border on hover */
    }
    &.Mui-focused fieldset {
      border: none; /* Prevents border when focused */
    }
  }

  input {
    outline: none !important; /* Ensures no outline */
    // padding:0px;
    // margin:0px;
    width: calc(100% - 25px);
  }
      input::placeholder {
    text-align: left; /* Ensure placeholder is aligned properly */
    opacity: 0.6;
  }
`;

    const ClearButton = styled.button`
    position: absolute;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    color: #CCC; /* Custom clear button color */
    cursor: pointer;
    width:25px;
    height:25px;
  
    &:focus {
      outline: none;
      background-color: #AAA;
    }
          &:hover {
      outline: none;
      background-color: #AAA;
      border-radius: 50%;
      color: white;
    }
  `;



  const GlobalStyles = createGlobalStyle`
  .pac-container {
    z-index: calc(${Z_INDEX_NOTIFICATIONS} + 3242342) !important;
    font-size: 16px; /* Adjust font size */
    background-color: white; /* Ensure it's visible */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
  }

  .pac-item {
    padding: 10px;
    cursor: pointer;
  }

  .pac-item:hover {
    background-color: #f1f1f1;
  }

  .pac-icon {
    display: none; /* Hide default icon */
  }
`;
  const GoogleMapsAddressInput = React.memo(GoogleMapsAddressInputComponent);


  export default GoogleMapsAddressInput;

    //ADDED , LIKELY DON"T NEED
    // const reverseGeocode = ({ latitude: lat, longitude: lng}) => {
    //     const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    //     searchInput.current.value = "Getting your location...";
    //     fetch(url)
    //         .then(response => response.json())
    //         .then(location => {
    //           const place = location.results[0];
    //           const _address = extractAddress(place);
    //           setSearchAddress(_address);
    //           searchInput.current.value = _address.plain();
    //         })
    //   }
    
      //ADDED, LIKELY DON"T NEED
    //   const findMyLocation = () => {
    //     if (navigator.geolocation) {
    //       navigator.geolocation.getCurrentPosition(position => {
    //         reverseGeocode(position.coords)
    //       })
    //     }
    //   }




      {/* <SearchInput
        className={`input-please-work ${className}`}
          ref={internalSearchInputRef}
          value={searchAddress || ''}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type="text"
          id={id}
          placeholder={placeholder}
          required={required}
          autoComplete={disableAutoComplete ? "off" : "on"}
          {...props}
        />
        {searchAddress && isFocused && (
          <ClearButton ref={clearButtonRef} onClick={handleClear}>×</ClearButton>
        )} */}
      {/* </Wrapper> */}



      // <CustomTextField
      // className={`${className}`}
      // inputRef={internalSearchInputRef}
      // value={searchAddress || ''}
      // onChange={handleInputChange}
      // onFocus={handleFocus}
      // onBlur={handleBlur}
      // id={id}
      // placeholder={placeholder}
      // required={required}
      // autoComplete={disableAutoComplete ? "off" : "on"}
      // fullWidth
      // variant="outlined"
      // InputProps={{
      //   endAdornment: searchAddress && isFocused && (
      //     <ClearButton ref={clearButtonRef} onClick={handleClear}>×</ClearButton>
      //   ),
      // }}
      // {...props}
      
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
`;


const SearchInput = styled.input`
    display: flex;
    flex: 1 1;
    height: 60px;
    border-radius: 20px;
    font-size: 18px;
    max-width: calc(100% - 25px);
    border: 1px solid transparent;
    // padding-left: 25px;
    // padding-right: 30px;
    // padding-left:-30px;
    // margin-right: 5px;
    // margin-left:-20px;
    outline:none;
    background-color: transparent;
    color: black;

    /* Ensure text doesn't overflow behind clear button */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    /* Keep cursor visible */
    caret-color: black;

 /* Placeholder styles */
  &::placeholder {
    text-align: left; /* Align placeholder text to the left */
    // color: red; /* Adjust color for better visibility */
  }


   input::-webkit-search-clear-button {
  -webkit-appearance: none;
  appearance: none;
  color: #CCC; /* Attempt to change the clear button color */
  }
    /* Firefox */
  input::-moz-search-clear-button {
    color: #CCC; /* Limited support */
  }
    `
