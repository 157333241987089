import React, { useState } from 'react';
import ReactDom from 'react-dom'

import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import { buttonSubmitStyle } from 'components/Buttons/ButtonStyles';

const PopUpNotification = ({ titleText, descriptionText, buttonText, buttonAction , zIndex=1000}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    if (buttonAction) {
      buttonAction();
    }
  };

  return ReactDom.createPortal (
    <>
    {isOpen &&(
      <OverlayWrapper $isVisible={isOpen} $zIndex={zIndex}>
          <PopUpContainer>
            <Title>{titleText}</Title>
            <Description>{descriptionText}</Description>
            <Button onClick={handleClose}>{buttonText || 'OK'}</Button>
          </PopUpContainer>
      </OverlayWrapper>
    )}
    </>,
    document.body
  );
};

export default PopUpNotification;


// Keyframes for the fade-in and slide-in animations
const fadeIn = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const slideIn = css`
  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;



const OverlayWrapper = styled.div`
  position: fixed !important;
  top: 0;
  left:0;
  width: 100vw;
  height: 100vh;
  background-color: #00000060;
  display: flex;
  z-index: ${props => (props.$zIndex)};
  justify-content: center;
  align-items: center;

  // Apply fade-in animation when visible
  animation: fadeIn 0.5s ease forwards;
  ${fadeIn}
`;

const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 600px;
  background-color: #fff;
  border-radius: 10px;   
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 35px 50px;
  gap:25px;
  
  // Apply slide-in animation from the bottom
  animation: slideIn 0.5s ease forwards;
  ${slideIn}
`;

const Title = styled.h2`
  display: flex;
  text-align: center;
  font-weight: bold;
  width: 100%;
  color: black;
  margin-bottom: 0px;
  justify-content: center;
`;
const Description = styled.p`
  display: flex;
  text-align: center;
  width: 100%;
  color: black;
  margin-top:0px;
`;

const Button = styled.button`
${buttonSubmitStyle}
padding:10px 50px;
border-radius: 25px;
`