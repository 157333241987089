import {useState } from "react";

//CONSTANTS
import { NAVBAR_HEIGHT } from "components/constants";

//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

import { useScreenContext } from "context/AppContext/ScreenContext";
// import { ObjectRequestItem } from "model/RequestObjects/objectRequestItem";
import { objectUserRequest } from "model/RequestObjects/objectUserRequest";

//toggle Panel
import TogglePanel from 'utility/togglePanel';

  interface SceenSizes {
    $isMobile: boolean;
    $isMedium: boolean;
    $isLarge: boolean;
  }
  interface DisplayBookingsProps {
    data: objectUserRequest[];
  }




  const sortItemsByDate = (items: objectUserRequest[]) => {
    const now = new Date();
  
    // Filter for ongoing events (events that are currently happening)
    const nowItems = items.filter(item => {
      const start = item.start ? new Date(item.start) : null;
      const end = item.end ? new Date(item.end) : null;
      
      return start && end && start <= now && end >= now; // Events happening between start and end dates
    });
  
    // Filter for upcoming events (events that will start in the future)
    const upcomingItems = items.filter(item => {
      const start = item.start ? new Date(item.start) : null;
      return start && start > now; // Events starting in the future
    });
  
    // Filter for past events (events that have already ended)
    const pastItems = items.filter(item => {
      const end = item.end ? new Date(item.end) : null;
      return end && end < now; // Events that have already ended
    });
  
    return { nowItems, upcomingItems, pastItems };
  };



//ACTUAL ELEMENT
const DisplayBookings: React.FC<DisplayBookingsProps> = ({data, ...props}) => {


    const {isMobile, isMedium, isLarge} = useScreenContext()

   
    // Sort the items
    const { nowItems, upcomingItems, pastItems } = sortItemsByDate(data);

    let toggleOptions = ['All','Now','Upcoming','Past']
    const [selectedTab, setSelectedTab] = useState('All');

    const handleToggle = (selectedTab:string) => {
        console.log("Selected Tab:", selectedTab); // For debugging
        setSelectedTab(selectedTab)
      };






    const SectionTitle = styled.h2`
    font-size: 1.5em;
    margin-bottom: 10px;
`;

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    // gap: 15px;
`;
const OuterContainerContent = styled.div`
display: flex;
flex-direction: column;
width: 100%;
min-height: 50vh;
gap: 15px;
`
const Container = styled.div<SceenSizes>`
    display: grid;
    width: 100%;
    padding: 10px;
    grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(1, 1fr);';
    if (props.$isMedium) return 'repeat(2, 1fr);';
    if (props.$isLarge) return 'repeat(3, 1fr);';
    return 'repeat(3, 1fr);'; // Default to large if none match
  }};

    gap: 15px;
`;
    const MenuItem = styled(DisplayBookingsItem)`
    
    `
    const Separator = styled.div`
    height: 1px;
    background: #e0e0e0;
    margin: 10px 0;
  `;
  return (
    <OuterContainer>
      <OuterContainerContent>
         <TogglePanel
            options={toggleOptions} 
            activeTab={selectedTab} // Pass selectedTab as activeTab
            onToggle={(tab : string) => handleToggle(tab)}  />
    {/* NOW Section */}
      {(selectedTab === 'All' || selectedTab === 'Now') && nowItems.length > 0 && (
        <div>
          <SectionTitle>Now</SectionTitle>
          <Container $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
            {upcomingItems.map((item, index) => (
              <DisplayBookingsItem 
              key={index}
              data={item}
              {...item} />
            ))}
          </Container>
          <Separator />
        </div>
      )}

      {/* Upcoming Section */}
      {(selectedTab === 'All' || selectedTab === 'Upcoming') &&  upcomingItems.length > 0 && (
        <div>
          <SectionTitle>Upcoming</SectionTitle>
          <Container $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
            {upcomingItems.map((item, index) => (
              <DisplayBookingsItem 
              key={index}
              data={item}
              {...item} />
            ))}
          </Container>
          <Separator />
        </div>
      )}

      {/* Past Section */}
      {(selectedTab === 'All' || selectedTab === 'Past') &&  pastItems.length > 0 && (
        <div>
          <SectionTitle>Past</SectionTitle>
          <Container $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
            {pastItems.map((item, index) => (
              <DisplayBookingsItem
                key={index}
                data={item}
                {...item}
                />
            ))}
          </Container>
        </div>
      )}
      </OuterContainerContent>
    </OuterContainer>
  );
};


export default DisplayBookings




interface BookingItem {
    data: objectUserRequest;
  }

const DisplayBookingsItem: React.FC<BookingItem> = ({data}) => {

    async function fetchSvgContent(url: string) {
        const response = await fetch(url);
        return await response.text();
      }

    //   const {  } = data;

// console.log("imgURL: ",imageUrl)
    return (
        <>
        <Container>
            <InnerContainer>
                <IconContainer>
                    {/* <img src={imageUrl} alt="icon" /> */}
                </IconContainer>
                <ItemTitle>{'TITLE'}</ItemTitle>
                <ItemDescription>{'loremplaum epsum bullshit'}</ItemDescription>
            </InnerContainer>
        </Container>
        </>
    )
}




const Container = styled.div`
display: flex;
padding-left: 8px;
padding-right: 8px;
flex-direction: column;
width: 100%;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
cursor: pointer;

&:sesshover{
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.55);

}
`

const InnerContainer = styled.div`
display: flex;
flex-direction: column;
align-items:start;
padding: 25px 10px;
width: 100%;
height: 100%;
`

const IconContainer = styled.div`
display: flex;
align-items: center;
justify-content: center
height: 50px;
width: 50px;
overflow: hidden;
    img{
        width:100%;
        height:100%;
        display: block;
            &:hover {
        transition: transform 0.65s ease, padding 0.65s ease; /* Smooth animation for transform and padding */
        transform-origin: center center; /* Scale relative to the center */
            transform: scale(0.975); /* Shrink the image by 10% */
        }
    }
        svg{
        width:100%;
        height:100%;
        display: block;
            &:hover {
        transition: transform 0.65s ease, padding 0.65s ease; /* Smooth animation for transform and padding */
        transform-origin: center center; /* Scale relative to the center */
            transform: scale(0.975); /* Shrink the image by 10% */
        }
    }
    // svg{
`

const ItemTitle = styled.p`
font-weight: bold;
color: black;
`
const ItemDescription = styled.p`
color: #00000060;
padding: 0px;
margin:0px;
`