import React, {useEffect } from 'react';
import styled from 'styled-components';
import { NAVBAR_HEIGHT } from "components/constants";
import CartMenuItem from "./CartMenuItem";
import { ActionButton } from 'components/standard';
import { useScreenContext } from 'context/AppContext/ScreenContext';
import Overlay from 'components/Containers/Overlay';
// import { useNavigate, Link } from 'react-router-dom';
//===================
//REDUCER STUFF
//REDUCER STUFF
// import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleCartItem, addCartItem, deleteCartItem } from 'third_party/redux/cart/cartActions';
import { useCart } from "third_party/redux/selectors";
import SearchFunctions from 'utility/searchFunctions';



//ELEMENT
const CartMenu = ({ isOpen, onClose, cartItems, removeFromCart, handleCheckout, canToggle = true, ...props}) => {

    const {isMobile, isMedium, isLarge} = useScreenContext()

    //cartItems is already being passed
    // const cartItems = useCart()//useSelector((state) => state.cart);
    const countCartItems = SearchFunctions.countCartItems(cartItems);

    const displayItems = cartItems && countCartItems > 0 ? cartItems : [];

    const closeView = () => {
        isOpen = 
        onClose()
    }

    useEffect(() => {
        // Only run the effect if canToggle is true
        if (canToggle) {
          if (isOpen) {
            document.body.style.overflow = 'hidden';
          } else {
            document.body.style.overflow = 'auto';
          }
        }
      
        // Cleanup function to remove the style when the component unmounts
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [canToggle, isOpen]); // Dependency array includes both canToggle and isOpen

      return (

        <>
        {canToggle && <Overlay isVisible={isOpen} onClose={onClose}/>}

        <CartPanelContainer {...props}  $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} $isOpen={isOpen} $canToggle={canToggle}>
            <InnerContainer>
                <HeaderPanel>
                {canToggle && (
                    <button onClick={onClose}>x</button>
                )}
                    <HeaderPanelText>
                        <PanelTitle>GoghNow Services</PanelTitle>
                        <PanelDescription>your event services cart</PanelDescription>
                    </HeaderPanelText>
                    <HeaderPropertyContainer>
                      <HeaderPropertyServiceContainer><p>services</p></HeaderPropertyServiceContainer>
                      <HeaderPropertyPriceContainer><p>price</p></HeaderPropertyPriceContainer>
                    </HeaderPropertyContainer>

                </HeaderPanel>
        
                <BodyPanel>
                    
                    <CartMenuItemsContainer>
                        {displayItems.map((item, index) => (
                        <CartMenuItem key={index} item={item} cartItems={cartItems} removeFromCart={removeFromCart} />
                        ))}
                    </CartMenuItemsContainer>
                </BodyPanel>
                <FooterPanel>
                    <CheckOutButton onClick={() => handleCheckout()} >Checkout ({countCartItems})</CheckOutButton>
                </FooterPanel>
            </InnerContainer>
        </CartPanelContainer>
        </>
  );
};

export default CartMenu;


const CartPanelContainer = styled.div`


--width: ${props => {
    if (props.$isMobile) return '95vw';
    if (props.$isMedium) return '66vw';
    if (props.$isLarge) return '33vw';
    return '33vw'; // Default to large if none match
  }};

  --onScreenPosition: calc(100vw - var(--width));

display: flex;
top:0px;
left:0px;
position: ${props => (props.$canToggle ? 'fixed' : 'relative')};
flex-direction: column;
height: 100%;
width: var(--width);
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
background-color: white;
padding-top: 0px;
// overflow-y: auto;
transition: transform 0.3s ease-in-out;
transform: ${props => (props.$canToggle ? (props.$isOpen ? 'translateX(var(--onScreenPosition))' : `translateX(100vw)`) : 'translateX(0)')};
//transform: ${(props) => (props.$isOpen ? 'translateX(100vw)' : 'translateX(onScreenPosition)')};  // Update the transform property
//transition: transform 0.3s ease-in-out;
z-index: 5789790;


`

const InnerContainer = styled.div`
position:relative;
display:flex;
flex-direction:column;
padding: 15px;
gap:15px;
overflow-y:auto;
width: 100%;
height:100%;
background-color: #EEEEEE60;

`
const HeaderPanel = styled.div`
display: flex;
flex-direction:column;
width: 100%;
flex-grow:0;
min-height: ${NAVBAR_HEIGHT};
justify-content: center;
align-items: start;
// padding: 15px 0px;
gap: 15px;
button {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-weight: 600;
    font-size: 1.5em;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
        background-color: #CCCCCC;
    }
}
`
const HeaderPanelText = styled.div`
display: flex;
flex-direction:column;
flex-grow:1;
`
const PanelTitle = styled.h1`
    color: black;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1.5em;
`
const PanelDescription = styled.p`
    color: #00000090;
    margin-top: 0px;
    margin-bottom: 0px;
`

//HEADER PROPERTIES
const HeaderPropertyContainer = styled.div`
display:flex;
flex-direction: row;
min-height: ${NAVBAR_HEIGHT};
border-bottom: 2px solid #AAAAAA80;
width:100%;
align-items: end;
padding-bottom:5px;
`
const HeaderPropertyServiceContainer = styled.div`
display: flex;
flex-grow: 1;
justify-content: start;

p{
  color: #AAAAAA80;
  margin:0px;
}
`

const HeaderPropertyPriceContainer = styled.div`
display: flex;
width: 75px;//width of button in CartMenuItem
flex-grow: 0;
flex-shrink:0;
justify-content: start;
p{
  color: #AAAAAA80;
  margin:0px;
}
`

const BodyPanel = styled.div`
display: flex;
flex-direction: column;
height: auto;
width: 100%;
overflow-y: scroll;
flex-grow:1;
gap: 0px;

  /* Hide scrollbar in WebKit browsers (Chrome, Safari, etc.) */
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Optional: to ensure the background is still clear */
  }

  /* For Firefox */
  scrollbar-width: none; /* hides scrollbar */
  -ms-overflow-style: none;  /* hides scrollbar in IE and Edge */
`

const CartMenuItemsContainer = styled.div`
display: flex;
padding: 25px 0px;
width: 100%;
flex-wrap: wrap;
gap: 0px;

button {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.5em;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
        background-color: #CCCCCC;
    }
}
`

const FooterPanel = styled.div`
bottom:0px;
display: flex;
width:100%;
justify-content: center;
align-items: center;
height: ${NAVBAR_HEIGHT};
flex-grow:0;
padding:15px 0px;
// background-color: #CCC;
`

const CheckOutButton = styled(ActionButton)`
width: 80% !important;
max-width: 100% !important;
padding: 0px !important;
margin: 0px !important;
justify-content: center;
`