import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';

import { NAVBAR_HEIGHT } from 'components/constants';
import { useScreenContext } from 'context/AppContext/ScreenContext';
import ProfileMenuButton from "apps/Business/Menus/ProfileMenuButton";


const NavBarLoginSM = (props) => {

     //navigation
     const navigate = useNavigate();

     //screen
    const {isMobile, isMedium, isLarge} = useScreenContext()


    const handleToggleProfileMenu = () => {
        console.log("handle toggle of profile menu")
    }

    const navigateHome = () => {
        navigate('/Business/app');
      }

    return (
        <>
   {/* LEFT */}
        <ContainerLeft $isMedium={isMedium} $isLarge={isLarge}>
            <LogoContainer onClick={navigateHome}>
                <LogoIMG src='/images/cta-logo-oneSM.svg' alt="GoghNow" />
            </LogoContainer>
        </ContainerLeft>

    {/* MIDDLE */}
        <ContainerCenter>

        </ContainerCenter>

    {/* RIGHT */}
        <ContainerRight $isMedium={isMedium} $isLarge={isLarge}>
            <ProfileMenuButton onClick={handleToggleProfileMenu} />
        </ContainerRight>
        </>
    )
}
export default NavBarLoginSM

const ContainerLeft = styled.div`
    display: flex;
    flex-direction: row;
    min-height: ${NAVBAR_HEIGHT};
    // background-color: #CCCCCC40;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: ${props => {
        if (props.$isMedium) return '0px';
        if (props.$isLarge) return '25%';
        return '25%'; // Default to large if none match
      }};
    // padding: 0px 15px;
    align-items: center;
    justify-content: start;

    `

    const LogoIMG = styled.img`
                       
        display: block;
        max-height: 100%;
        max-width: 150px;
        width: auto; /* Ensures image keeps its aspect ratio */
        height: auto;
        object-fit: contain; /* Ensures the image fits within the container without stretching */
        max-height: 50px;
        
    `
        const LogoContainer = styled.div`
        display: flex;
        flex-shrink: 1;
        background-color: transparent;
        width: auto;
        height: 50px;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
        `

    const ContainerCenter = styled.div`
    display: flex;
    flex-direction: row;
    min-height: ${NAVBAR_HEIGHT};
    // background-color: #CCCCCC40;
    flex-grow: 1;
    flex-shrink: 0;
    min-width: 50%;
    padding: 0px 15px;
    align-items: center;
    justify-content: center;

    `

    const ContainerRight = styled.div`
    display: flex;
    flex-direction: row;
    min-height: ${NAVBAR_HEIGHT};
    // background-color: #CCCCCC40;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: ${props => {
    if (props.$isMedium) return '0px';
    if (props.$isLarge) return '25%';
    return 'auto'; // Default to large if none match
    }};
    // padding: 0px 15px;
    align-items: center;
    justify-content: end;

    `