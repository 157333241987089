//Constants.js

//emotions
import styled from '@emotion/styled'
import { cx, keyframes } from '@emotion/css';
import { css } from '@emotion/react';



//CSS STYLED
export const ContainerGeneral = css`
display: flex;
flex-direction: column;
width: 100%;
// background-color: black;
min-height: 50vh;
`
export const InnerContainer = css`
display: flex;
width: 100%;
margin: 25px;
`
//ELEMENTS
export const HorizontalLine = styled.hr`
  border: none;
  border-top: 1px solid #AAAAAA40;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`;


export const COLORS = {
    primary: "#AAAAAA40",
    secondary: "#FF5733",
    accent: "#4CAF50",
    background: "#F5F5F5",
    text: "#333333",
  };

  export const BORDERS = {
    thinDashed: "1px dashed #AAAAAA",
    thickSolid: "3px solid #FF5733",
    lightBorder: "1px solid #AAAAAA40",
    elementBorder: "1px solid #AAAAAA80"
  };