//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//React
import React, {useState } from "react";


const EditAccountItem = ({Property = '', Value, Description, children, onEditComplete, propertyBeingEdited, ...props}) => {

//isEditing
const [isEditing, setIsEditing] = useState(false)
const editText = isEditing ? 'Cancel' : 'Edit';

//If Value and Description are unequal to nil then Value Color is black and Description color is #CCC...else, the color is #CCC
const valueAndDescriptionNotNil = Value && Description;
 
// const toggleEdit = () => {
//     setIsEditing((prev) => !prev); //SIMPLE
// }
const toggleEdit = () => {
    setIsEditing((prev) => {
      const newEditingState = !prev;
        
      //If setActiveItem is set
      if (newEditingState===true) {
        propertyBeingEdited(Property); // Set the active item to the current Property
      } else {
        propertyBeingEdited(null)
      }
  
      return newEditingState; // Return the new editing state
    });
  };
const handleEditComplete = () => {
    setIsEditing(false); // Reset `isEditing` to `false`
    if (onEditComplete) onEditComplete(); // Optionally notify the parent or log if needed
  };

    return (
            <Container {...props}>
                <ContentFlexContainer>
                    {Property&&<PropertyText>{Property}</PropertyText>}
                    {!isEditing ? (
                    <>
                        {Value && (
                        <ValueText isNonNil={valueAndDescriptionNotNil}>{Value}</ValueText>
                        )}
                        {Description && (
                        <DescriptionText isNonNil={valueAndDescriptionNotNil}>
                            {Description}
                        </DescriptionText>
                        )}
                    </>
                    ) : (
                    <>
                        {/* Add your alternative content for the `isEditing` state here */}
                        <ChildContentContainer>
                            {/* {children} */}
                            {React.cloneElement(children, { onEditComplete: handleEditComplete })}
                        </ChildContentContainer>
                    </>
                    )}
                </ContentFlexContainer>

                <EditFlexContainer>
                    <EditText onClick={toggleEdit}>{editText}</EditText>
                </EditFlexContainer>
            </Container>
    )
}
export default EditAccountItem

const Container = styled.div`
display: flex;
position: relative;
width: 100%;
border-bottom: 1px solid #EBEBEB !important;
height: auto;
padding: 10px 0px;
background-color: white;
`

const ContentFlexContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
flex-grow:1;
`

const EditFlexContainer = styled.div`
display: flex;
flex-direction: column;
width: auto;
`

//LEFT SIDE OF FLEX
const PropertyText = styled.h4`
font-weigth: bold;
color: Black;
margin-bottom: 0px;
`
const ValueText = styled.p`
  color: ${(props) => (props.isNonNil ? "black" : "#00000080")};
`
const DescriptionText = styled.p`
  color: ${(props) => (props.isNonNil ? "black" : "#00000080")};
`

const ChildContentContainer = styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: column;
color: black;
`
//RIGHT SIDE OF FLEX
const EditText = styled.p`
font-weight: bold;
cursor: pointer;
text-decoration: underline;
color: black;
`


