import React, { createContext, useState, useContext } from "react";

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
    const [isScrolledFromTop, setIsScrolledFromTop] = useState(false);

    const handleScrollStateChangeFromTop = (value) => {
        setIsScrolledFromTop(value);
    };

    return (
        <ScrollContext.Provider value={{ isScrolledFromTop, handleScrollStateChangeFromTop, setIsScrolledFromTop }}>
            {children}
        </ScrollContext.Provider>
    );
};

export const useScroll = () => useContext(ScrollContext);
