//Hemlet
import { Helmet } from 'react-helmet-async';

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

//context
import {useState, useEffect, useRef } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useScreenContext } from 'context/AppContext/ScreenContext';
import { useBusinessServiceContext } from 'api/Business/BusinessService/BusinessServiceAPIContext';

//MOTION
import { motion, useScroll, useTransform } from 'framer-motion';


//JUST ADDED 
import NavBarLogout from "pages/home/header/NavBarLogout"

//Common Components
import {PageContentContainer} from "components/Page/Elements"
import FooterLoggedOut from 'components/Footer/FooterLoggedOut';
import { TextParallaxContent } from './components/BusinessTextComponents';
import { FlipLink } from 'components/LinkSelectionAnimation';

//Items
import { NAVBAR_HEIGHT } from "components/constants";
import { CALLTOACTION, SECTION, STANDARDBUTTON } from "components/standard";
import HoverImage from "components/HoverImage/HoverImage";

import {mobileDisplayContentVertical,mobileDisplayContentHorizontal, mobileSizeDisplayNone, mobileSizeDisplayBlock, mobileSizeDisplayFlex} from 'components/emotionStyles'

import PopUpInfo from 'components/HoverImage/PopUpInfo';
import FAQManager from "components/FAQComponents/FAQManager";

//SIGNUP
import BusinessSignup from 'pages/business/signup/BusinessSignup'; //MIGHT BE PROBLEM

//IMPORT SLICK CAROUSEL
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//components
import CheckoutError from 'pages/book/checkoutcomponents/checkoutError';
import SignUpSuccess from 'pages/business/signup/SignUpSuccess'; //MIGHT BE PROBLEM

//SVG
import { ReactComponent as RocketIcon } from 'assets/rocket.svg';
import { ReactComponent as SaveTime } from 'assets/savetime.svg';
import { ReactComponent as MoneyIcon } from 'assets/provider/money.svg';
import { ReactComponent as FreeResources } from 'assets/freeresources.svg';


import { ScrollProvider } from 'pages/home/header/ScrollContext';


const Starter = {name:'Starter', price:'FREE', description:'A great way to give your clients access to GoghNow. Start earning for free.'};
const Pro = {name:'Pro (COMING SOON)', price:"$29.99", description:'Automated event booking solution. Ideal for businesses seeking an out-of-the-box solution to monetize their private spaces.'};
const Enterprise = {name:'Enterprise', price:"$99.99", description:'Custom automated event solution for enterprises. Designed for large businesses with complex processes, offering seamless integration and advanced features.'};



const trustby_images = [
    {alt:'CBRE',image: '/images/Business/logos/cbre.png'},
    {alt:'Greystar',image: '/images/Business/logos/greystar.png'},
    {alt:'Hoppin', image: '/images/Business/logos/hoppin.png'},
    {alt:'Crescent',image: '/images/Business/logos/crescent.png'},
    {alt:'Insperity',image: '/images/Business/logos/insperity.png'},
    {alt:'Kingsmen',image: '/images/Business/logos/kingsmen.png'},

]

const joinNow = [
  { src: 'images/Business/industries/restaurant.png', caption: 'Restaurants, Bars & Breweries' },
  { src: 'images/Business/industries/multi-family2.png', caption: 'Multi-Family Communities' },
];

const industryInfo = [
  {category:'Experience-Ready Spaces', description:`
    <b>Experience-Ready Spaces</b> are transforming the way businesses offer personalized experiences. Our technology enables easy access to <i>customizable event services</i> that allow clients to effortlessly enhance their spaces for gatherings, events, and experiences. Whether it's <b>private spaces</b> or <b>public areas</b>, businesses can now <i>curate unique experiences</i> on demand, optimizing both space usage and customer satisfaction. This creates an opportunity to increase revenue, enhance client engagement, and elevate the overall experience—making any space truly <i>experience-ready</i>.
  `},
  {category:'Restaurants, Bars & Breweries', description:`
      <b>Restaurants</b> are using our technology to <i>create event-ready spaces</i> for their clients, allowing them to easily <b>select event services</b> to personalize their bookings. Our platform also enables restaurants to <i>curate experiences in public spaces</i> with local event services, simplifying staff management. Plus, restaurants enjoy <i>kickbacks every time a client uses the space</i>.
    `},
  {category:'Multi-Family Communities', description: `
      <b>Multi-family complexes</b> give residents <i>instant access</i> to event services for personalizing gatherings in both <b>private</b> and <b>common spaces</b>. Management can use our platform to <i>create resident engagement</i> experiences and <i>seasonal events</i>, enhancing the community’s overall experience.
    `}
];



// const ThreeWayToggle = ({ options, onChange }) => {
//   const [value, setValue] = useState(options[0]);

//   const handleChange = (newValue) => {
//     setValue(newValue);
//     onChange(newValue);
//   };

//   return (
//     <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '5px', overflow: 'hidden' }}>
//       {options.map((option) => (
//         <button
//           key={option}
//           onClick={() => handleChange(option)}
//           style={{
//             flex: 1,
//             padding: '10px 15px',
//             border: 'none',
//             backgroundColor: value === option ? '#007bff' : '#fff',
//             color: value === option ? '#fff' : '#333',
//             cursor: 'pointer',
//             transition: 'background-color 0.3s ease',
//           }}
//         >
//           {option}
//         </button>
//       ))}
//     </div>
//   );
// };


const Business = (props) => {

  let options = ['Restaurants, Bars & Breweries', 'Multi-Family Communities']
  const [selectedOption, setSelectedOption] = useState('Restaurants, Bars & Breweries');

  const handleToggleChange = (newValue) => {
    setSelectedOption(newValue);
  };


  const settings = {
    dots: true, // Add navigation dots for paging
    infinite: true, // Enable continuous sliding
    slidesToShow: 1, // Number of images visible per slide (adjust as needed)
    slidesToScroll: 1, // Number of images to slide on user interaction
    responsive: [
      // Optional responsive settings for different screen sizes
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (current, next) => {
      // Set the highlighted provider item based on the next slide index
      handleProviderItemCarouselSelected(joinNow[next].caption);
    },
  };


const {isMobile, isMedium, isLarge} = useScreenContext()
const {serviceSignup} = useBusinessServiceContext()



// useEffect(() => {
//     const handleMouseMove = (e) => {
//       setMousePosition({ x: e.pageX, y: e.pageY });
//     };
  
//     // Attach event listener when highlightedItem changes
//     document.addEventListener('mousemove', handleMouseMove);
  
//     // Clean up event listener
//     return () => {
//       document.removeEventListener('mousemove', handleMouseMove);
//     };
//   }, [highlightedItem]); // Depend on highlightedItem to update mouse position when it changes
 

    
const servicesSectionRef = useRef(null);
const goToSignUpOfferings = () => {
    // console.log("CLICKED!!!")
    servicesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
}

    const itemIsHighlighted = useState(null)


    //KEEP
    const EventVenues = {
        x: 2048/4096,
        y: 750/4096,
        title: "Event Venues & Unique Spaces",
        description: "Become the premier venue for private events by offering a seamless, all-in-one booking experience.",
        ideal: ["Streamline Bookings","All-in-One Event Services","Expand Service Options","Increase Event Bookings","Earn from Partner Services"],
        image: "/images/Business/private.png"
    };

     //Keep
     const Multifamily = {
        x: 2048/4096,
        y: 750/4096,
        title: "Multifamily Communities",
        description: "Management can set up any style of event on-site, while residents enjoy easy access to diverse event services within private spaces.",
        ideal: ["Boost Revenue", "Engage Residents", "Access Event Services"],
        image: "/images/Business/apartment.jpeg"
    };

    //KEEP
    const Restaurants = {
        x: 2048/4096,
        y: 750/4096,
        title: "Bars, Breweries, & Restaurants",
        description: "Easily host themed events or offer clients a one-stop shop for private events and partner services—all without extra overhead.",
        ideal: ["Drive Private Events","Simplify Bookings","Keep Revenue In-House","One-Stop Event Hub","Increase Event Spend"],
        image: "/images/Business/restaurant.jpg"
    };

    const Commercial = {
        x: 2048/4096,
        y: 750/4096,
        title: "Commercial Properties",
        description: "Become the property that tenants flock to with effortless, unique event experiences.",
        ideal: ["Easy Event Setup", "Flexible Themed Spaces", "Maximize Event Revenue", "Easily Earn From Private Spaces"],
        image: "/images/Business/hotels.png"
    };

    const questionsAndAnswers = [
      { question: 'What if we already have a process for people to book spaces?', answer: "That's great! GoghNow for Business doesn't change how you book spaces—it simply makes it easier for clients to personalize their event once they've booked. With seamless access to vetted event services, they can enhance their experience effortlessly while you stay in control."},
      { question: 'Is GoghNow for Business just for large events?', answer: 'No, GoghNow for Business is designed for events of all sizes! Our technology makes it easy for clients planning smaller gatherings to quickly find the services they need at affordable prices.  For larger events, we offer a wide range of options to accommodate even the most complex requirements.' },
      { question: 'We already provide in-house event services, can we still use GoghNow for Business?', answer: "That's great!  We make it even easier for your clients to seamlessly book your in-house services directly through our platform.  You retain 100% of the revenue from those bookings."},
      { question: 'We already have preferred vendors, is this still useful?', answer: "Awesome! Our platform streamlines booking for your preferred vendors, handling all the logistics so your clients don't have to.  We'd also love to have your vendors join our platform for even easier collaboration!"},
      { question: 'We would love to add more event services without the overhead, would this help?', answer: "Absolutely! GoghNow for business lets you expand your service offerings instantly, without any added overhead. Your clients gain access to a wide network of local providers, and you earn revenue on every booking – it's a win-win."},
    ];
  
    // const questionsAndAnswers = [
    //     { question: 'Own an event space?', answer: 'We simplify the booking process, making it easy for your clients to book your spaces directly through you.' },
    //     { question: 'Do you provide your own event services?', answer: 'We make it easier for your clients to seamlessly book your services. You always get 100% of the dollars earned from those booked services.' },
    //     { question: 'Do you want to offer more event services?', answer: 'Your clients have direct access to our network of local providers to expand your offerings. Our service providers fulfill those requests while you earn a portion of the revenue — with no added effort or overhead.' },
    //     // Add more questions and answers as needed
    //   ];
    
    
    


     const carouselSettings = {
        dots: false, // Add navigation dots for paging
        infinite: true, // Enable continuous sliding
        slidesToShow: 4.75, // Number of images visible per slide (adjust as needed)
        slidesToScroll: 1, // Number of images to slide on user interaction
        responsive: [
          // Optional responsive settings for different screen sizes
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2.75,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.75,
              slidesToScroll: 1,
            },
          },
        ],
      };

      //VENU TYPE
      const [highlightedItem, setHighlightedItem] = useState(null);
    //   const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = (data) => {
        // console.log('handleMouseEnter: ',data)
        if (data.title !== highlightedItem?.title) {
          setHighlightedItem(data);
        }
      };
    
      const handleMouseLeave = () => {
        setHighlightedItem(null);
      };
    
      //THIS IS WHAT'S NEEDED FOR SIGNUP SHEET
      const [isSignupOpen, setSignupOpen] = useState(false);
      const [serviceType, setserviceType] = useState('');
      const formRef = useRef(null)
      const [invalidFields, setInvalidFields] = useState(new Set());
      const [submitting, setSubmitting] = useState(false);
      const [isErrorPopupVisible, setErrorPopupVisible] = useState(false);
      const [showSuccessPopup, setShowSuccessPopup] = useState(false);

      const [errorMessage, setErrorMessage] = useState('');
      const showErrorPopup = (message) => {
          setErrorMessage(message);
          setErrorPopupVisible(true);
          // console.log("setErrorMessage: ",message)
        };
      
        const closeErrorPopup = () => {
          setErrorPopupVisible(false);
          setErrorMessage('');
        };

        

      const handleOpenSignup = (type) => {
        setSignupOpen(true);
        setserviceType(type)
        // console.log(type)
      };
    
      const handleCloseSignup = () => {
        setShowSuccessPopup(false)
        setSignupOpen(false);
        //TODO: CLEAR FORM
      };

      const handleSuccessCloseSignup = () => {
        setShowSuccessPopup(false)
        setSignupOpen(false);
        //TODO: CLEAR FORM
        formRef.current.reset();
      };
    

      const handleSubmit = async (userData) => {
        // console.log("BUSINESS: SUBMIT")

        // event.preventDefault();
        const isFormValid = formRef.current.checkValidity();
        // console.log("isFormValid: ",isFormValid)
        if (isFormValid) {
            // console.log('Form is valid! Submitting...');
            setSubmitting(true); // Set submitting state to true
            try {
                await handleCreateRequest(userData);
            }catch (error) {
                setSubmitting(false); // Reset submitting state on error
                console.error('Error submitting form:', error);
            }
        } else {
        // Do something when the form is not valid
        const invalidFields = getInvalidFields(formRef);
        // console.log("invalid form fiels: ",invalidFields)
        showErrorPopup(`Invalid form fields: ${invalidFields.join(', ')}\nPlease check your inputs.`);
    }
    };

    const getInvalidFields = (formRef) => {
        const formElements = formRef.current.elements;
        const invalidFields = [];
    
        for (let i = 0; i < formElements.length; i++) {
            const field = formElements[i];
            if (!field.checkValidity()) {
                const fieldName = field.name || field.id;
                if (fieldName) {
                    invalidFields.push(fieldName);
                }
            }
        }
    
        return invalidFields;
    };
    const handleCreateRequest = async (data) => {
        try{
            const result = await serviceSignup(data);
            // console.log('Request successful:', result);
            setShowSuccessPopup(true);
        }
        catch (error){
            console.error('Request failed:', error);
            // Show popup or any other error handling logic
            showErrorPopup(error.message);
        } finally {
            setSubmitting(false)
        }
      };

      //==================================
      //==========SCROLL SHIT============
      const scrollToTop = () =>{
        // console.log("this is working?")
        // setTimeout(() => contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' }), 10);
      } 



    const scrollToOutOfTheBoxContentTop = () =>{
        // console.log("scrollToOutOfTheBoxContentTop")
        if (OutOfBoxRef.current) {
            OutOfBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
    }
    const scrollToManageSpacesContentTop = () =>{
        // console.log("scrollToBenefitsSection")
        
        if (OfferingTWORef.current) {
            OfferingTWORef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
    }
    const scrollToAmenitiesTop = () =>{
        // console.log("scrollToIndustrySection")
        if (OfferingTHREERef.current) {
            OfferingTHREERef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
    }

    const scrollToExperienceTop =() =>{
        // console.log("scrollToExperienceTop")
        if (IndustrySectionRef.current) {
          IndustrySectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
    } 

    //OFFERINGS SECTIONS
  

    //=====================================
    //=======MOUSE ENTER MOUSE LEAVE=======
    const [isSectionHovered, setIsSectionHovered] = useState(null);
 
    const handleSectionMouseLeave = () => {
        setIsSectionHovered(null);
    };

    const handleSectionMouseEnter = (item) => {
        setIsSectionHovered(item)
        // console.log('HERE: ',item)
    };
    const IndustrySectionRef = useRef(null);

    //=========================================
    //===============ANIMATION ITEMS===========
    //=========================================

          //XXXXXXXXXXXXXXXXXXXXXXXXXX
      //XXXXXXXXX HERO REFXXXXXXXX
      const HeroSectionRef = useRef(null);
      const { scrollYProgress : scrollYProgressQuickNavigationSection } = useScroll({
        target: HeroSectionRef,
        offset:["start start" , "end center"],
      })

    //   const scale = useTransform(scrollYProgressQuickNavigationSection, [0,1],[1,0.85])
      const opacityExperienceCTA = useTransform(scrollYProgressQuickNavigationSection, [0,1],[1,0])


 //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //XXXXXXXXX Benefit Section XXXXXXXX
      const BenefitsSectionRef = useRef(null);

    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //XXXXXXXXX Services Section XXXXXXXX
    const SectionDividerSectionRef = useRef(null);
    const { scrollYProgress : scrollYProgressSectionDividerSection } = useScroll({
      target: SectionDividerSectionRef,
      offset:["start start" , "end end"],
    })

    const opacitySectionDivider = useTransform(scrollYProgressSectionDividerSection, [0, 0.5, 1], [1, 0.0, 1])
    const opacitySectionDividerLightBulb = useTransform(scrollYProgressSectionDividerSection, [0.25, 0.5, 0.75], [0, 1, 0])

    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //XXXXXXXXX QuickNavContent XXXXXXXX
    const QuickNavContentRef = useRef(null);
    const { scrollYProgress : scrollYProgressQuickNavContentRefSection } = useScroll({
      target: QuickNavContentRef,
      offset:["start start" , "end start"],
    })

    //.25 - .75
    const opacityQuickNavContentONE = useTransform(scrollYProgressQuickNavContentRefSection, [0.25,0.35,0.45,0.55,0.60], [0.05,1,0.05,0.05,1]);
    const opacityQuickNavContentTWO = useTransform(scrollYProgressQuickNavContentRefSection, [0.35,0.45,0.55,0.60], [0.05,1, .05,1]);
    
    const opacityQuickNavContentTHREE = useTransform(scrollYProgressQuickNavContentRefSection, [0.45,0.55,0.60], [0.05,1,1]);
 
    // Derived boolean for conditional display
//     const shouldDisplayQuickNavContentONE =useTransform(scrollYProgressQuickNavContentRefSection,[0.25,0.35,0.45,0.55,0.75], ['flex','flex','flex','flex','none']);
//     const shouldDisplayQuickNavContentTWO =useTransform(scrollYProgressQuickNavContentRefSection, [0.35,0.45,0.55,0.75], ['flex','flex','flex','none']);

//  const shouldDisplayQuickNavContentTHREE =useTransform(scrollYProgressQuickNavContentRefSection, [0.45,0.55,0.75], ['flex','flex','none']);



         //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //XXXXXXXXX QuickNav Section XXXXXXXX
    const OfferingsSectionRef = useRef(null);
    const { scrollYProgress : scrollYProgressQuickNavRefSection } = useScroll({
      target: OfferingsSectionRef,
      offset:["start center" , "end end"],
    })

    const bottomOfferingsSectionMenu = useTransform(scrollYProgressQuickNavRefSection, [0,0.05,0.25,0.5, 0.75, 0.99,1], [-60,30,30, 30,30,30, -60]);




    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //XXXXXXXXX OutOfBox XXXXXXXX
    const OutOfBoxRef = useRef(null);
    const { scrollYProgress : scrollYProgressOfferingONE } = useScroll({
      target: OutOfBoxRef,
      offset:["start center" , "end center"],
    })
    const backgroundOfferingONE =useTransform(scrollYProgressOfferingONE, [0,.99,1], ['#222','#5F5F5F','#222']);


    const OfferingTWORef = useRef(null);
    const { scrollYProgress : scrollYProgressOfferingTWO } = useScroll({
      target: OfferingTWORef,
      offset:["start center" , "end center"],
    })
    const backgroundOfferingTWO =useTransform(scrollYProgressOfferingTWO, [0,.99,1], ['#222','#5F5F5F','#222']);


    const OfferingTHREERef = useRef(null);
    const { scrollYProgress : scrollYProgressOfferingTHREE } = useScroll({
      target: OfferingTHREERef,
      offset:["start center" , "end center"],
    })
    const backgroundOfferingTHREE =useTransform(scrollYProgressOfferingTHREE, [0,.99,1], ['#222','#5F5F5F','#222']);




//PROVIDER SHIT
const [highlightedProviderItem, setHighlightedProviderItem] = useState('Experience-Ready Spaces');
const [matchedIndustry, setMatchedIndustry] = useState(industryInfo[0]);

const [highlightedProviderCarouselItem, setHighlightedProviderCarouselItem] = useState(joinNow[0].caption);
const [carouselMatchedIndustry, setCarouselMatchedIndustry] = useState(industryInfo[1]);

const handleProviderItemSelected = (itemCaption) => {
  setHighlightedProviderItem(itemCaption)
  const foundIndustry = industryInfo.find(
    (industry) => industry.category === itemCaption
  );
  setMatchedIndustry(foundIndustry)
  // console.log("matched industry: ",foundIndustry)
}

const handleProviderItemUnSelected = () => {
  setHighlightedProviderItem(industryInfo[0].category)

  
  const foundIndustry = industryInfo.find(
    (industry) => industry.category === industryInfo[0].category
  );
  setMatchedIndustry(foundIndustry)
}


const handleProviderItemCarouselSelected = (itemCaption) => {
  setHighlightedProviderCarouselItem(itemCaption)
  const foundIndustry = industryInfo.find(
    (industry) => industry.category === itemCaption
  );
  setCarouselMatchedIndustry(foundIndustry)
  // console.log("matched industry: ",foundIndustry)
}

return (

    <>
{/* NAV BAR */}
    <Helmet>
        <title>Partner with GoghNow: Receive exclusive benefits with no hassle | GoghNow for Business</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="GoghNow | Become a partner today" />
        <meta name="description" content="GoghNow for Business is a modern platform for instant event bookings. Turn your space into a self-booking venue today!" />
        <meta property="og:image" content="images/cta-logo-one.png" />
    </Helmet>
    <ScrollProvider>
    <SpecialNavBarLogout handleNavigateHome={scrollToTop}/>

    <SpecialPageContentContainer>

    {/* imgUrl="/images/space2.jpeg" */}

        <HeroSection ref={HeroSectionRef} style={{opacity:opacityExperienceCTA}}>
            <TextParallaxContent
            imgUrl="/images/Business/restaurant.jpg"
            heading="The experience platform for event bookings"
            subheading="GoghNow for business enhances your online booking process into an experience that clients love"
            >
                <HeroFlexContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                    <HeroFlexItem1 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <CTA>
                            <HeroDescription><b>GOGHNOW FOR BUSINESS</b></HeroDescription>
                            <HeroText>Make Your Spaces <b><i>Experience-Ready</i></b></HeroText>
                            <HeroTextSubDescription><b>GoghNow for Business </b>gives you easy access to vetted local event services, allowing your clients to personalize their events/gatherings in minutes while you maintain control and keep more revenue in-house.</HeroTextSubDescription>
                            {/* <HeroTextSubDescription><b>GoghNow for Business </b>helps venues capture bookings for smaller-scale events with instant booking options, freeing up your time to focus on larger events while maximizing revenue.</HeroTextSubDescription> */}
                            <SignUpButton onClick={goToSignUpOfferings} >Sign up today!</SignUpButton>
                            {/* <ActionButton>Sign In</ActionButton> */}
                        </CTA>
                    </HeroFlexItem1>
                </HeroFlexContainer>
            </TextParallaxContent>
            <GoghExperienceCTA  style={{opacity: opacityExperienceCTA, display: opacityExperienceCTA <= 0.5 ? 'none' : 'flex' }} onClick={scrollToExperienceTop}>
                <GoghExperienceCTAHeader>Explore</GoghExperienceCTAHeader>
                <GoghExperienceCTAHeader>↓</GoghExperienceCTAHeader>
            </GoghExperienceCTA>
        </HeroSection>



        <GoghNowSectionJoinNow $backgroundColor={"#BBBBBB"} ref={IndustrySectionRef} >
                <GoghNowSectionJoinNowContainer>
                    <SectionHeader style={{ color: 'black' }}>Join our network of businesses with experience-ready spaces</SectionHeader>
                    {/* <SectionHeaderDescription style={{ color: '#00000060' }}>the more creative the better</SectionHeaderDescription> */}
                    {/* <ThreeWayToggle
                      options={options}
                      onChange={handleToggleChange}
                    />
                    <p>Selected: {selectedOption}</p> */}
                    {/* PREVIEW */}
                    <ProviderFlexContainerContent $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                      <ProviderFlexContainer style={{ backgroundColor: 'transparent' }}>
                        <ProviderPreviewContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            {joinNow.map((item, index) => (
                                <ProviderPreviewContainerItem key={index} $imgURL={item.src} $isHighlighted={highlightedProviderItem === item.caption} onMouseEnter={() => handleProviderItemSelected(item.caption)} onMouseLeave={() => handleProviderItemUnSelected()}>
                                    <ProviderPreviewContainerItemOverlay $isHighlighted={highlightedProviderItem} $caption={item.caption} ></ProviderPreviewContainerItemOverlay>
                                    <p dangerouslySetInnerHTML={{ __html: `${item.caption}` }}></p>
                                    </ProviderPreviewContainerItem>
                            ))}
                        </ProviderPreviewContainer>
                      </ProviderFlexContainer>
                      <ProviderFlexContainer style={{ backgroundColor: 'transparent', flexDirection: "column"  }}>
                        <ProviderFlexContainerTitle dangerouslySetInnerHTML={{ __html: `${matchedIndustry?.category || ''}` }}></ProviderFlexContainerTitle>
                        <ProviderFlexContainerDescription dangerouslySetInnerHTML={{ __html: `${matchedIndustry?.description || ''}` }}></ProviderFlexContainerDescription>
                      </ProviderFlexContainer>
                    </ProviderFlexContainerContent>

                    {/* Carousel */}
                    <CarouselContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                    <SliderSpecial {...settings} prevArrow={<PrevArrow className="slick-prev"/>} nextArrow={<NextArrow className="slick-next"/>}>
                          {joinNow.map((item, index) => (
                            <ProviderPreviewContainerItem key={index} $imgURL={item.src} $isHighlighted={highlightedProviderItem === item.caption} onMouseEnter={() => handleProviderItemSelected(item.caption)} onMouseLeave={() => setHighlightedProviderItem(null)}>
                                <ProviderPreviewContainerItemOverlay $isHighlighted={highlightedProviderItem} $caption={item.caption} ></ProviderPreviewContainerItemOverlay>
                                <p dangerouslySetInnerHTML={{ __html: `<br>${item.caption}` }}></p>
                            </ProviderPreviewContainerItem>
                          ))}
                      </SliderSpecial>
                      <ProviderFlexContainerDescription dangerouslySetInnerHTML={{ __html: `${carouselMatchedIndustry?.description || ''}` }}></ProviderFlexContainerDescription>

                    </CarouselContainer>

                    
                </GoghNowSectionJoinNowContainer>
            </GoghNowSectionJoinNow>



        <BenefitsSection ref={BenefitsSectionRef}>
            <BenefitsContainer>
                <SectionHeader>How We Elevate Your Business</SectionHeader>
                {/* <SectionHeaderDescription>More booked events, increased per-event revenue, and higher client satisfaction</SectionHeaderDescription> */}


                <BenefitsContent $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>

                    <BenefitsContentItem $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <BenefitsImageContainer>
                          <RocketIcon style={{ stroke: 'white',fill:"white", width: '100%', height: 'auto' }} />
                        </BenefitsImageContainer>
                        <BenefitContentItemTextContainer>
                          <h3>More Booked Events</h3>
                          <p>Make your space the obvious choice by offering a complete, all-in-one event solution.</p>
                        </BenefitContentItemTextContainer>
                    </BenefitsContentItem>

                    <BenefitsContentItem $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <BenefitsImageContainer>
                          <MoneyIcon style={{ stroke: 'white',fill:"white", width: '100%', height: 'auto' }} />
                        </BenefitsImageContainer>
                        <BenefitContentItemTextContainer>
                          <h3>Higher Revenue Per Event</h3>
                          <p>Clients spend more when services are effortless to book. Keep their dollars in your ecosystem.</p>
                        </BenefitContentItemTextContainer>
                    </BenefitsContentItem>

                    <BenefitsContentItem $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <BenefitsImageContainer>
                          <SaveTime style={{ stroke: 'white',fill:"white", width: '100%', height: 'auto' }} />
                        </BenefitsImageContainer>
                        <BenefitContentItemTextContainer>
                          <h3>Less Work, More Impact</h3>
                          <p>Our platform eliminates the headache of service coordination, so you can focus on what matters.</p>
                        </BenefitContentItemTextContainer>
                    </BenefitsContentItem>


                    <BenefitsContentItem $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <BenefitsImageContainer>
                          <FreeResources style={{ stroke: 'white',fill:"white", width: '100%', height: 'auto' }} />
                        </BenefitsImageContainer>
                        <BenefitContentItemTextContainer>
                          <h3>Curated Moments, Made Simple.</h3>
                          <p>Give clients everything they need to create unforgettable gatherings without the complexity.</p>
                        </BenefitContentItemTextContainer>
                    </BenefitsContentItem>


                </BenefitsContent>


            </BenefitsContainer>

        </BenefitsSection>





        <QuoteSection ref={SectionDividerSectionRef}>
            <QuoteOverlay style={{opacity: opacitySectionDivider}}></QuoteOverlay>
            <QuoteContainer>
                <QuoteContainerBox>
                    {/* <QuoteHeading>"</QuoteHeading> */}
                    {/* <QuoteHeading>Clients decide to book based on how they feel when you're not around.</QuoteHeading> */}
                    <QuoteImageContainer>
                        <QuoteImageContainerLightSource style={{opacity: opacitySectionDividerLightBulb}}/>
                        <img src='/images/Business/lightbulb.png' alt="lightbulb" />
                    </QuoteImageContainer>
                    <QuoteHeading>Clients are walking away—not because they don’t love your space, but because they aren't getting all the services they need.</QuoteHeading>
                </QuoteContainerBox>
            </QuoteContainer>
        </QuoteSection>



        {/* <QuickNavSection>
            <QuickNavContent ref={QuickNavContentRef}>
                <QuickNavigationContentContainer>

                    <QuickNavigationHeader>Discover the features that are empowering venues today</QuickNavigationHeader>
                    <QuickNavigationHeaderDescription $isSectionHovered={isSectionHovered}>Your All-in-One Solution for Instant Event Bookings</QuickNavigationHeaderDescription>
                    <QuickNavigationGridContentContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <QuickNavigationGrid $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>


                            <QuickNavigationGridItem style={{opacity:opacityQuickNavContentONE}} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                <ForText><p>Designed for your customers</p></ForText>
                                <QuickNavigationGridImage $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <img src='/images/Business/serviceicons/user-smile.png' alt="user" />
                                </QuickNavigationGridImage>
                                <QuickNavigationGridContent $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <h3>Event Booking</h3>
                                    <QuickNavigationGridPText $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                        Our modern technology organizes your spaces, information, and the services you offer and enables your clients to book them in minutes.
                                    </QuickNavigationGridPText>
                                </QuickNavigationGridContent>
                            </QuickNavigationGridItem>


                            <QuickNavigationGridItem style={{opacity:opacityQuickNavContentTWO}} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                <ForText><p>Designed for your business</p></ForText>
                                <QuickNavigationGridImage $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <img src='/images/Business/serviceicons/space.png' alt="space" />
                                </QuickNavigationGridImage>
                                <QuickNavigationGridContent $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <h3>Space Management</h3>
                                    <QuickNavigationGridPText $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Easily manage, and update your spaces, while quickly accepting or denying client requests to book them.</QuickNavigationGridPText>
                                </QuickNavigationGridContent>

                            </QuickNavigationGridItem>


                            <QuickNavigationGridItem style={{opacity:opacityQuickNavContentTHREE}} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                <ForText>
                                    <p>Designed for your customers</p>
                                </ForText>
                                <QuickNavigationGridImage $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <img src='/images/Business/serviceicons/provider1.png' alt="provider" />
                                </QuickNavigationGridImage>
                                <QuickNavigationGridContent $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                    <h3>Event Amenities</h3>
                                    <QuickNavigationGridPText $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Gives your clients access to quickly select the event services they need in one platform. You get provide the services you have available, while you get a portion of the services our local providers provide.</QuickNavigationGridPText>
                                    

                                </QuickNavigationGridContent>

                            </QuickNavigationGridItem>



                        </QuickNavigationGrid>
                    </QuickNavigationGridContentContainer>
                         
                </QuickNavigationContentContainer>
            </QuickNavContent>
        </QuickNavSection> */}





            <UnsureSection>
                <UnsureHeader>Not quite ready to get started?</UnsureHeader>

                <UnsureSubHeader>Check out the following Frequently Asked Questions to learn more about how GoghNow for Business can help support your company.</UnsureSubHeader>

                <FAQManagerSpecial questionsAndAnswers={questionsAndAnswers} title = {''} />
            </UnsureSection>

            <ServicesSection ref={servicesSectionRef}>
                <ServicesFlexContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                    {(isLarge) &&
                    <ServicesItemLeft>
                        {/* <ServicesHeader>Questions about GoghNow for Business? We're happy to help.</ServicesHeader> */}
                        {/* <ServicesSubHeader>Get access to event services and start earning for free. Upgrade for more features.</ServicesSubHeader> */}
                        <ImageContainer>
                            <img src='/images/Home/image1.png' alt="space" />
                        </ImageContainer>
                    </ServicesItemLeft>
                    }
                    <ServicesItemRight>
                        <ServicesItemRightBoxBorderContainer>
                            <ServicesHeaderRight $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Start using GoghNow for Business today!</ServicesHeaderRight>
                            <ServicesHeaderRightBig $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Register your business</ServicesHeaderRightBig>
                            <BusinessSignup 
                                // isOpen={true} 
                                // onClose={handleCloseSignup} 
                                onSubmit={handleSubmit}
                                formRef={formRef}
                                submitting={submitting}
                                // name= {serviceType.name}
                                // description = {serviceType.description}
                            />
                            {/* <NeedHelpLink to={'/help/business/start-today'}>Need help starting?</NeedHelpLink> */}

                        </ServicesItemRightBoxBorderContainer>
                    </ServicesItemRight>

                </ServicesFlexContainer>
            </ServicesSection>


            <FooterLoggedOut></FooterLoggedOut>

            {/* Popup Info for Venues*/}
            {highlightedItem && (
                <div onMouseEnter={(e)=>{e.stopPropagation()}} onMouseLeave={(e)=>{e.stopPropagation()}}>
                    <PopUpInfo
                        noBackground={!isMobile}
                        image={highlightedItem.image} 
                        title={highlightedItem.title}
                        description={highlightedItem.description}
                        // Appear={true}
                        SlideUp={true} // Add this prop to enable slide-up animation
                        list={highlightedItem.ideal}
                    />
                </div>
            )}



    </SpecialPageContentContainer>


       {/* POPUP ERRORS */}
       {showSuccessPopup && 
            <SignUpSuccess onClose={handleSuccessCloseSignup}/>
        }
        {isErrorPopupVisible && (
        <CheckoutError
          message={errorMessage}
          onClose={closeErrorPopup}>
        </CheckoutError>
      )}

    {/* <BusinessSignup 
        isOpen={isSignupOpen} 
        onClose={handleCloseSignup} 
        onSubmit={handleSubmit}
        formRef={formRef}
        submitting={submitting}
        name= {serviceType.name}
        description = {serviceType.description}
    /> */}
    </ScrollProvider>
    </>

)

}



export default Business;

const GoghNowSection = styled.div`
display: flex;
position: relative;
flex-direction: row;
text-align: center;
width: 100%;
justify-content: center;
align-items: center;
background-color: ${props => props.$backgroundColor ? `${props.$backgroundColor}` : 'transparent'};  // Corrected the string interpolation
min-height: 50vh;
padding: 24px 36px;
// padding-bottom:48px;
`

const GoghNowSectionContainer = styled.div`
display: flex;
flex-direction:column;
// height: 200dvh;
width: 100dvw;
padding:15px 36px;
// background-color: white; /*#f0f0f0;*/
align-items: start;
justify-content: start;
`


const GoghNowSectionJoinNow = styled(GoghNowSection)`
min-height: 0px !important;
`
const GoghNowSectionJoinNowContainer = styled(GoghNowSectionContainer)`
min-height: 0px;
align-items: center;
`



const SpecialTextParallaxContent = motion(styled(TextParallaxContent)`

`)
//Page Container
const SpecialPageContentContainer = styled(PageContentContainer)`
background-color: black !important;
max-width: 100dvw;
`
//NavBar
const SpecialNavBarLogout = styled(NavBarLogout)`
    position:fixed;
    background-color: transparent !important;
`

//HERO SECTION
const HeroSection = styled(motion.div)`
display:flex;
position; relative;
background-color: black;
`

const HeroFlexContainer = styled.div`

${mobileDisplayContentVertical};
    display: flex;
    align-items:start;
    justify-content: start;
    width: 100%;
    padding: 15px 36px;
    // max-width: 1280px;
    gap: 36px;
    // flex-grow: 1;

`
const HeroFlexItem1 = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: ${(props) => {
        if (props.$isMobile) return '100%';
        if (props.$isMedium) return '100%';
        if (props.$isLarge) return '50%';
        return '50%'; // Default to large if none match
    }}; 
    height: calc(100% - 50px) !important;
`




const HeroFlexItem2 = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${(props) => {
        if (props.$isMobile) return '100%';
        if (props.$isMedium) return '100%';
        if (props.$isLarge) return '50%';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;

`
const ImageContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    // position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const CTA = styled(CALLTOACTION)`
display: flex;
height: auto;
gap: 15px;
align-items: start !important;
p, h1{
margin-bottom: 0px;
text-align: left;

}
`;

const SignUpButton = styled.div`
color: #FFFFFF;
font-weight: 700;
font-size: 22px;
cursor: pointer;
background-color: #FBAE6290;
padding: 25px 50px;
border-radius: 10px;
left: 0px;
&:hover{
color: #FFFFFF;
background-color: #FBAE62;
}
`
const NeedHelpLink = styled(Link)`
margin-top: 0px;
color: tan;
cursor: pointer;
font-size: 1.25em;

&:hover{
color: white;
}
`
const GoghExperienceCTA = styled(motion.div)`
position: fixed;
display: flex;
bottom: 30px;

flex-direction: column;
height: auto;
width: 100vw;
justify-content: center;
align-items: center;
cursor: pointer;

&:hover{
    h3{
    color: #FBAE62;
    }
}
`

const GoghExperienceCTAHeader = styled.h3`

margin: 0px;
padding: 0px;
color: #FBAE6260;
text-align: center;
`
const HeroText = styled.h1`
color: #FFFFFF;
margin-top: 0px;
`
const HeroDescription = styled.p`
color: #FBAE62;
font-size : .8em;
margin: 0 0 24px;
line-height: 1.5em;
// letter-spacing: .75px;
max-width: 400px;
margin-bottom: 0px;
padding-bottom: 0px;
font-weight: 400;
// border-radius: 15px;
// background-color: white;
padding: 15px;
`

const HeroTextSubDescription = styled.h3`
color: #FFFFFF;
text-align: left;
margin-bottom: 25px;
font-weight: 200;
`

//==================================
//QuickBavigation Section
//==================================
const QuickNavigateContainer = styled.div`
display: flex;
// position: relative;
width: 100dvw;
height: 100dvh;
background-color: purple;
`








//SECTION DIV
const QuickNavSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh; /* Full viewport height */
  background-color: black;
  position: relative;
//   overflow: hidden;
`;

const QuickNavContent = styled.div`
  padding: 20px;
  min-height: 200vh; /* Provides enough height for scrollable area */
  position: relative; /* Relative to make sticky positioning work in child */
  display: inline-block;
`;

const QuickNavigationContentContainer = styled(motion.div)`
    display:flex;
    position: sticky;
    top: ${NAVBAR_HEIGHT};
    left: 0px;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    background-color: transparent;
    width: 100%;
    // max-height: calc(100vh - ${NAVBAR_HEIGHT});
    // margin-top:
    gap:20px;
    padding: 50px 20px;
    background-color: black;
    `

const QuickNavigationHeader = styled('h3', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
display: flex;
// width: 100%;
text-align: center;
margin: 0px;
color:#FFFFFF60;
// opacity: ${(props) => props.$isSectionHovered ? `1` : '1'};  // Corrected the string interpolation
@media (max-width: 769px) {
    padding: 0px;
}

`
const QuickNavigationHeaderDescription = styled('h2', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
text-align: center;
width: 100%;
margin: 0px;
color: ${(props) => props.$textColor ? `${(props).$textColor}` : 'white'};  // Corrected the string interpolation
@media (max-width: 769px) {
    padding: 0px;
}
`

const QuickNavigationGridContentContainer = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
display:flex;
flex-direction: column;
flex-grow: 1;
width: 100%;
margin: 0px;
height:auto;
align-items: center;
justify-content: center;
padding-top:${(props) => {
        if (props.$isMobile) return '0px';
        if (props.$isMedium) return '0px';
        if (props.$isLarge) return '50px';
        return '50px'; // Default to large if none match
    }}; 
`

const QuickNavigationGrid = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
display: grid;
height: auto;
max-height: 100%;
width: 100%;
// grid-template-columns: repeat(3,1fr);
grid-template-columns:${(props) => {
        if (props.$isMobile) return '1fr';
        if (props.$isMedium) return '1fr';
        if (props.$isLarge) return 'repeat(3,1fr)';
        return 'repeat(3,1fr)'; // Default to large if none match
    }}; 
// grid-auto-rows: 1fr;
grid-auto-rows: ${(props) => {
        if (props.$isMobile) return 'repeat(3,1fr)';
        if (props.$isMedium) return 'repeat(3,1fr)';
        if (props.$isLarge) return '1fr';
        return '1fr'; // Default to large if none match
    }}; 
gap: 15px;
max-width:1000px;
`

const QuickNavigationGridItem = styled(motion.div, {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
display: flex;
flex-direction: ${(props) => {
        if (props.$isMobile) return 'row';
        if (props.$isMedium) return 'row';
        if (props.$isLarge) return 'column';
        return 'column'; // Default to large if none match
    }}; 
justify-content: start;
align-items: center;
grid-row: span 1;
grid-column: span 1;
border-radius: 15px;
box-shadow: 5px 5px 10px #FFFFFF60;
background-color: white;
// padding: 25px 35px;
padding: ${(props) => {
        if (props.$isMobile) return '0px 5px';
        if (props.$isMedium) return '5px 5px';
        if (props.$isLarge) return '25px 35px';
        return '25px 35px'; // Default to large if none match
    }}; 
color: black;
cursor: pointer;
background-color: white;
text-align: center;

h3{
margin-bottom: 0px;
}
    
`
const QuickNavigationGridPText = styled(motion.p, {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`

    display: flex;
    font-size: 1em;
    color: #00000080;
    text-align: ${(props) => {
        if (props.$isMobile) return 'left';
        if (props.$isMedium) return 'left';
        if (props.$isLarge) return 'center';
        return 'center'; // Default to large if none match
    }}; 

`
const QuickNavigationGridImage = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
margin-bottom: 0px;
width: ${(props) => {
        if (props.$isMobile) return '25%';
        if (props.$isMedium) return '25%';
        if (props.$isLarge) return '75%';
        return '75%'; // Default to large if none match
    }}; 
aspect-ratio: 1 / 1;
font-size: 0px;
display: inline-block;
border-radius: 50%;
border: solid 5px black;
overflow: hidden;
margin: 10px;
box-shadow: 5px 5px 10px #FBAE6270;

img {
    display: block;
    width:100%;
}

// @media (max-width: 769px) {
//     flex-grow: 0;
//     width: 100%;
// }
`

const QuickNavigationGridContent = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
// ${mobileDisplayContentVertical}
display: flex;
flex-direction: column;
height: auto; 
width: 100%;
align-items: ${(props) => {
        if (props.$isMobile) return 'start';
        if (props.$isMedium) return 'start';
        if (props.$isLarge) return 'center';
        return 'center'; // Default to large if none match
    }}; 
justify-content: ${(props) => {
        if (props.$isMobile) return 'center';
        if (props.$isMedium) return 'center';
        if (props.$isLarge) return 'center';
        return 'center'; // Default to large if none match
    }}; 
`

const ForText = styled.div`
display: flex;
gap: 10px;
flex-direction: column;
p{
padding: 0.5em .25em;
margin: .25em;
background-color: #FFFF0080;
border-radius: 10px;
}
`

//==========================
//XXXXSERVICE DIV - CUSTOMERS
const CustomerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  padding: 50px 20px;
`;


const CustomerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: auto; 
  flex-grow: 1;
`;

const CustomerImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%; /* Optional for circular images */
`;

const CustomerText = styled.p`
  margin-top: 10px;
  margin-bottom: 0px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 1.25em;
`;



const CustomerDottedLine = styled.div`
  width: 50px;
  border-top: 2px dashed white;
`;


//BUSINESS
const BusinessContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  padding: 50px 20px;
`;


const BusinessItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: auto; 
  flex-grow: 1;
`;

const BusinessImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%; /* Optional for circular images */
`;

const BusinessText = styled.p`
  margin-top: 10px;
  margin-bottom: 0px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 1.25em;
`;



const BusinessDottedLine = styled.div`
  width: 50px;
  border-top: 2px dashed white;
`;


//EventServices
const EventServicesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  padding: 50px 20px;
`;


const EventServicesItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: auto; 
  flex-grow: 1;
`;

const EventServicesImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%; /* Optional for circular images */
`;

const EventServicesText = styled.p`
  margin-top: 10px;
  margin-bottom: 0px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 1.25em;
`;



const EventServicesDottedLine = styled.div`
  width: 50px;
  border-top: 2px dashed white;
`;
//==================================
//BENEFITS JOINT
//==================================
const BenefitsSection = styled.div`
// min-height:100dvh;
width: 100dvw;
background-color: ;
padding: 0px;
margin:0px;
padding:50px 25px;
`

const BenefitsContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width:100%;
// min-height: 200dvh;
padding: 0px;
margin:0px;
background-color: transparent;
position: relative;
`

const BenefitsContent = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
${mobileDisplayContentVertical};
display: grid;
grid-template-columns:${(props) => {
    if (props.$isMobile) return 'repeat(1, 1fr)';
    if (props.$isMedium) return 'repeat(2, 1fr)';
    if (props.$isLarge) return 'repeat(4, 1fr)';
    return 'repeat(3, 1fr)'; // Default to large if none match
  }};
grid-auto-rows: 1fr;
width: 100%;
width:${(props) => {
    if (props.$isMobile) return '95%';
    if (props.$isMedium) return '95%';
    if (props.$isLarge) return '100%';
    return '100%'; // Default to large if none match
  }};
max-width: 1200px;
gap: ${(props) => {
  if (props.$isMobile) return '10px';
  if (props.$isMedium) return '15px';
  if (props.$isLarge) return '25px';
  return '25px'; // Default to large if none match
}};
align-items: start;
justify-content: space-around;
padding:${(props) => {
  if (props.$isMobile) return '0px 0px 0px 0px';
  if (props.$isMedium) return '20px 0px 20px 0px';
  if (props.$isLarge) return '25px 0px 25px 0px';
  return '25px 0px 25px 0px'; // Default to large if none match
}};
`


const BenefitsContentItem = styled.div`
${mobileDisplayContentHorizontal}
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: auto; 
  height:100%;
  flex: 1 1;
  background-color: #FFFFFF20;
  padding: 25px;
  padding:${(props) => {
  if (props.$isMobile) return '5px 7.5px 5px 7.5px';
  if (props.$isMedium) return '25px';
  if (props.$isLarge) return '25px';
  return '25px'; // Default to large if none match
}};

  border-radius: 35px;
  gap:15px;
  h3{
  margin-bottom: 0px;
  }
  p{

  }
`;

const BenefitsImageContainer = styled.div`
margin-bottom: 0px;

width: 50%;
aspect-ratio: 1 / 1;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}

@media (max-width: 769px) {
    flex-grow: 1;
    width: 50%;
    padding-left: 5px;
    padding-right:5px;
}
`

const BenefitContentItemTextContainer = styled.div`
display: flex;
flex-direction:column;
width: 100%;
`
//==================================
//==================================
//==================================

const SectionHeader = styled.h2`
// font-size: 2.5em;
//   text-align: center;
//   color: #FFFFFF;
// letter-spacing: .10px;
// font-weight: 700;
//   line-height: 95%;
  padding: 5px 0px 5px 0px;
  // margin: 0px;
  color: #FFFFFF;
//   width: 100%;


`;

const SectionHeaderDescription = styled.h3`
display: flex;
    text-align: center;
    margin: 0px;
    color: #FFFFFF60;
      padding: 5px 0px 25px 0px;

    `

//==================================
//QUOTE JOINT
//==================================
const QuoteSection = styled.div`
min-height:100dvh;
width: 100dvw;
background-color: #FFFF00;
padding: 0px;
margin:0px;
`
const QuoteOverlay = motion(styled.div`
    position: absolute;
    display: flex;
    width: 100vw;
    height: 125dvh;
    background: black;
    opacity: 1;
    padding: 0px;
    margin:0px;
    z-index:0;
    `)
    const QuoteContainer = styled.div`
    ${mobileDisplayContentVertical};
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    min-height: 125dvh;
    padding: 0px;
    margin:0px;
    background-color: transparent;
    position: relative;
    
    `

    const QuoteContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    // border: 2px solid white;
    max-width: 950px;
    width: 100%;
    padding: 50px;
    margin: 50px 36px;
    align-items: center;

    `
    
const QuoteImageContainer = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  height: auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-bottom: 20px;

  img {
    // position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
`

const QuoteImageContainerLightSource = styled(motion.div)`
display: flex;
position: absolute;
width: 35%;
height: auto;
aspect-ratio: 1 / 1;
background-color: #FFFF0070;
border-radius: 50%;
top: 50%;
left: 50%;
transform: translateY(-50%) translateX(-50%); 
z-index: 0;
`

    const QuoteHeading = styled.h1`
    text-align: center;
    font-size: 3.5em;
    // padding: 50px;
    margin-bottom: 0px;
    color: black;
    `


//OFFERINGSECTION
const OfferingsSection = styled(motion.div)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 500px 36px;
  background-color: #000000;
  color: black;
  height: auto;
//   align-items: center;
//   justify-content: center;
//   box-sizing: border-box; /* Ensures padding is included in the height calculation */
  min-height: 100vh;
  width: 100vw;
`



//OUT OF THE BOX
const OutOfTheBoxContent = styled(motion.div)`
display:flex;
flex-direction: column;
min-height: 100vh;
width: 100%; 
padding: 15px 36px;
padding-top: ${NAVBAR_HEIGHT};
`
const OutOfTheBoxHeader = styled.h2`
color: #FBAE6265;
margin-bottom: 0px;
`

const OutOfTheBoxDescription = styled.h3`
color: #FBAE62;
`


const OutOfTheBoxStepContainerGrid = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
position: relative;
display: grid;
height: auto;
width: 100%;
min-height: 25vh;
grid-template-columns:${(props) => {
        if (props.$isMobile) return '1fr';
        if (props.$isMedium) return '1fr';
        if (props.$isLarge) return 'repeat(3,1fr)';
        return 'repeat(3,1fr)'; // Default to large if none match
    }}; 
grid-auto-rows: ${(props) => {
        if (props.$isMobile) return '1fr';
        if (props.$isMedium) return '1fr';
        if (props.$isLarge) return '1fr';
        return '1fr'; // Default to large if none match
    }}; 
gap: 15px;
// max-width:1000px;
padding: 15px 36px;
`

const OutOfTheBoxStepContainerItem = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
  display: flex;
  position: inherit;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 25vh;
  align-items: start;
  justify-content: start;
//   grid-column: ${(props) => props.gridColumn || 'span 1'};  // Default to span 1 if not specified
//   grid-row: ${(props) => props.gridRow || 'span 1'};        // Default to span 1 if not specified
  /* Default behavior for smaller screens */
  grid-column: ${(props) => {
    if (props.$isMobile || props.$isMedium) return 'span 1'; // One column for mobile and medium
    if (props.$isLarge) return props.gridColumn || 'span 1';  // Use gridColumn from props for large screens
    return 'span 1';  // Default to span 1
  }};
  
  grid-row: ${(props) => {
    if (props.$isMobile || props.$isMedium) return 'span 1'; // One row for mobile and medium
    if (props.$isLarge) return props.gridRow || 'span 1';  // Use gridRow from props for large screens
    return 'span 1';  // Default to span 1
  }};

// background-color: #EEEEEE10;
padding: 10px;

  h3{
  color: white;
  margin-bottom: 5px;
  }
  p{
  color: #FFFFFF;
  margin-top: 0px;
  }
`

const StepNumber = styled.h1`

position: absolute;
display: flex;
margin: 0px;
padding: 0px;
top: 50%; /* Align the top of the child to the middle of the parent */
transform: translateY(-50%); 
right:0%;

font-size: 15em;
font-weight: 400;
// color: #FFFFFF15;
color: #FBAE6225;
`




//GENERAL
const ContainerItemHeader = styled.div`
  display: inline-flex;
  align-items: start;
  margin-bottom: 15px;
  border-bottom: solid 1px white;
  position: relative;
  flex-shrink: 0;
  width: fit-content; /* Or a specific width in pixels or percentage */
  background-color: transparent;
  padding-top: 5vh;

`;
const ContainerItemDescription = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  width: fit-content; /* Or a specific width in pixels or percentage */
  background-color: transparent;
`;

//ITEMS
const ContainerIconsWithText = styled.div`
display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
`

const ContainerIconsWithTextItem = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
//   padding: 16px;
`;

const ContainerIconsWithTextImageContainer = styled.div`
  flex: 0 0 auto;
  width: 25px; /* Adjust based on your layout */
  aspect-ratio: 1 / 1;
  overflow:hidden;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center; /* Center the image within the container */

  }
`;

const ContainerIconsWithTextTextContainer = styled.div`
  flex: 1;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: start;
  p{
  margin-top: 0px;
  }
`;











//
//SpaceContent
const SpaceContent = styled(motion.div)`
display:flex;
min-height: 100vh;
width: 100%; 
padding: 15px 36px;
padding-top: ${NAVBAR_HEIGHT};
`
const SpaceContentHeader = styled.h2`
color: white;
`

//
//SpaceContent
const AmenitiesContent = styled(motion.div)`
display:flex;
min-height: 100vh;
width: 100%; 
padding: 15px 36px;
padding-top: ${NAVBAR_HEIGHT};
`

const AmenitiesHeader = styled.h2`
color: white;
`





const QuickNavLeftPanel = styled(motion.div)`
height: 60px;
bottom: -60px;
display:flex;
align-items:center;
justify-content:center;
position: fixed;
z-index:10;
width: 100vw;
`

const StickyMenu = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;
  background-color: #222;
  border-radius: 10px;
  border: solid 1px #5F5F5F;
    // box-shadow: 2px 2px 5px #FFFFFF60;
`;


    
// const CTAPopUpMenuItem = motion(styled(FlipLink)`
const CTAPopUpMenuItem = styled(motion.div, {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`

    white-space: nowrap;
    white-space:${(props) => {
    if (props.$isMobile) return 'wrap';
    if (props.$isMedium) return 'wrap';
    if (props.$isLarge) return 'nowrap';
    return 'nowrap'; // Default to large if none match
  }};
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
    padding: 15px 25px;
    cursor: pointer;
    color: white;
    background-color: #AAA;
    &:hover{
        color: white;
        border: solid 1px #5F5F5F;
        background-color: #5F5F5F;
    }
    `


//===========================
//GOGHNOW POWERS ALL VENUES
//=========================
const VenuesSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25vh 36px 15px 36px;
background-color: #000000;
  color: black;
  height: auto;
  align-items: center;
  justify-content: center;
//   box-sizing: border-box; /* Ensures padding is included in the height calculation */
  min-height: 100vh;
`
const VenuesHeader = styled.h2`
// font-size: 2.5em;
//   text-align: center;
//   color: #FFFFFF;
// letter-spacing: .10px;
// font-weight: 700;
//   line-height: 95%;
  padding: 5px 0px 50px 0px;
  color: #FFFFFF;
//   width: 100%;


`;

const VenuesHeaderDescription = styled.h3`
display: flex;
    text-align: center;
    margin: 0px;
    color: #FFFFFF60;
    `

const VenueGridContainer = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`

${mobileDisplayContentVertical};
display: grid;
grid-template-columns:${(props) => {
    if (props.$isMobile) return 'repeat(2, 1fr)';
    if (props.$isMedium) return 'repeat(2, 1fr)';
    if (props.$isLarge) return 'repeat(4, 1fr)';
    return 'repeat(4, 1fr)'; // Default to large if none match
  }};
grid-auto-rows: 1fr;
width: 100%;
width:${(props) => {
    if (props.$isMobile) return '80%';
    if (props.$isMedium) return '75%';
    if (props.$isLarge) return '100%';
    return '100%'; // Default to large if none match
  }};
max-width: 850px;
gap: 15px;
align-items: start;
justify-content: space-around;
padding: 15px 0px 500px 0px;



@media (max-width: 769px) {
    gap: 0px;
    padding: 20px 0px 250px 0px;
}

@media (max-width: 450px) {
    gap: 0px;
    padding: 0px 0px 150px 0px;
}
`

const VenueGridItem = styled.div `

    // background-color: #00000040;
    // border: 1px solid #CCCCCC;

    
    ${mobileDisplayContentVertical};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #EEE;
border: 1px solid #CCC;
// padding: 15px;
aspect-ratio: 1/1;
grid-row: span 1;
grid-column: span 1;

@media (max-width: 769px) {
    // gap: 20px;
    // padding: 20px 0px;
}
    `

const VenueGridItemContainer = styled.div`
//   position: absolute; /* Added to position the container absolutely */
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
  display: flex;
//   justify-content: center;
//   align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const VenueGridItemContainerHorizontal = styled.div`
display: flex;
width: 100%;
overflow: hidden;
align-items: center;
justify-content: center;
aspect-ratio: 2 / 1;
`







//Services By Section
const ServicesSection = styled.div`
display: flex;
flex-direction: column;
padding: 36px 36px;
height: auto;
background-color: #eff6f9;
align-items: center;
`

const ServicesHeader = styled.h2`
padding: 50px 0px 0px;
`

const ServicesHeaderRight = styled('h4', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
color:black;
margin-bottom: 0px;
margin-top: 0px;
padding-bottom: 0px;
width: 100%;
text-align: ${(props) => {
    if (props.$isMobile) return 'center';
    if (props.$isMedium) return 'center';
    if (props.$isLarge) return 'center';
    return 'center'; // Default to large if none match
}}; 
padding: 25px 0px 10px 0px;
font-weight: 200;
// font-size: 22px;
`

const ServicesHeaderRightBig = styled('h2', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
color:black;
// margin-bottom: 0px;
margin-top: 0px;
// padding-bottom: 0px;
width: 100%;
text-align: ${(props) => {
    if (props.$isMobile) return 'center';
    if (props.$isMedium) return 'center';
    if (props.$isLarge) return 'center';
    return 'center'; // Default to large if none match
}}; 
font-weight: 600;
font-size: 36px;
`
const ServicesSubHeader = styled.p`
// font-size: 2.5em;
text-align: center;
color: black;
letter-spacing: .10px;
font-weight: 1000;
line-height: 95%;
margin: 0px;
`

const ServicesFlexContainer = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`

${mobileDisplayContentVertical};
    display: flex;
    grid-template-columns:${(props) => {
    if (props.$isMobile) return 'repeat(1, 1fr)';
    if (props.$isMedium) return 'repeat(1, 1fr)';
    if (props.$isLarge) return 'repeat(2, 1fr)';
    return 'repeat(2, 1fr)'; // Default to large if none match
  }};
    grid-auto-rows: 1fr;
    width: 100%;
    padding: 36px 36px;
    // max-width: 1280px;
    gap: 50px;
    // flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 769px) {
    gap: 25px;
    padding: 20px 0px;
    }

    @media (max-width: 450px) {
        gap: 0px;
        padding: 0px 0px;
    }
`

const ServicesItemLeft = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
// background-color: #EEE;
// border: 1px solid #CCC;
padding: 15px;
gap:25px;

@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 0px;
}
`

const ServicesItemRight = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
background-color: white;
padding: 25px;
box-shadow: 5px 5px 10px #00000060;;

@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 15px;
}
`

const ServicesItemRightBoxBorderContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
background-color: white;
border: 2px solid #DDD;
padding: 25px;
// border-radius: 25px;

@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 15px;
}
`

const ServicesFlexItem1 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: left;
    justify-content: start;
    align-items: center;
    width: ${(props) => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(100% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    height: auto !important;
    background-color: #3F3F3F;
    padding: ${(props) => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;

`
const ServicesFlexItem2 = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
    opacity: 0.75;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${(props) => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(100% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;
    background-color: #3F3F3F60;
    padding: ${(props) => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;
    border: 4px dotted #00000060;

`

const ServicesFlexItem3 = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${(props) => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(50% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;
    background-color: #3F3F3F;
    padding: ${(props) => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;
`



const InternalFlexContainer = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
display: flex;
align-items: end;
margin-bottom: 10px;
`

const FlexItemOverview = styled.p`
min-height: ${(props) => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return '0px';
    if (props.$isLarge) return '6.5em';
    return '0px'; // Default to large if none match
}}; 
`
const FlexItemTitle = styled.h1`
color: #FFFFFF;
font-size: 3.5em;
font-weight: 700;
margin-top: 0px;
line-height: 50px;
margin: 0px;
`

const FlexItemTitleMetric = styled.p`
color: #FFFFFF80;
font-size : .8em;
line-height: 1.5em;
max-width: 400px;
font-weight: 400;
border-radius: 15px;
margin: 0px;

`

const ServiceCategory = styled.p`
font-weight: bold;
`

const FlexItemList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;

    p {
    margin: 0px;
    }
`;

const FlexItemAttributeContainer = styled.li`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: start;
`;

const FlexItemListImgContainer = styled.div`
    display: flex;
    height: 32px;
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    img {
    display: block;
    width:100%;
}
`;








//Services By Section
const UnsureSection = styled.div`
display: flex;
flex-direction: column;
padding: 36px 36px;
margin: 0vh 0px 0px 0px;
height: auto;
background-color: #CCC;
align-items: center;
justify-content: center;
min-height: 100vh;
`

const UnsureHeader = styled.h2`
// font-size: 2.5em;
// text-align: center;
// color: black;
// letter-spacing: .10px;
// font-weight: 1000;
// line-height: 95%;
padding: 50px 0px 0px 0px;
// padding-bottom: 0px;
margin-bottom: 0px;
`
const UnsureSubHeader = styled.p`
  font-size:1.25rem;
text-align: center;
color: black;
// letter-spacing: .10px;
// font-weight: 1000;
// line-height: 95%;
// margin: 0px;
padding-bottom: 0px;
margin-bottom: 0px;
`

const FAQManagerSpecial = styled(FAQManager)`
// padding-top: 25px;
// margin-bottom: 0px;
padding: 0px;
margin:0px;

`






//======================
//======================
//PROVIDER SECTION
//======================
//======================

const CarouselContainerDiv = styled.div`
  width: 100%;
  height:100%;
  ${props => {
    if (props.$isMobile) return 'flex !important';
    if (props.$isMedium) return 'none !important';
    if (props.$isLarge) return 'none !important';
    return 'none !important'; // Default to large if none match
  }};
`


const CarouselContainer = styled.div`
  position:relative;
  width: 100%;
  height:100%;
  // margin: 0 auto;  /* Center the slider horizontally */
  padding: 25px 0px;  /* Optional padding around the slider */
  display:${props => {
    if (props.$isMobile) return 'flex !important';
    if (props.$isMedium) return 'none !important';
    if (props.$isLarge) return 'none !important';
    return 'none !important'; // Default to large if none match
  }};
//   background-color:yellow;
  flex-direction:column;
  gap: 25px;

`;
const SliderSpecial = styled(Slider)`
width: 100%;
height:100%;

.slick-dots {
    position: absolute; /* Make dots absolute within the carousel */
    bottom: 10px; /* Adjust the bottom position as needed */
    left: 50%; /* Center the dots horizontally */
    transform: translateX(-50%); /* Offset to compensate for centering */
    display: flex; /* Ensure horizontal alignment */
    justify-content: center; /* Center dots within the carousel width */
    li {
        margin: -5px;
        // color: white;
        // background-color:yellow;

        button:before {
        color: white;
        }
    }
  }
  &:hover .prev-button,
  &:hover .next-button {
    opacity: 1;
    color: black;
  }

  .slick-list{
  height:100%;
  min-height:100%;
    div{
      height:100%;
    }
  }
`

const ProviderFlexContainerContent = styled.div`
display: ${props => {
  if (props.$isMobile) return 'none';
  if (props.$isMedium) return 'flex';
  if (props.$isLarge) return 'flex';
  return 'flex'; // Default to large if none match
}};
width: 100%;
max-width: 1080px;
padding: 50px 0px;
gap: 25px;
flex-wrap:${props => {
  if (props.$isMobile) return 'none';
  if (props.$isMedium) return 'wrap';
  if (props.$isLarge) return 'nowrap';
  return 'nowrap'; // Default to large if none match
}};;
`

const ProviderFlexContainerTitle = styled.h3`
color: black;
text-align: left;
padding-bottom: 0px;
`
const ProviderFlexContainerDescription = styled.p`
color: black;
text-align: left;
padding-top: 0px;
margin-top: 0px;
`
const ProviderFlexContainer = styled.div`
display: flex;
// flex: 1 1;
width: 50%;
flex-grow:1;


`
const ProviderPreviewContainer = styled('div', {
  shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
${mobileDisplayContentVertical};
display: ${props => {
  if (props.$isMobile) return 'none';
  if (props.$isMedium) return 'grid';
  if (props.$isLarge) return 'grid';
  return 'none'; // Default to large if none match
}};
grid-template-columns:${props => {
  if (props.$isMobile) return 'repeat(1, 1fr)';
  if (props.$isMedium) return 'repeat(2, 1fr)';
  if (props.$isLarge) return 'repeat(2, 1fr)';
  return 'repeat(2, 1fr)'; // Default to large if none match
}};
grid-auto-rows: 1fr;
width:100%;
max-width: 100%;
gap: 25px;
align-items: start;
// padding: 50px 0px 50px 0px;
place-items: center;
`

const ProviderPreviewContainerItem = styled('div', {
shouldForwardProp: (prop) => !prop.startsWith('$'), // Exclude all props starting with `$`
})`
  overflow: hidden;
display: flex !important;
position:relative;
flex-direction: column;
align-items: start;
justify-content:end;
color: white;
flex: 1 1;
height:100%;
aspect-ratio: 1 / 1.15;
border-radius: ${props => {
  if (props.$isMobile) return '0px';
  return '15px'; // Default to large if none match
}};
background-color: ${props => {
  if (props.$isMobile) return 'transparent';
  if (props.$isMedium) return '#00000010';
  if (props.$isLarge) return 'transparent';
  return 'transparent'; // Default to large if none match
}};

padding: 10px 5px;

background: 
  linear-gradient(to top, #00000040, #00000020, transparent, transparent),
  url(${(props) => props.$imgURL});  background-size: cover;
//   background-position: center;
cursor: pointer;


transition: background-position 0.35s ease-in-out;
background-position: ${(props) => (props.$isHighlighted ? 'center' : 'none')};


p{
font-size: ${(props) => (props.$isMobile ? '.875em' : props.$isMedium ? '1.0em' : '1.5em')};
font-weight: ${(props) => (props.$isMobile ? '400' : props.$isMedium ? '500' : '600')};
//   font-weight: 600;
text-align:left;
padding: 15px 5px;
border-radius: 5px;
line-height: 1em;
color: #FFFFFF;
margin-bottom: 0px;
bottom:0px;
}


`
const ProviderPreviewContainerItemOverlay = styled.div`
display:flex;
position:absolute;
height:100%;
width:100%;
left:0px;
top:0px;
background-color: ${(props) => (props.$isHighlighted === null || props.$isHighlighted === props.$caption ? 'none' : '#00000080')};
transition: background-color 0.1s ease-in-out;

`
const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0;
  
//   display: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    width: 40px;
    height: 40px;
    box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);

  }

  font-weight: 600;
`;

const PrevArrowButton = styled(ArrowButton)`
  left: 10px;
  background-color:white;
  z-index:2;
  opacity:1;
  color:black;

`;

const NextArrowButton = styled(ArrowButton)`
  right: 10px;
    background-color:white;
      z-index:2;
        opacity:1;
color:black;

`;

const PrevArrow = ({ onClick }) => (
  <PrevArrowButton className='prev-button' type="button" onClick={(e) => { e.stopPropagation(); onClick(e); }}>
     ←
  </PrevArrowButton>
);

const NextArrow = ({ onClick }) => (
  <NextArrowButton className='next-button' type="button" onClick={(e) => { e.stopPropagation(); onClick(e); }}>
     →
  </NextArrowButton>
);