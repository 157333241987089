//Hemlet
import { Helmet } from 'react-helmet-async';


import UserAppContent from 'apps/User/UserAppContent';
import NavBarLogin from  "apps/User/NavBarComponents/NavBarLogin";

//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//URL PROVIDER AND CONTEXT
import { HomeURLProvider } from "context/URL/HomeURLContext";
import { AppNavBarManagerProvider } from "context/AppNavBarContext";
import { useAuth } from "third_party/redux/selectors";

import { PageContainer } from "components/Page/Elements";
import {PageContentContainer} from "components/Page/Elements"

const UserApp = (props) => {

    const { isLoggedIn } = useAuth()

//GeneralSignUpMenuProvider provides context for a popup menu that goes over Homeontent
    return (
            <>
                <Helmet>
                    <title>GoghNow the App: Explore Event Services Near You | GoghNow</title>
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="GoghNow | The Future of Events" />
                    <meta name="description" content="GoghNow is a one-stop-shop for event services" />
                    <meta property="og:image" content="images/cta-logo-one.png" />
                </Helmet>
                <HomeURLProvider>  {/*updates url and gets data from url*/}
                <AppNavBarManagerProvider>

                        <SpecialPageContainer>
                          <SpecialNavBarLogin/>
                            <UserAppContent />                            
                        </SpecialPageContainer>
                </AppNavBarManagerProvider>
                </HomeURLProvider>
            </>
    );
};


export default UserApp;

const SpecialNavBarLogin = styled(NavBarLogin)`
    // position:fixed;
    background-color: transparent !important;
    // top:0px;
`

const SpecialPageContainer = styled.div`
display: flex;
flex-direction: column;
height:100dvh;
width: 100dvw;
overflow:hidden;
background-color: white;
`