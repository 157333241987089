import styled from '@emotion/styled'
import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';

import PhoneInputContainer from "components/Inputs/PhoneInputContainer";
import SubmitButton from "components/Buttons/SubmitButton";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';

import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import { useScreenContext } from 'context/AppContext/ScreenContext';

import PasswordTextField from 'components/Inputs/PasswordTextField';
import OrSeparator from 'components/Dividers/OrSeparator';

import Panel from 'components/Containers/Panel';

import { NAVBAR_HEIGHT } from "components/constants";

//SHIT I NEED TO CHANGE
import LoginNavBarLogout from "apps/User/pages/login/LoginNavBarLogout"//CHANGE
import AuthServiceAPI from 'api/User/AuthService';//CHANGE
import ProfileServiceAPI from 'api/Provider/ProfileServiceAPI';//CHANGE
import PopUpNotification from 'components/Popups/PopUpNotification';

//MANAGE LOGIN
import { useStateManagementFunctions } from 'third_party/redux/stateManagementFunctions';

interface LoginProps {}

interface ScreenProps {
    $isMobile: boolean;
    $isMedium: boolean;
    $isLarge: boolean;
}


interface FormData {
    phone_number: string;
    email: string;
    password: string;
  }
interface ImageProps{
    $imgUrl: string;
}
const BusinessLogin: React.FC<LoginProps> = () => {

    const authService = new AuthServiceAPI();
    const profileService = new ProfileServiceAPI();
    // const [email ,setEmail] = useState("")
    // const [password ,setPassword] = useState("")

    const navigate = useNavigate();


    const {isMobile, isMedium, isLarge} = useScreenContext()

    const [data, setData] = useState<FormData>({
        phone_number: '',
        email: '',
        password: ''
    })

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    // const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    //   event.preventDefault();
    // };

    const [error, setError] = useState<string | null>(null);
    const [showNotification, setShowNotification] = useState(false);

    //MOVED THIS from API to inside the components
    const {login,logout,updateUser} = useStateManagementFunctions()


    const onLoginSubmit = async(e:React.FormEvent) =>{
        e.preventDefault()
        console.log('information',{data})
            try{
            // Authenticate the user
            await authLogin(data);
            // Fetch user profile
            await fetchUserProfile();
            // Navigate to home page if both succeed
            navigate('/s');
        }
        catch(error){
            console.error("Error during login or profile fetching: ", error);
            // Handle the case where the profile fetch fails after login
            // setError(error as string);
            setError((error instanceof Error) ? error.message : 'An unexpected error occurred');
            // Optional: Keep the user logged in or log them out depending on your app's behavior
            logout();
        }
    }

    //===============================
    // API CALLS
    //===============================
    const authLogin = async (data:FormData) => {
        try {
        // Call AuthService to log in
        const authResult = await authService.login(data.phone_number, data.email, data.password);
        console.log('auth result: ', authResult);
        login(authResult.token,authResult.refresh_token,'user')

        }
        catch (error){
            console.error("Login failed: ", error);
            throw new Error(`Failed to authenticate user: ${error}`);
        }
    }

    const fetchUserProfile = async () => {
        try {
        // Call ProfileService to get profile
        const profileResult = await profileService.getProfile()
        console.log('profile result: ', profileResult);
        updateUser(profileResult)

        }
        catch (error){
            console.error("Fetching profile failed: ", error);
            throw new Error(`Failed to fetch profile: ${error}`);
        }
    }
    //===============================
    // HANDLE INPUT CHANGE
    //===============================
    const handleInputChange = (value:any,id:string) => {
        // const { id, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [id]: value,
        }));

        // console.log('input changing:', value);
    };
    //===============================
    // USE EFFECTS
    //===============================
       useEffect(() => {
        console.log('Data updated:', data);
    }, [data]); // Dependency array ensures this runs whenever `data` changes.


    const handleSignUp = () => {
        console.log('signup button pressed')
        navigate('/signup-provider'); // Redirect to the SignUp page

    }

    const handleLogoClick = () =>{
        console.log('logo click')
        navigate('/')
     }

     const scrollToTop = () =>{
        console.log("scrollToTop NavBar")
        // setTimeout(() => contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    } 
    const handleClosePopup = () => {
        setError(null);  // Close the popup by clearing the error state
      };

    return (
    <>
    <SpecialNavBarLogout handleNavigateHome={scrollToTop}/>
    <PageContainer>
        <Content $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}> 


        <InputContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>

            <SpecialPanel $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                <LogoContainer onClick={handleLogoClick}><img src='/images/cta-logo-one.svg' alt="GoghNow" /></LogoContainer>
                <WelcomeText>Welcome back to GoghNow!</WelcomeText>
                {/* <LoginForm onSubmit={onLoginSubmit}> */}
                <LoginForm>
                        <PhoneInputContainer
                        id="phone"
                        label=""
                        value={data.phone_number || ''}
                        onChange={(e) => handleInputChange(e.target.value, 'phone_number')}
                        placeholder="Phone Number"
                        />
                        


                        <PasswordTextField
                            id="password"
                            label="Password"
                            variant="outlined"
                            value={data.password || ''}
                            // onChange={handleInputChange}
                            onChange={(e) => handleInputChange(e.target.value, 'password')}
                            fullWidth
                            margin="normal"
                            required
                        />
                        {/* <TextInputContainer id="password" label="password" value={data.password} onChange={handleInputChange} placeholder="Enter password"></TextInputContainer> */}
                        <SubmitButtonCustom onClick={onLoginSubmit} text="Login"/>
                </LoginForm>
                <SeparatorOR/>
                <SignupButtonCustom text="Create an account" onClick={handleSignUp}/>
            </SpecialPanel> 
        </InputContainer>
        {/* <SideContainer>
            <ImageBackground $imgUrl={'/images/Signup/successlogin.png'}>
                <ImageBackgroundContentContainer>
                </ImageBackgroundContentContainer>
            
            </ImageBackground>
        </SideContainer> */}





            <BgImage/>
            <BgImageOverlay/>

        </Content>
    </PageContainer>
    {/* Conditionally render the popup when there is an error */}
    {error && (
            <PopUpNotification
            titleText="Error"
            descriptionText={error}
            buttonText="Close"
            buttonAction={handleClosePopup}  // Close popup on button click
            />
        )}
    </>
    );
    
};

const SpecialNavBarLogout = styled(LoginNavBarLogout)`
    position:fixed;
    background-color: transparent !important;
    h1{
        color:black;
    }           
`

const SpecialPanel = styled(Panel)`
align-items: start;
width:400px;
max-width:100%;
border: none;
background-color: transparent;
`
const WelcomeText = styled.h3`
color:black;
font-weight:600;
`
const PageContainer = styled.section`

overflow: hidden;
position: relative;
display: flex;
flex-grow:1;
flex-direction: column;
text-align: left;
height: calc(100vh);
// top: ${NAVBAR_HEIGHT};
width: 100vw;
background-color: #FFFFFF90;
`;

const Content = styled.div<ScreenProps>`
width: 100%;
display: flex;
position: relative;
min-height: 100vh;
min-height: 100dvh;
box-sizing: border-box;
padding: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return '100px 40px';
    if (props.$isLarge) return '100px 40px';
    return 'px'; // Default to large if none match
}};
justify-content: ${props => {
    if (props.$isMobile) return 'none';
    if (props.$isMedium) return 'center';
    if (props.$isLarge) return 'center';
    return 'center'; // Default to large if none match
}}; 
align-items: center;
// flex-direction: column;
height: 100%;
gap: 10px;
`;








const InputContainer = styled.div<ScreenProps>`
display: flex;
flex-direction:column;
background-color: #FFFFFF50;
justify-content: center;
align-items:center;
// height:100%;
border: solid 1px #AAA;
boder-radius: 100px;
overflow: hidden;
`

const SideContainer = styled.div`
    display:flex;
    gap:25px;
    flex-direction:column;
    text-align: center;
    background-color: white;
    width: 400px;
    height:100%;

     @media (max-width: 850px) {
    display: none;
  }
`

const ImageBackground = styled.div<ImageProps>`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 0px; /* The gaps between the squares */
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  background-color: white; /* The color of the gaps */
  position: relative;
  overflow: hidden;
    background-image: ${props => {
        if (props.$imgUrl) return `url(${props.$imgUrl})`;
        return 'none'; // Default to 'none' if no image URL is provided
    }};
    background-size: cover;
    background-position: center;
    filter: brightness(1.5);
`;
const ImageBackgroundContentContainer = styled.div`
display: flex;
flex-direction: column;
position: absolute;
width: 100%;
height: 100%;
justify-content: center;
align-items: center;
    h1{
    color: black;
    margin: 0px;
    }
    p{
    color: black;
    margin: 10px;
    }
    h2{
    margin:0px;
    }
`













const BgImage = styled.div`
// background-image: url("/images/web-gradient-100.jpg");
height: 100%;
background-position: top;
background-size: cover;
background-repeat: no-repeat;
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: -1;
`;
const BgImageOverlay = styled.div`
height: 100%;
opacity: 0.95;
background-color: white;
background-position: top;
background-size: cover;
background-repeat: no-repeat;
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: -1;
`;



const LogoContainer = styled.a`
padding: 0px;
width: 150px;
margin-top: 36px;
margin-bottom: 36px;
max-height: 70px;
font-size: 0px;
display: inline-block;
cursor: pointer;
img {
    display: block;
    width:100%;
}
`

const MainText = styled.h1`
color: black;
font-weight: 800;
margin-top: 0px;
width:100%;
`


const Description = styled.p`
color: #00000080;
font-size : 11px;
margin: 0 0 24px;
line-height: 1.5em;
letter-spacing: 1.5px;
// max-width: 400px;
`

const LoginForm = styled.form`
display:flex;
flex-direction: column;
width: 100%;
background-color: transparent;
border-radius: 50px;
gap: 0px;
`

const LoginFormContainer = styled.div`
display: flex;
flex-direction: column;
gap: 0px;
width: 100%;
align-items: center;
`

const SubmitButtonCustom = styled(SubmitButton)`
  margin-top: 18px;
  background-color: #64B0B8;
  &:hover {
    background-color: #64B0B880;
  }
`;

const SignupButtonCustom = styled(SubmitButton)`
  margin-top: 18px;
  background-color: #AAA;
  border: solid 1px #CCC;
  width: 100%;
  color: white;

  &:hover {
        background-color: #CCC;
   }
`;


const SeparatorOR = styled(OrSeparator)`
padding: 36px 0px;
`

export default BusinessLogin;