
//Used to Upload Photos or Upload Pictures
export const triggerFileUpload = (onPhotoSelected) => {
    // Create an input element dynamically
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
  
    // Add an event listener to capture the selected file
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (file && onPhotoSelected) {
        onPhotoSelected(file); // Pass the selected file to the callback
      }
    };
  
    // Programmatically trigger the file input
    input.click();
  };
  