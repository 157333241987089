import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

// import styled from "styled-components";
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

import { frostedGlassStyle, frostedContainerStyle } from 'components/emotionStyles';
import { Z_INDEX_NAVBAR, Z_INDEX_POPUP_MENUS } from 'components/constants';
import { NAVBAR_HEIGHT } from 'components/constants';
import { TextField } from '@mui/material';

import GoogleMapsFunctions from 'utility/googleMapsFunctions';
import {useStateManagementFunctions} from 'third_party/redux/stateManagementFunctions';
import {AddressSearchComponent} from "components/Inputs/AddressSearchComponent"

//SEARCH
import { useSearch } from 'third_party/redux/selectors';
import { useSearchActions } from 'third_party/redux/search/searchActions';


const SearchPopUpComponent = ({zIndex=Z_INDEX_NAVBAR+ 1,data={}, onClose,onChange, isOpen, children}) => {
    const navigate = useNavigate();
    const {updateSearchLocation} = useStateManagementFunctions()


    
    // const [isOpen, setIsOpen] = useState(true);
    // const [selectedTab, setSelectedTab] = useState('AI'); //'Search'
    //SEARCH
    const selectedTab = useSearch().searchType
    const {SEARCH_TYPE} = useSearchActions()

    const handleToggle = (selectedTab) => {
      console.log("Selected Tab:", selectedTab); // For debugging
      SEARCH_TYPE(selectedTab)
    //   setSelectedTab(selectedTab)
    };


    // useEffect(() => {
    //     if (externalIsOpen !== undefined) {
    //       setIsOpen(externalIsOpen);
    //     }
    //   }, [externalIsOpen]);

    const handleClose = () => {
        console.log("IM TYING to CLOSE")
        // setIsOpen(false);
        if (onClose) {
          onClose();
        }
      };

      //SHIT IN HOME
      // const [data, setData] = useState({})
      const [localData, setLocalData] = useState({
        aiUserPrompt: data.aiUserPrompt || ''
    });
      const handleInputChange = (e) => {
        const { id, value } = e.target;
    
        // Update local state
        const updatedData = { ...localData, [id]: value };
        setLocalData(updatedData);
    
        // Trigger external function if provided
        if (onChange) {
            onChange(e, value);
        }
    };
    const handleSearchSubmit = async (address) => {

        console.log("Get Place From Address: ", address)
        if(!address){
          console.log('no address was input')
          return
        }
        try{
            const place = await GoogleMapsFunctions.getPlaceFromAddress({address:address});
            if (place){
                console.log('handleSearchSubmit (place returned is):', place);
                updateSearchLocation(place)
                navigate('/s')
            }
            else {
                //do nothing
                console.log("no place was found")
            }
        }
        catch(error){
            console.error('Error fetching place details:', error);
        }
      };
      const inputChange = (inputValue) => {
        console.log("new input: ",inputValue)
        GoogleMapsFunctions.fetchPredictions(inputValue)
        .then(predictions => {
          console.log('Predictions:', predictions);
        })
        .catch(error => {
          console.error('Error fetching predictions:', error);
        });
      }
    
      // Function to handle place selection
      const handlePlaceSelected = (place) => {
        console.log('Place Was Selected:', place);
        updateSearchLocation(place)
        navigate('/s')
    };
    console.log("isOPEN:",isOpen)

    //NEED TO IMPLEMENT THIS TO GET THE FETAURES
    const [unlockAITab, setUnlockAITab] = useState(false);

    return(
        <>
    {/* {isOpen &&( */}
            <OverlayWrapper $isVisible={isOpen} $zIndex={zIndex}>
                <SearchInputMainContainer $isVisible={isOpen} >
                <HeaderRow>
                    <button onClick={handleClose}>x</button>
                </HeaderRow>
                      
                        <TabContainer>
                        {unlockAITab && (
                          <TogglePanelContainer>
                            
                            <ToggleButton
                            key={'AI'}
                            $isActive={selectedTab === 'AI'}
                            onClick={() => handleToggle('AI')}
                            >
                            AI <span style={{ fontSize: '0.75em', marginLeft: '5px', fontWeight: 'bold', color:'#f6933180' }}>BETA</span>
                            </ToggleButton>


                            <ToggleButton
                            key={'Search'}
                            $isActive={selectedTab === 'Search'}
                            onClick={() => handleToggle('Search')}
                            >
                            Near You
                            </ToggleButton>


                          </TogglePanelContainer>
                        )}
                        </TabContainer>
                        <SearchInputContentContainer $isVisible={selectedTab==='AI'}>
                        {/* {selectedTab==='AI' &&( */}
                            <>
                            <SearchInputMainDescription>Our AI will recommend event services</SearchInputMainDescription>
                            <TextField
                              id="aiUserPrompt"
                              label="Details"
                              type="text"
                              variant="outlined"
                              value={localData.aiUserPrompt || ''}
                              onChange={handleInputChange}
                              fullWidth
                              size="small"
                              margin="normal"
                              placeholder="I'm having a gathering this saturday with about 10 friends, at 5:30pm for 2 hours at 222 Greenway Lane, Chalotte, NC."
                              multiline
                              required
                              rows={4} // You can adjust the number of rows as needed
                              InputLabelProps={{
                                shrink: true,
                            }}
                            />
                            </>
                          {/* )} */}
                          </SearchInputContentContainer>


                          <SearchInputContentContainer $isVisible={selectedTab==='Search'}>
                          {/* {selectedTab==='Search' &&( */}
                            <>
                            {/* Keep SpecialAddressSearchComponentMemo mounted but hide it based on selectedTab */}
                            
                            <SearchInputMainDescription>Enter your <b>event address</b> to find event services near you</SearchInputMainDescription>
                            <SpecialAddressSearchComponent onSearchSubmit={handleSearchSubmit} onPlaceSelected={handlePlaceSelected} onChange={inputChange}/>
                            </>
                          {/* )} */}
                        </SearchInputContentContainer>
                        </SearchInputMainContainer>
                    </OverlayWrapper>
    {/* )} */}
    </>
    )
}
export default SearchPopUpComponent
// Keyframes for the fade-in and slide-in animations
const fadeIn = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const slideIn = css`
  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;



const HeaderRow = styled.div`
display:flex;
position:absolute;
right:15px;
top:10px;
align-items: center;
justify-content: end;
// width: 100%;

button {
    background-color: transparent;
    display: flex;
    align-items:center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-weight: 200;
    font-size: 2.0em;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
        color: #00000080;
          filter: brightness(1.35);
          
    }
  }
`


const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000090;
  // display: flex;
  display:${props => {
    if (props.$isVisible) return 'flex';
    return 'none';
  }};
  z-index: ${props => (props.$zIndex)};
  justify-content: center;
  align-items: center;

      ${frostedGlassStyle}

  // Apply fade-in animation when visible
  animation: fadeIn 0.5s ease forwards;
  ${fadeIn}
`;


const TabContainer = styled.div`
display:flex;
width: 100%;
`

const TogglePanelContainer = styled.div`
  display: inline-flex;
  gap: 5px;
  padding: 5px 25px;
  // background-color: white;
  border-radius: 25px 25px 0px 0px;
  align-self: flex-start;
  box-sizing: border-box;
  justify-content: center;
  min-height: calc(${NAVBAR_HEIGHT}/2);
  width: 100%;
`;

const ToggleButton = styled.button`
  padding: 15px 15px;
  border: none;
  color:${props => {
    if (props.$isActive) return 'black';
    return '#BBBBBB80';
  }};
  background-color:${props => {
    if (props.$isActive) return '#EEE';
    return 'transparent';
  }};
  font-weight:${props => {
    if (props.$isActive) return '600';
    return '300';
  }};
//   color: ${({ active }) => (active ? "#EEE" : "#333")};
//   background-color: ${({ active }) => (active ? "gray" : "transparent")};
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.3s, color 0.3s;
  font-size:1em;
  &:hover {
    color: #BBBBBB;
  }

`;

const SearchInputMainContainer = styled.div`
display: ${props => {
  if (props.$isVisible) return 'flex';
  return 'none';
}};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  min-height: 25vh;
  max-width: 600px;
  background-color: #fff;
  border-radius: 25px;   
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 35px 5%;
//   gap:25px;

${frostedContainerStyle}
  
  // Apply slide-in animation from the bottom
  animation: slideIn 0.5s ease forwards;
  ${slideIn}

`
const SearchInputContentContainer = styled.div`
display: ${props => {
  if (props.$isVisible) return 'flex';
  return 'none';
}};
flex-direction: column;
width: 100%;
border-radius:25px; //0px 25px 25px;
overflow: hidden;
min-height: ${NAVBAR_HEIGHT};
// background-color: #FFFFFF80;
padding: 15px;
align-items: center;

`

const SearchInputMainDescription = styled.p`
// margin: 0px;
// padding: 0px;
color: #00000080;
`
const SpecialAddressSearchComponent = styled(AddressSearchComponent)`
// box-shadow: 5px 5px 10px #FBAE6270;
  // box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7); /* Optional styling for emphasis */

//     max-width: 600px;

//     input::placeholder {
//     text-align: center; /* Ensures the placeholder is aligned to the padding */
//     color: #CCC;
//     padding-left: 80px;
//     // z-index: calc(${Z_INDEX_NAVBAR} + 5)
// }
`