
import React, {useState, useEffect, useRef, useMemo, useCallback } from "react";
// import styled from "styled-components";
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'
import { Title } from "@mui/icons-material";
import { useScreenContext} from "context/AppContext/ScreenContext";
import {mobileDisplayContentVertical,mobileDisplayContentHorizontal, mobileSizeDisplayNone, mobileSizeDisplayBlock, mobileSizeDisplayFlex,mediumDisplayContentHorizontal, frostedContainerStyle, frostedGlassStyle} from 'components/emotionStyles'



const HowItWorksCard = ({$width, $isActive, isEven, step,title,description, callToAction, callToActionHandler,imageURL='/images/Home/userNew2.png',...props}) => {
    const formattedStep = String(step).padStart(2, '0'); // Ensures step is always two digits

    console.log('isEven: ',isEven)

    const {isMedium, isMobile} = useScreenContext()
    const adjustedIsEven = isMedium || isMobile ? true : isEven;
    const isExpanded = isMedium || isMobile ? true : false
    return (
        <>
        <Container {...props}>

        <ImageContainerODD $display = {!adjustedIsEven} ><img src={imageURL}/></ImageContainerODD>
            {/* <ContainerStep>
                <Line></Line>
            </ContainerStep> */}

            <ContainerTextContent $isExpanded={isExpanded}>
                    <Step><h1>{formattedStep}</h1></Step>
                <DescriptionContent>
                    <TitleElement>
                        {title}
                    </TitleElement>
                    <DescriptionElement
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                    {/* <ContainerTextContentAction onClick={callToActionHandler}>
                        {callToAction}
                    </ContainerTextContentAction> */}
                </DescriptionContent>


            </ContainerTextContent>
            <ImageContainerEVEN $display = {adjustedIsEven} $isExpanded={isExpanded}><img src={imageURL}/></ImageContainerEVEN>

        </Container>
        </>
    )
}
export default HowItWorksCard


const Container = styled.div`
${mobileDisplayContentVertical}
display: flex;
position:relative;
width:100%;
height: auto;
border-radius: 15px;
min-height: 15vh;
background-color: transparent;
z-index: ${({ $isActive }) => ($isActive ? 100 : 80)};
gap:25px;
padding-top: 15px;
justify-content: center;
`;

const ContainerStep = styled.div`
display:flex;
flex-direction:column;
align-items:start;
width:3px;


`
const Line = styled.div`
display:flex;
width: 1.5px;
height: calc(100% + 15px);
// flex: 1;
// background-color: white;
`

const ContainerTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: ${(props) => (props.$isExpanded ? '100%' : '50%')};
  border-left: 1px solid white;
  padding-left:25px;
`;


const Step = styled.div`

display:flex;
font-weight: 200;
color: black;
// border: 1px solid white;
// background-color: white;
// border-radius: 50%;
justify-content:center;
align-items:center;
// padding: 20px 20px;
h1{
color:white;
margin:0px;
font-weight: 200;
font-size: 3.5rem;
}

`
const ImageContainerEVEN = styled.div`
// display: flex;
display: ${({ $display }) => ($display ? 'flex' : 'none')};

  width: ${(props) => (props.$isExpanded ? '40%' : '20%')};
// height: 200px;
aspect-ratio: 1 / 1;
overflow: hidden;
img {
 width: 100%; /* Ensure the image covers the full width */
    height: 100%; /* Ensure the image covers the full height */
    object-fit: cover; /* Maintain aspect ratio and fill the container */
    object-position: center; /* Center the image */
}
`
const ImageContainerODD = styled.div`
// display: flex;
display: ${({ $display }) => ($display ? 'flex' : 'none')};
height:auto;
  width: ${(props) => (props.$isExpanded ? '40%' : '20%')};
// height: 200px;
aspect-ratio: 1 / 1;
overflow: hidden;
img {
 width: 100%; /* Ensure the image covers the full width */
    height: 100%; /* Ensure the image covers the full height */
    object-fit: cover; /* Maintain aspect ratio and fill the container */
    object-position: center; /* Center the image */
}`
const  DescriptionContent = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`

const TitleElement = styled.h3`
display:inline-block;
color:white;
font-weight:bold;
text-align:left;
background-color: transparent;
margin:0px;
margin-bottom: 5px;
font-size: 2rem;
`

const DescriptionElement = styled.p`
display:inline-block;
color: white;
text-align:left;
font-size:1.275em;
margin:0px;
`

const ContainerTextContentAction = styled.button`
background-color: white;
color: black;
font-weight: 400;
cursor: pointer;
`