import { toJSON } from 'api/apiUtils';//fromJSON
// import { userEmailSignUpRoute } from './UserServiceRoutes';

import apiUtils from 'api/apiUtils';


//USER
export const userEmailSignUpRoute = '/php/UserEmailSignupForm.php'

export const emailSignupAPI = async(data) => {

    console.log('SUBMIT emailSignupAPI: ', data)

    const item = {
        'email': data?.email || '',
        'created': new Date(),
        'name':data?.name || '',
        }
    
    const JSONdata = apiUtils.toJSON(item)
    console.log('JSON: ', JSONdata)
    
    try {
        const response = await fetch(userEmailSignUpRoute, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSONdata,
          });

        // Check response headers and status
        console.log('Response Headers: ', response.headers);
        console.log('Content-Type: ', response.headers.get('Content-Type'));
        console.log('Response Status: ', response.status);

          //FAILED
          if(!response.ok){
            const errorMessage = await response.text();
            throw new Error(errorMessage || 'Request failed');
          }

        //SUCCESS
        console.log("Success")

        // Log the full response text
        const responseText = await response.text();
        console.log('Full Response Text:', responseText);


                // **Quick Fix**: Check if response is JSON before parsing
                const contentType = response.headers.get('Content-Type');
                if (contentType && contentType.includes('application/json')) {
                    const data = JSON.parse(responseText);
                    console.log('Data from PHP:', data);
                    return { data, error: null };
                } else {
                    console.error('Unexpected response:', responseText);
                    throw new Error('Invalid response from server');
                }
            } catch (error) {
                // CAUGHT ERROR
                console.error('Request failed:', error);
                throw new Error(error.message || 'Unknown error occurred');

                // return { data: null, error };
            }
        
        // Check if the response is actually JSON
    //     try {
    //     const data = JSON.parse(responseText);
    //     console.log('Data from PHP:', data);
    //     return { data, error: null };
    //     } catch (parseError) {
    //         console.error('Error parsing JSON:', parseError);
    //         throw new Error('Invalid JSON response');
    //     }     
    // }
    // catch (error) {
    //     //CAUGHT ERROR
    //     return {data: null, error};
    // }
}