
import { ObjectRequestItem } from "./objectRequestItem";
import { BaseModel } from "model/base";

export interface objectUserRequest {


    //REQUIRED
    id: string;
    requester_id: string;

    //General
    created?: string; // Assuming you'll convert it to a Date object in React
    expiration?: string; // Assuming you'll convert it to a Date object in React
    duration?: number;
    end?: Date;
    start?: Date;
    status?: string;
    lng?: string;
    lat?: string;
    // location_id?: string;
    // location?: ObjectLocation;
    timezone?: string;
    timezoneAbbreviation?: string;
    item_ids?: string[];
    items?: [];//ObjectRequestItem[];
    total_price?: number;
    estimated_price?: number;
    // totalPrice(): number;
  }
  
  interface ObjectLocation {
    // Add properties of ObjectLocation here
  }


  export class ObjectUserRequestClass extends BaseModel implements objectUserRequest {
  // export class ObjectUserRequestClass implements objectUserRequest {
    // Required properties
    id: string;
    requester_id: string;
  
    // Optional and general properties
    created?: string;
    expiration?: string;
    duration?: number;
    end?: Date;
    start?: Date;
    status?: string;
    // location_id?: string;
    // location?: ObjectLocation;
    timezone?: string;
    timezoneAbbreviation?: string;
    item_ids?: string[];
    items?: [];//ObjectRequestItem[];
    total_price?: number;
    estimated_price?: number;

    constructor(data: Partial<objectUserRequest> = {}) {
      super(data); // Call the constructor of BaseModel with 'data' 
      this.id = data.id!;
      this.requester_id = data.requester_id!;
  
      this.created = data.created;
      this.expiration = data.expiration;
      this.duration = data.duration;
      this.end = data.end ? new Date(data.end) : undefined;
      this.start = data.start ? new Date(data.start) : undefined;
      this.status = data.status;
    //   this.location_id = data.location_id;
    //   this.location = data.location;
      this.timezone = data.timezone;
      this.timezoneAbbreviation = data.timezoneAbbreviation;
      this.items = data.items;
      this.item_ids = data.item_ids;
      this.total_price = data.total_price;
      this.estimated_price = data.estimated_price;
    }
  
    // Method to calculate total price
    // totalPrice(): number {
    //   return this.total_price || this.total_price || 0;
    // }
  }

  export const sampleUserRequestData: objectUserRequest[] = [
    // Ongoing Events
    {
      id: '1',
      requester_id: 'user101',
      created: '2025-01-15T10:00:00Z',
      expiration: '2025-01-20T10:00:00Z',
      duration: 4,
      start: new Date('2025-01-16T19:00:00Z'), // 2:00 PM EST, ongoing
      end: new Date('2025-01-16T22:00:00Z'), // 5:00 PM EST
      status: 'confirmed',
      lng: '-122.4194',
      lat: '37.7749',
      timezone: 'UTC',
      timezoneAbbreviation: 'UTC',
      item_ids: ['item1', 'item2'],
      items: [],
      total_price: 300.0,
      estimated_price: 300.0,
    },
    {
      id: '2',
      requester_id: 'user102',
      created: '2025-01-15T12:00:00Z',
      expiration: '2025-01-20T12:00:00Z',
      duration: 3,
      start: new Date('2025-01-16T20:00:00Z'), // 3:00 PM EST, ongoing
      end: new Date('2025-01-16T23:00:00Z'), // 6:00 PM EST
      status: 'pending',
      lng: '-73.935242',
      lat: '40.73061',
      timezone: 'UTC+1',
      timezoneAbbreviation: 'CET',
      item_ids: ['item3', 'item4'],
      items: [],
      total_price: 200.0,
      estimated_price: 200.0,
    },
  
    // Upcoming Events
    {
      id: '3',
      requester_id: 'user103',
      created: '2025-01-14T10:00:00Z',
      expiration: '2025-01-20T10:00:00Z',
      duration: 2,
      start: new Date('2025-01-17T10:00:00Z'), // Starts in the future
      end: new Date('2025-01-17T12:00:00Z'),
      status: 'scheduled',
      lng: '-118.2437',
      lat: '34.0522',
      timezone: 'UTC-8',
      timezoneAbbreviation: 'PST',
      item_ids: ['item5'],
      items: [],
      total_price: 100.0,
      estimated_price: 100.0,
    },
    {
      id: '4',
      requester_id: 'user104',
      created: '2025-01-15T15:00:00Z',
      expiration: '2025-01-22T15:00:00Z',
      duration: 5,
      start: new Date('2025-01-19T10:00:00Z'), // Starts in the future
      end: new Date('2025-01-19T15:00:00Z'),
      status: 'pending',
      lng: '-74.006',
      lat: '40.7128',
      timezone: 'UTC-5',
      timezoneAbbreviation: 'EST',
      item_ids: ['item6', 'item7'],
      items: [],
      total_price: 250.0,
      estimated_price: 250.0,
    },
  
    // Past Events
    {
      id: '5',
      requester_id: 'user105',
      created: '2025-01-10T09:00:00Z',
      expiration: '2025-01-12T09:00:00Z',
      duration: 1,
      start: new Date('2025-01-12T10:00:00Z'), // Already ended
      end: new Date('2025-01-12T11:00:00Z'),
      status: 'completed',
      lng: '-123.1216',
      lat: '49.2827',
      timezone: 'UTC-8',
      timezoneAbbreviation: 'PST',
      item_ids: ['item8'],
      items: [],
      total_price: 50.0,
      estimated_price: 50.0,
    },
    {
      id: '6',
      requester_id: 'user106',
      created: '2025-01-09T12:00:00Z',
      expiration: '2025-01-11T12:00:00Z',
      duration: 2,
      start: new Date('2025-01-10T14:00:00Z'), // Already ended
      end: new Date('2025-01-10T16:00:00Z'),
      status: 'completed',
      lng: '-79.3832',
      lat: '43.6532',
      timezone: 'UTC-5',
      timezoneAbbreviation: 'EST',
      item_ids: ['item9', 'item10'],
      items: [],
      total_price: 120.0,
      estimated_price: 120.0,
    },
    {
      id: '7',
      requester_id: 'user107',
      created: '2025-01-07T08:00:00Z',
      expiration: '2025-01-15T08:00:00Z',
      duration: 6,
      start: new Date('2025-01-08T12:00:00Z'), // Already ended
      end: new Date('2025-01-08T18:00:00Z'),
      status: 'canceled',
      lng: '-80.1918',
      lat: '25.7617',
      timezone: 'UTC-5',
      timezoneAbbreviation: 'EST',
      item_ids: ['item11', 'item12'],
      items: [],
      total_price: 200.0,
      estimated_price: 200.0,
    },
  ];
  
  
  
  