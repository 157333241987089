import React, { useRef, useEffect, useState } from 'react';
//styled components emotion
// import styled from "styled-components";
import styled from '@emotion/styled';
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react';

//Components
import DateTimeInput from '../DateTimeSearchComponent/DateTimeInput';
import DurationInput from '../DurationSearchComponent/DurationInput';
import LocationInput from '../LocationSearchComponent/LocationInput';

//context
import { useScreenContext } from "context/AppContext/ScreenContext"
import { useAppNavBarContext } from "context/AppNavBarContext"
import { useSearchActions } from 'third_party/redux/search/searchActions';
import { NAVBAR_HEIGHT, Z_INDEX_POPUP_MENUS } from 'components/constants';
import { frostedGlassStyle } from 'components/emotionStyles';
const TopSearchPopUpEditor = ({...props}) => {

//Contexts
const {isMobile, isMedium, isLarge} = useScreenContext()
const {
    toggleSearchInput,
    setSearchBarState,searchBarState, //compact or interactive
    activeSearchInput, setActiveSearchInput, //which input is showing
    canToggleBasedOnScroll,//adjusted based on setSearchBarState :) 
    setCanToggleBasedOnScroll,
} = useAppNavBarContext()
const {SEARCH_UPDATE_TEMP, SEARCH_LOCATION, SEARCH_RESULTS_UPDATE} = useSearchActions()

//REFERENCES
const containerRef = useRef(null);

//ACTIONS
const handleClickOutside = (event) => {
    console.log("HERE!!!")
    if (containerRef.current && containerRef.current === event.target) {
        //NEW
        // Reset search bar state to compact
        setSearchBarState("compact");
        // Reset active search input
        setActiveSearchInput(null);
        console.log('Container closed (click outside)');
      } else {
        console.log('Container open (click inside)');
      }
  };
  const setLocation = (place) => {
    SEARCH_LOCATION(place)
    // closeSearchInputsViews()
    // toggleSearchInput(null)
    console.log("WE ARE HERE")
    setActiveSearchInput(null)
  }
    return (
        <>
        {/* MOVE BELOW INTO NAVBAR EVENTUALLY */}
        {(isMedium || isLarge) && 
            // <SearchInputContainer/>
                <>
                <CloseableOverlay $isOpen={activeSearchInput !== null} onClick={handleClickOutside} ref={containerRef}>
                </CloseableOverlay>
                <SearchInputContainer $isOpen={activeSearchInput !== null} {...props}>
                    <CustomLocationInput isOpen={activeSearchInput==='location'} setLocation={setLocation}/>
                    <CustomDateTimeInput isOpen={activeSearchInput==='when'} />
                    <CustomDurationInput isOpen={activeSearchInput==='duration'}/>
                </SearchInputContainer>
                </>
        }
        </>
    )
}
export default TopSearchPopUpEditor

const CloseableOverlay = styled.div`
// display: flex;
position: fixed;
top:${NAVBAR_HEIGHT};
left:0px;
width: 100%;
height: 100%;
align-items: start;
z-index: calc(${Z_INDEX_POPUP_MENUS} - 3);
// cursor: ;
  ${({ $isOpen }) => `
    // background-color: ${$isOpen ? 'rgba(0, 0, 0, 0.5)' : 'transparent'} !important;
    opacity: ${$isOpen ? 1 : 0};
    display: ${$isOpen ? 'inline-block' : 'none'} !important;
    position: ${$isOpen ? 'fixed' : 'relative'} !important;
    transition: opacity 0.3s ease;
  `}
`
const SearchInputContainer = styled.div`
// display:flex;
position: absolute;
top: 90%;
left: 10%;
height: auto;
min-height:50vh;
width: 80%; 
margin-left: auto;
margin-right: auto;
background-color: transparent;
align-items: start;
justify-content: center;
z-index: calc(${Z_INDEX_POPUP_MENUS} - 2);
overflow-y:auto;
  ${({ $isOpen }) => `
    display: ${$isOpen ? 'flex' : 'none'} !important;
  `}
`

const CustomLocationInput = styled(LocationInput)`
min-height: 50vh !important;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
height: 50vh;
overflow-y: auto;

`

const CustomDurationInput = styled(DurationInput)`
min-height: 50vh !important;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15) !important;
height: 50vh;
overflow-y: auto;
`


const CustomDateTimeInput = styled(DateTimeInput)`
min-height: 50vh !important;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
height: 50vh;
overflow-y: auto;
`

const SearchInputOverlay = styled.div`
display:flex;
position: absolute;
top: 0px;
left: 0px;
height: 100%;
width: 100%; 
background-color: #00000080;
align-items: start;
justify-content: center;

${(props) =>
    props.$isOpen
      ? css`
          animation: ${fadeIn} 0.5s forwards;
          display:flex;
        `
      : css`
          animation: ${fadeOut} 0.3s forwards;
          animation-delay: 0s; /* No delay for fade out */
          display: none;
          opacity:0;
        `}
`






//KEYFRAMES
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;