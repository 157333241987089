import React, { useRef, useEffect, useState, useMemo } from 'react';
//styled components emotion
// import styled from "styled-components";
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'
//Constants
import { NAVBAR_HEIGHT, Z_INDEX_MAIN_CONTENT } from 'components/constants';
import { useScreenContext } from 'context/AppContext/ScreenContext';
import SearchBarExpanded from '../SearchBarExpanded';

//components
// import TopSearchPopUpEditor from './TopSearchPopUpEditor';
import { TextField } from '@mui/material';


// import SearchBarCompactSM from 'apps/User/NavBarComponents/SearchBarCompactSM';
import SearchBarVertical from 'apps/User/NavBarComponents/SearchBarVertical';
import { useSearch } from 'third_party/redux/selectors';
import { useSearchActions } from 'third_party/redux/search/searchActions';

const NewSideSearchComponent = ({data = {}, onChange}) => {

    const {isMobile, isMedium, isLarge} = useScreenContext()


   

    //This is AI/Search Toggle
    // const [selectedTab, setSelectedTab] = useState('AI'); //'Search'
    // const handleToggle = (selectedTab) => {
    //   console.log("Selected Tab:", selectedTab); // For debugging
    //   setSelectedTab(selectedTab)
    // };
    const selectedTab = useSearch().searchType
    
    const {SEARCH_TYPE} = useSearchActions()

    const handleToggle = (selectedTab) => {
      console.log("Selected Tab:", selectedTab); // For debugging
      SEARCH_TYPE(selectedTab)
    //   setSelectedTab(selectedTab)
    };
    //End


     //Variables
     const [localData, setLocalData] = useState({
        aiUserPrompt: data.aiUserPrompt || ''
    });
    const handleInputChange = (e) => {
        const { id, value } = e.target;
    
        // Update local state
        const updatedData = { ...localData, [id]: value };
        setLocalData(updatedData);
    
        // Trigger external function if provided
        if (onChange) {
            onChange(e, value);
        }
      };


      //RENDER STUFF

const renderSearchInputComponent = useMemo(() => {
  if (selectedTab === 'Search') {
    return (
        <>
            <SearchBarVertical/>
        </>
    )
  }
  return null;
}, [selectedTab, isMobile, isMedium, isLarge]);


const renderAIInputComponent = useMemo(() => {
    if (selectedTab === 'AI') {
      return (
        <TextField
          id="aiUserPrompt"
          label="Details"
          type="text"
          variant="outlined"
          value={localData.aiUserPrompt || ''}
          onChange={(e) => {
            console.log(`Input Changed: ${e.target.value}`); // Debugging
            handleInputChange(e);
          }}
          fullWidth
          size="small"
          margin="normal"
          placeholder="I'm having a gathering this Saturday with about 10 friends, at 5:30pm for 2 hours at 222 Greenway Lane, Charlotte, NC."
          multiline
          required
          rows={4} // You can adjust the number of rows as needed
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    }
    return null;
  }, [selectedTab, localData.aiUserPrompt]);

  const [unlockAITab, setUnlockAITab] = useState(false);

    return(
        <>
        <Container>
            <Title $isMobile={isMobile}>Event Services</Title>
            <SearchComponentContainer>
            {unlockAITab && (
                <OptionsRow>
                <OptionsRowContainer>
                    <ToggleButton
                        key={'AI'}
                        $isActive={selectedTab === 'AI'}
                        onClick={() => handleToggle('AI')}
                        >
                        AI <span style={{ fontSize: '0.75em', marginLeft: '5px', fontWeight: 'bold', color:'#f6933180' }}>BETA</span>
                    </ToggleButton>
                    <ToggleButton
                        key={'Search'}
                        $isActive={selectedTab === 'Search'}
                        onClick={() => handleToggle('Search')}
                        >
                        Near You
                    </ToggleButton>
                </OptionsRowContainer>
                </OptionsRow>
             )}
                <InputRow>
                    {renderAIInputComponent}
                    {renderSearchInputComponent}
                </InputRow>
            </SearchComponentContainer>
            {/* <TopSearchPopUpEditor/> */}
        </Container>
        </>
    )
}

export default NewSideSearchComponent

const Container = styled.div`
display:flex;
position:relative;
flex-direction:column;
width: 100%;
background-color: white;
height:auto;
align-items:center;
padding: 15px;
`
const Title = styled.h1`
display: ${props => {
  if (props.$isMobile) return 'none';
  return 'block'; // Default to large if none match
}};
color: black;
font-weight: 400;
font-size: 56px;
`

const SearchComponentContainer = styled.div`
display:flex;
flex-direction: column;
width: 100%;
// min-height: 25vh;
box-shadow: 0 1px 3px 0 rgba(60, 64, 67, .3), 0 4px 8px 3px rgba(60, 64, 67, .15);
border-radius: 25px;
max-width: 1080px;
padding:16px;
`
const OptionsRow = styled.div`
display:flex;
flex-direction:column;
flex-grow:0;
position:relative;

// min-height: 60px;
width: 100%;
height:auto;
// background-color:#00000040;
`
const OptionsRowContainer = styled.div`
display:flex;
line-height:20px;
letter-spacing: 0.2px;
font-size: 14px;
width: 100%;
`

const InputRow = styled.div`
display:flex;
position:relative;
flex-direction:column;
flex-grow:1;
// min-height: 60px;
width: 100%;
// background-color:#00000040;
justify-content: center;
align-items: center;
padding: 25px 0px;
color: black;
// z-index: ${Z_INDEX_MAIN_CONTENT};

`


const ToggleButton = styled.button`
  padding: 15px 15px;
  border: none;
  color:${props => {
    if (props.$isActive) return 'black';
    return '#BBBBBB80';
  }};
  background-color:${props => {
    if (props.$isActive) return '#EEE';
    return 'transparent';
  }};
  font-weight:${props => {
    if (props.$isActive) return '600';
    return '300';
  }};
//   color: ${({ active }) => (active ? "#EEE" : "#333")};
//   background-color: ${({ active }) => (active ? "gray" : "transparent")};
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.3s, color 0.3s;
  font-size:1em;
  &:hover {
    color: #BBBBBB;
  }

`;

const SpecialSearchBarInteractive = styled(SearchBarExpanded)`
        min-width: ${props => {
        if (props.$isMedium) return '100%';
        if (props.$isLarge) return '100%';
        return '100%'; // Default to large if none match
      }};
      `