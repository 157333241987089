import React, { useState } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

// Define props to include 'style' and motion props
export const FlipableCard = ({
  title = 'test bro',
  icon,
  description = 'Lorem epsum yahdia dkaljda lk',
  imageURL = '/images/Home/userNew2.png',
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);


  const mouseEnter = () => {
    if (!isAnimating) {
      setIsAnimating(true); // Start animation
      setIsOpen(true); // Set the card to open

      // Wait for the animation to finish before enabling interaction
      setTimeout(() => {
        setIsAnimating(false); // Re-enable interaction after animation
      }, 600); // Match the animation duration
    }
  };

  const mouseLeave = () => {
    if (!isAnimating) {
      setIsAnimating(true); // Start animation
      setIsOpen(false); // Set the card to closed

      // Wait for the animation to finish before enabling interaction
      setTimeout(() => {
        setIsAnimating(false); // Re-enable interaction after animation
      }, 600); // Match the animation duration
    }
  };

  return (
    <Card
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      {...props}
      transition={{ layout: { duration: 1 } }}
      layout
    >
      {/* Front part of the card with Icon and Title */}
      <CardFront $isOpen={isOpen}>
        <IconContainer>
          <img src={icon} alt="icon" />
        </IconContainer>
        <Title $isOpen={isOpen}>{title}</Title>
      </CardFront>

      {/* Back part of the card with description */}
      <CardBack $isOpen={isOpen}>
        <Paragraphs dangerouslySetInnerHTML={{ __html: description }} />
      </CardBack>

      {children}
    </Card>
  );
};

export default FlipableCard;

const Card = styled(motion.div)`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 3rem 5rem;
  cursor: pointer;
  min-height: 30vh;
  width: 100%;
  overflow: hidden;
  transform-style: preserve-3d; /* Important for 3D rotation effect */
  perspective: 1000px; /* Adds depth for rotation effect */
  transition: transform 0.6s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.75); /* Optional styling for emphasis */
  background-color: red;
  &:hover {
    transform: rotateY(180deg); /* Flip card on hover */
  }
`;

const CardFront = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  backface-visibility: hidden; /* Hide the front when rotated */
  opacity: ${({ $isOpen }) => ($isOpen ? 0 : 1)}; /* Fade out front when rotated */
  transition: opacity 0.6s ease;
    width: 100%;
    height:100%;

`;

const CardBack = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  // backface-visibility: hidden;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)}; /* Fade in description when rotated */
  transform: rotateY(180deg); /* Rotate back side */
  transition: opacity 0.6s ease;
   width: 100%;
    height:100%;
`;

const IconContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 50px;
  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const Title = styled(motion.p)`
  display: block;
  font-size: 22px;
  color: ${({ $isOpen }) => ($isOpen ? 'transparent' : 'black')}; /* Change color on open */
`;

const Paragraphs = styled(motion.div)`
  display: block;
  z-index: 11;
  color: black;
`;
