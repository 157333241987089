// import styled from 'styled-components';
import styled from 'styled-components';
import {AddButton} from 'components/standard'
import { useState } from 'react';
import PopUpNotification from 'components/Popups/PopUpNotification';

const CartMenuItem = ({ item, cartItems, removeFromCart, price = 'TBD' }) => {

    const handleClick = () => {
            removeFromCart(item);
    };


    const isPriceSet = price !== 'TBD';
    const [showPriceDescription, setShowPriceDescription] = useState(false)


    const [textTitle, setTextTitle] = useState('')
    const [pricingDetails, setPricingDetails] = useState('')


    const handleShowPriceDescription = () => {
      if (!isPriceSet){
         console.log("POPPING UP WITH PRICE SHIT")
         setTextTitle('Pricing: TBD')
         setPricingDetails("TBD means 'To Be Determined'. Our team will leverage our expertise and network to find the best service provider for you—at no extra cost! We’ll handle the logistics, negotiate the best rate on your behalf, and present you with the final options. You stay in control while we do the heavy lifting!")
         setShowPriceDescription(true)
      }
    }
    const handleShowPriceDescriptionClose = () => {
      setShowPriceDescription(false)
    }

return (
  <>
    <Container>
        <ImageSection>
          <img src={item.images[0].image} alt={item.images[0].alt} />
        </ImageSection>
        <DetailsSection>
            <h1>{item.title}</h1>
            {item.description && <p>{item.description}</p>}
            <RemoveButton onClick={handleClick}>-</RemoveButton>
        </DetailsSection>
        <PriceSection>
          <PriceSectionText onClick={handleShowPriceDescription} $isPriceSet={isPriceSet}>{price}</PriceSectionText>
        </PriceSection>

    </Container>
          {showPriceDescription && (
            <PopUpNotification
                titleText={textTitle}
                descriptionText={pricingDetails}
                buttonText="Ok"
                buttonAction={handleShowPriceDescriptionClose}  // Close popup on button click
                />
            )}
  </>
    );
};

export default CartMenuItem;

const Container = styled.div`
padding: 7.5px 0px;
position: relative;
display: flex;
width: 100%;
gap: 10px;
border-bottom: 1px solid #DDD;
&:hover{
    background-color: #00000010;
}
`

const ImageSection = styled.div`
display: flex;
aspect-ratio: 1 / 1;
height: 75px;
background-color: #CCC;
width: auto;
border-radius: 50%;
overflow:hidden;
img {
    display: block;
    width: 100%;  /* Fill container horizontally */
    height: 100%;  /* Fill container vertically (important change) */
    object-fit: cover;  /* Cover the container while maintaining aspect ratio */
    object-position: center; /* Center the image within the container */
    background-color: #00000040;
}
`
const DetailsSection = styled.div`
display: flex;
  flex-direction: column;
  flex: 1 1 calc(50% - 50px);
  padding-bottom: 1em;
  overflow: hidden;
  max-width: 100%;


  h1 {
    color:black;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0px;
    margin-top: 0px;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* Adjust based on your layout */
  }

  p {
    /* Default color, overridden by enabled/disabled styles */
    color:#00000060;
    white-space: nowrap;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* Adjust based on your layout */
  }

`;

const PriceSection = styled.div`
display: flex;
align-items: start;
justify-content: start;
aspect-ratio: 1 / 1;
height: 75px;
background-color: transparent;
width: auto;

`
const PriceSectionText = styled.p`
border-bottom:${props => props.$isPriceSet ? `none` : '1px solid #00000060'};
cursor: ${props => props.$isPrice ? `none` : 'pointer'};
padding-bottom: ${props => props.$isPrice ? `none` : '5px'};

margin-top:0px;
color: #00000060;

&:hover {
color: ${props => props.$isPrice ? `none` : 'black'};
border-bottom:${props => props.$isPriceSet ? `none` : '1px solid black'};

}

`
const RemoveButton = styled(AddButton)`
position: relative;
width:30px !important;
height:30px !important;
font-weight: 200 !important;
&:hover{
    background-color: orange !important;
}
`




const PriceDescription = styled.div`
display:flex;
width: 100%;
padding:5px 15px;
margin: 10px;
`

const PriceRemoveButton = styled(AddButton)`
position: relative;
width:30px !important;
height:30px !important;
font-weight: 200 !important;
background-color: transparent !important;
color: #00000080;
&:hover{
    color: black !important;
}
    `


//   ${(props) =>
//     props.enabled
//       ? css`
//           cursor: pointer;

//           &:hover {
//             opacity: 0.60;
//           }

//           h1 {
//             color: black;
//           }

//           p {
//             color: #00000060;
//           }
//         `
//       : css`
//           cursor: default;

//           &:hover {
//             opacity: 1.0;
//           }

//           h1 {
//             color: #ccc;
//           }

//           p {
//             color: #ccc;
//           }
//         `}
