import React, { useState } from 'react';
import styled, {css, keyframes} from "styled-components";
import LocationInputItem from './LocationInputItem';
import { useScreenContext } from 'context/AppContext/ScreenContext';
import GoogleMapsAddressInput from "components/Inputs/GoogleMapsAddressInput"

import GoogleMapsFunctions from 'utility/googleMapsFunctions';

import SearchFunctions from 'utility/searchFunctions';
import { useSearch } from 'third_party/redux/selectors';
import { useSearchActions } from 'third_party/redux/search/searchActions';

import { useAppNavBarContext } from 'context/AppNavBarContext'; //search


const LocationInput = ({isOpen, setLocation, ...props}) => {
    

    const tempSearchCriteria = useSearch().temp
    const {SEARCH_LOCATION} = useSearchActions()

    const { searchLocationPredictions, updateSearchLocationPredictions} = useAppNavBarContext();

    const handlePlaceSelection = (place) => {
        setLocation(place)
    }
    const [searchAddress ,setSearchAddress] = useState(""); //AJALON ADDED THIS
    const [searchResults,setSearchResults] = useState([])

    const {isMobile, isMedium, isLarge} = useScreenContext()

    //MOBILE STUFF
    const handleSearchSubmit = (address) => {
      GoogleMapsFunctions.returnPlaceFromAddress(address, setSearchAddress, (place) => {
        if (place) {
          console.log('Place selected:', place);
          SEARCH_LOCATION(place);
        } else {
          console.log("No place found");
        }
      });
    };

    // Function to handle place selection
    const handlePlaceSelected = (place) => {
      console.log('place selected:', place);
      SEARCH_LOCATION(place)
      setLocation(place)
  };

  const searchResultsChange = (inputValue) => {
    console.log("new input: ",inputValue)
    updateSearchLocationPredictions(inputValue)
    GoogleMapsFunctions.fetchPredictions(inputValue)
    .then(predictions => {
      console.log('Predictions:', predictions);
      setSearchResults(predictions)
    })
    .catch(error => {
      console.error('Error fetching predictions:', error);
    });
  }


      //setSearchResults(predictions)


    return (
        <>
            <Container $isOpen={isOpen} {...props}>
              {(isMedium || isLarge) && 
                <HeadingText>{SearchFunctions.getAddressFromPlace(tempSearchCriteria.location)}
                  {/* <Special>(current)</Special> */}
                </HeadingText>
              }
              {(isMobile) &&
              <>
                {/* <h1>MOBILE TIME</h1> */}
                <AddressInputContainer>
                  <GoogleMapsAddressInputSpecial onChange={searchResultsChange} onPlaceSelected={handlePlaceSelected} value={SearchFunctions.getLine1FromPlace(tempSearchCriteria.location)}/>
                </AddressInputContainer>
              </>
              }
                <PlaceItemsContainer>
                    {searchLocationPredictions.map((item, index) => (
                    <LocationInputItem key={index} item={item} onSelect={handlePlaceSelected} />
                    ))}
                </PlaceItemsContainer>
            </Container>
        </>
    )


}

export default LocationInput

const HeadingText = styled.h4`
display: flex;
align-items:center;
color: black;
gap: 10px;
`
 const Special = styled.p`
 color: black;
 `
const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: start;
    width: 100%;
    min-height: 75%;
    height: auto;
    background-color: white;
    border-radius: 25px;
    transform: ${(props) => (props.$isOpen ? 'translateX(0)' : 'translateX(100vw)')};  // Update the transform property
    transition: transform 0.3s ease-in-out;
    color: black;
    padding: 25px 50px; //Add isMobile isLarge, etc if you want to adjust this

    ${(props) =>
        props.$isOpen
          ? css`
              animation: ${fadeIn} 0.5s forwards;
              display:flex;
            `
          : css`
              animation: ${fadeOut} 0.3s forwards;
              animation-delay: 0s; /* No delay for fade out */
              display: none;
              opacity:0;
            `}   
`

// KEYFRAMES
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;


const PlaceItemsContainer = styled.div`
display: flex;
padding: 25px 15px;
width: 100%;
flex-wrap: wrap;
gap: 0px;
`

const AddressInputContainer = styled.div`
display: flex;
flex-direction: row;
border: solid 1px #CCC;
border-radius: 20px;
width: 100%;
`
const GoogleMapsAddressInputSpecial = styled(GoogleMapsAddressInput)`
    background-color: transparent;
    color: #CCC;

    // &.input-please-work {
    //     background-color: red !important;
    //   }
`