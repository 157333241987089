import styled from 'styled-components';
import PopUpNotification from 'components/Popups/PopUpNotification';
import { useState } from 'react';

const CheckoutServicesItem = ({ item }) => {
  const modifiedPrice = item.price ?? "TBD";





  const isPriceSet = modifiedPrice !== 'TBD';
    const [showPriceDescription, setShowPriceDescription] = useState(false)


    const [textTitle, setTextTitle] = useState('')
    const [pricingDetails, setPricingDetails] = useState('')
  const handleShowPriceDescription = () => {
    if (!isPriceSet){
       console.log("POPPING UP WITH PRICE SHIT")
       setTextTitle('Pricing: TBD')
       setPricingDetails("TBD means 'To Be Determined'. Our team will leverage our expertise and network to find the best service provider for you—at no extra cost! We’ll handle the logistics, negotiate the best rate on your behalf, and present you with the final options. You stay in control while we do the heavy lifting!")
       setShowPriceDescription(true)
    }
  }
  const handleShowPriceDescriptionClose = () => {
    setShowPriceDescription(false)
  }


  return (
    <>
        <Container>
            <ItemInfoContainer>
              <ImageSection>
                <img src={item.images[0].image} alt={item.images[0].alt} />
              </ImageSection>
              <DetailsSection>
                  <h1>{item.title}</h1>
                  {/* {item.description && <p>{item.description}</p>} */}
              </DetailsSection>
            </ItemInfoContainer>
            <PriceComponent>
              <PriceSectionText onClick={handleShowPriceDescription} $isPriceSet={isPriceSet}>{modifiedPrice}</PriceSectionText>
            </PriceComponent>
        </Container>
                  {showPriceDescription && (
                    <PopUpNotification
                        titleText={textTitle}
                        descriptionText={pricingDetails}
                        buttonText="Ok"
                        buttonAction={handleShowPriceDescriptionClose}  // Close popup on button click
                        />
                    )}
      </>
        );
    };

export default CheckoutServicesItem;

const Container = styled.div`
padding: 7.5px 0px;
display: flex;
flex-direction: row;
width: 100%;
gap: 10px;
// border-bottom: 1px solid #00000060;
// &:hover{
//     background-color: #00000010;
// }
`


const ItemInfoContainer = styled.div`
display:flex;
flex-direction: row;
flex: 1 1 calc(75% - 10px);
align-items: center;
justify-content: between;
gap: 15px;
`



const ImageSection = styled.div`
display: flex;
aspect-ratio: 1 / 1;
height: 50px;
background-color: #CCC;

img {
    display: block;
    width: 100%;  /* Fill container horizontally */
    height: 100%;  /* Fill container vertically (important change) */
    object-fit: cover;  /* Cover the container while maintaining aspect ratio */
    object-position: center; /* Center the image within the container */
    background-color: #00000040;
}
`
const DetailsSection = styled.div`
display: flex;
  flex-direction: column;
  width: 100%;
//   flex: 0 0 calc(50% - 50px);
  padding-bottom: 1em;


  h1 {
    color: #00000060;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0px;
  }

  p {
    /* Default color, overridden by enabled/disabled styles */
    color:#00000060;
    margin: 0px;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }

`;


const PriceComponent = styled.div`
display: flex;
flex: 1 1 calc(25% - 10px);
justify-content: center;
align-items: start;

`

const PriceSectionText = styled.p`
border-bottom:${props => props.$isPriceSet ? `none` : '1px solid #00000060'};
cursor: ${props => props.$isPrice ? `none` : 'pointer'};
// padding-bottom: ${props => props.$isPrice ? `none` : '5px'};
padding:0px;
margin:0px !important;
color: #00000060;

&:hover {
color: ${props => props.$isPrice ? `none` : 'black'};
border-bottom:${props => props.$isPriceSet ? `none` : '1px solid black'};

}
`
//   ${(props) =>
//     props.enabled
//       ? css`
//           cursor: pointer;

//           &:hover {
//             opacity: 0.60;
//           }

//           h1 {
//             color: black;
//           }

//           p {
//             color: #00000060;
//           }
//         `
//       : css`
//           cursor: default;

//           &:hover {
//             opacity: 1.0;
//           }

//           h1 {
//             color: #ccc;
//           }

//           p {
//             color: #ccc;
//           }
//         `}
