
//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//Constants
import { NAVBAR_HEIGHT } from 'components/constants';

//ELEMENTS
import {buttonSubmitStyle} from 'components/Buttons/ButtonStyles'
import TextField from '@mui/material/TextField';
import { textFieldStyle } from "components/Inputs/InputStyles";

//useScreen
import { useScreenContext } from 'context/AppContext/ScreenContext';
import { useState } from 'react';


import { BORDERS, ContainerGeneral } from 'apps/User/UserAppConstants';
import {mobileDisplayContentVertical,mediumDisplayContentHorizontal} from 'components/emotionStyles'


import { triggerFileUpload } from 'utility/photos/photoUpload';
import PhotoPreview from 'utility/photos/photoPreview';

interface UserData {
    details: string;
  }
  interface EditProfilePictureButtonsContainerProps {
    $isMedium: boolean;
    $isMobile: boolean;
  }
  interface ProfilePictuerProps {
    $isLarge: boolean;
    $isMedium: boolean;
    $isMobile: boolean;
  }
  const UserPofileEditComponent = ({profile_picture='', details=''}) => {
    const [data, setData] = useState<UserData>({
      details: "",
    });
  
    const handleChange = (value: string, field: keyof UserData) => {
      setData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };
  
    const handleSubmit = () => {
      console.log("Updated Data:", data);
      // Call your API or perform the necessary action with the updated data
    };
  
    const handleDeleteImage = () =>{
        console.log("Delete Profile Pic")
    }

    //photo shit
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleUploadImage = (selectedPhoto:File) =>{

      setSelectedFile(selectedPhoto)
      console.log("the selected photo is captured! ")
  }

  const handleAccept = () => {
    console.log('Photo accepted:', selectedFile);
    setSelectedFile(null); // Reset after accepting
  };

  const handleCancel = () => {
    console.log('Photo selection canceled');
    setSelectedFile(null); // Reset after canceling
  };

  const handleReUpload = () => {
    console.log('ReUpload initiated');
    setSelectedFile(null); // Reset for re-upload
    // Trigger your input file selection logic here
  };

  
  
    const {isMobile, isMedium, isLarge} = useScreenContext()

    const canDeleteImage = !!profile_picture; //The !! operator converts the value of profile_picture to a boolean: | null, '', undefined, 0, or false will evaluate to false. | Any non-empty string or other truthy value will evaluate to true.

    return (
      <>
      <Container>
        <ProfilePictureContainer>
            <ProfilePicture $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
              <img src={profile_picture}/>
            </ProfilePicture>
            <ButtonContainer $isMobile={isMobile} $isMedium={isMedium}>
                <UploadButton onClick={() => triggerFileUpload(handleUploadImage)}>Upload New</UploadButton>
                {canDeleteImage && (<DeleteButton onClick={handleDeleteImage}>Delete</DeleteButton>)}
            </ButtonContainer>
        </ProfilePictureContainer>

        <ValueText>Tell providers a little bit about you</ValueText>
        <ContentContainer>
          <TextFieldCustom
            id="details"
            label="About"
            value={data.details}
            onChange={(e) => handleChange(e.target.value, "details")}
            placeholder="About"
            // sx={textFieldStyle}
            multiline
            rows={4} // You can adjust the number of rows as needed
          />
        </ContentContainer>
        <SubmitButton onClick={handleSubmit}>Save</SubmitButton>
      </Container>
      <PhotoPreview
      file={selectedFile}
      onAccept={handleAccept}
      onCancel={handleCancel}
      // onReUpload={handleReUpload}
    />

    </>
    );
  };


  {/* <TextField
  id="details"
  label="Details"
  type="text"
  variant="outlined"
  value={data.details || ''}
  onChange={(e) => handleChange(e.target.value, "access_code")}
  fullWidth
  size="small"
  margin="normal"
  multiline
  required
  rows={4} // You can adjust the number of rows as needed
  InputLabelProps={{
      shrink: true,
  }} /> */}


export default UserPofileEditComponent

const Container = styled.div`
${ContainerGeneral};

display: flex;
flex-direction: column;
width: 100%;
min-height: ${NAVBAR_HEIGHT};
`

const ProfilePictureContainer = styled.div`
${mobileDisplayContentVertical}
display:flex;
align-items: center;
padding: 35px 0px;
gap: 35px;
`
const ProfilePicture = styled.div<ProfilePictuerProps>`
display: flex;
width: ${(props) => {
  if (props.$isMobile) return '75%';
  if (props.$isMedium) return '50%';
  if (props.$isLarge) return '33%';
  return '33%%'; // Default to large if none match
}};
aspect-ratio: 1 / 1;
// border: ${BORDERS.elementBorder};
border-radius: 50%;
overflow: hidden;
 background-image: url('/images/apps/user/no-pic.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

    img {
    width: 100%; /* Ensure the image covers the full width */
    height: 100%; /* Ensure the image covers the full height */
    object-fit: cover; /* Maintain aspect ratio and fill the container */
    object-position: center; /* Center the image */
  }
`
const ButtonContainer = styled.div<EditProfilePictureButtonsContainerProps>`
// ${mediumDisplayContentHorizontal};
display: flex;
flex-direction:row;
gap: 25px;
// height: 100%;
height:${(props) => {
    if (props.$isMobile) return 'auto';
    if (props.$isMedium) return 'auto';
    return '100%'; // Default to large if none match
}};
width: 100%;
flex-grow: 1;
justify-content: center;
align-items: center;
`
const UploadButton = styled.button`
background-color: #75e1b480;
color: black;
width: 50%;
cursor: pointer;
height:50px;
border-radius: 10px;
border: 1px solid transparent;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.35); /* Optional styling for emphasis */
&:hover{
background-color: #75e1b4;
color: white;
}
`
const DeleteButton = styled.button`
// background-color: #B93D4430;
background-color: transparent;
color: #B93D44;
// width: 50%;
padding: 5px 5px;
cursor: pointer;
height:50px;
border-radius: 10px;
// border: 1px solid #B93D44;
border: transparent;

// box-shadow: 0 2px 10px rgba(0, 0, 0, 0.35); /* Optional styling for emphasis */

&:hover{
// background-color: #B93D44;
color: red;
}
`
const ValueText = styled.p`
  color:#00000080;
`
const ContentContainer = styled.div`
display: flex;
width: 100%;
gap:15px;
padding-bottom: 35px;
`

const TextFieldCustom = styled(TextField)`
width: 100%;
flex: 1;
`

//END OF NAVBAR
const SubmitButton = styled.button`
${buttonSubmitStyle}
margin-top: 10px;
width: 100%;
margin-top: 20px;
`