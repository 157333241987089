import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

// import { useAuth, useCart } from "third_party/redux/selectors"
import SearchFunctions from "utility/searchFunctions"
import { ReactComponent as MenuIcon } from 'assets/menu-alt.svg';
import { NAVBAR_HEIGHT } from "components/constants";

//React
import {useState } from "react";
import { useNavigate, Link } from 'react-router-dom';

//DATA
import { useUser } from 'third_party/redux/selectors';

//Serivces
// import AuthServiceAPI from 'api/User/AuthService';

//MANAGE LOGOUT
// import { useStateManagementFunctions } from 'third_party/redux/stateManagementFunctions';

//JUST ADDED
import GeneralSignUpMenu, { useGeneralSignUpMenuContext } from "components/SignUpMenu/GeneralSignUpMenu";

import {frostedContainerStyle, frostedGlassStyle} from 'components/emotionStyles'

const MenuButtonLogout = ({onClick = null}) => {

  //API
//   const authServices = new AuthServiceAPI()

  //APP LOGOUT
//   const {logout} = useStateManagementFunctions()

const { isMenuOpen, toggleMenu, updateMenuItems, closeMenu, signupOptions, loginOptions } = useGeneralSignUpMenuContext()

//Navigation
const navigate = useNavigate();


const handleJoinNowClick = () => {
    updateMenuItems(signupOptions); // Set the menu items
    setIsVisible(false)
    toggleMenu('join'); // Open the menu
  };

  const handleLoginNowClick = () => {
    console.log('loginOptions', loginOptions)
    setIsVisible(false)
    updateMenuItems(loginOptions); // Set the menu items
    toggleMenu('login'); // Open the menu
  };

//VARIABLES
    // const cart = useCart()
    const [isVisble, setIsVisible] = useState(false)

    const toggleMenuOpenStatus = () => {
        setIsVisible((prev) => !prev);
        if(onClick){
            onClick()
        }
      };
      const menuData = [
        // {
        //   title: "Account",
        //   items: [
        //     { 
        //         label: "Sign Up",
        //         onClick: () => {
        //             console.log("Go to Signup") 
        //             handleJoinNowClick()
        //             // navigate('/account-settings')
        //         }
        //     },
        //     { label: "Login",
        //         onClick: () => {
        //             console.log("Go to Login") 
        //             handleLoginNowClick()
        //             // navigate('/bookings')
        //         }
        //     },
        //   ],
        // },
        {
            title: "Services",
            items: [
              { 
                  label: "Users",
                  onClick: () => {
                      console.log("Go to users") 
                      navigate('/')
                  }
              },
              { label: "Providers",
                  onClick: () => {
                      console.log("Go to providers") 
                      navigate('/provider')
                  }
              },
              { label: "Businesses",
                onClick: () => {
                    console.log("Go to businesses") 
                    navigate('/business')
                }
                },
            ],

        },
                {
          title: "Resources",
          items: [
            { 
              label: "Help",
              onClick: () => {
                  console.log("Go to help") 
                  navigate('/help')

              }
          },
            { 
                label: "Blog",
                onClick: () => {
                    console.log("Go to Blog") 
                    navigate('/blog')

                }
            },
            // { label: "Login",
            //     onClick: () => {
            //         console.log("Go to Login") 
            //         handleLoginNowClick()
            //         // navigate('/bookings')
            //     }
            // },
          ],
        },
      ];

//STYLED COMPONENTS
const Menu = styled(SignedInUserMenuComponent)`
`

const MenuOverlay = styled.div`
display:flex;
position:fixed;
width: 100vw;
height: 100vh;
background-color: #00000035;
top:0px;
left:0px;
z-index:1;

`

const Container = styled.div`
display: block;
position: relative;
height: auto;
width: auto;
padding: 0px 15px;
// overflow: hidden;
`
const Button = styled.div`

    position: relative;
    display: flex;
    padding: 5px 5px;
    // background-color: #FBAE6260;
    // border: solid 1px #00000060;
    border-radius: 20px;
    cursor: pointer;
    color: white;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: auto;
    // box-shadow: 0 4px 10px -2px #CCCCCC; /* Shadow at the bottom only */

//     &:hover {
//         background-color: #CCC;
//    }
`

const IconContainer = styled.div`
width: 40px;
height: 40px;
align-items: center;
justify-content: center;
display: flex;

img {
    display: flex;
    width:65%;
    height: 65%;
    color: black;
}
`

const StyledMenuIcon = styled(MenuIcon)`
    display: flex;
    width:65%;
    height: 65%;
    color: white;
    // background-color: white;
    // fill: white;

    path{
    stroke: white;


    }
    &:hover {
        path{
        // ${frostedGlassStyle}

        stroke:orange;
        }
   }
`

const UserIconContainer = styled.div`
width: 40px;
height: 40px;
border-radius: 50%;
align-items: center;
justify-content: center;
display: flex;
background-color: transparent;
 background-image: url('/images/apps/user/no-pic.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
      border-color: transparent;


img {
    display: flex;
    width:100%;
    height: 100%;
    color: black;
    border-radius: 50%;
    border-color: transparent;
    background-color: transparent;
}
    svg {
    display: flex;
    width:100%;
    height: 100%;
    background-color: transparent;
    border-radius: 50%;
    border-color: transparent;
    background-color: transparent;

}
`

const Indicator = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: red;
color: white;
position: absolute;
top: 0px;
right: calc(0px - 10px);
aspect-ratio: 1/1;
height: 20px;
width: 20px;
border-radius: 75%;
padding: 2px 4px;
`

//RETURNING
return (
    <>
    <Container>
        <Button onClick={toggleMenuOpenStatus}>
            <IconContainer>
                {/* <img src="/images/cart.svg" alt="Cart" /> */}
                <StyledMenuIcon style={{ fill: 'white' }}/>
            </IconContainer>
            {/* <UserIconContainer> */}
                {/* <img src="/images/cart.svg" alt="Cart" /> */}
                {/* <img src={picture}/> */}
            {/* </UserIconContainer> */}
            {/* <Indicator>1</Indicator> */}
        </Button>
        {isVisble && (
        <>
            <MenuOverlay onClick={toggleMenuOpenStatus}></MenuOverlay>
            <Menu data={menuData}>

            </Menu>
        </>
    )}
    </Container>
    </>
)

}
export default MenuButtonLogout;






















export const SignedInUserMenuComponent = ({data,...props}) => {


const Container = styled.div`
display: flex;
flex-direction:column;
position: absolute;
top: calc(${NAVBAR_HEIGHT} + 5px);
right: 0px;
min-width: 20vw;
max-height: calc(100vh - ${NAVBAR_HEIGHT} - 50px);
// min-height: 25vh;
background-color: white;
box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7); /* Optional styling for emphasis */
border-radius: 15px;
padding: 15px;
z-index:2;
`

const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #333;
  margin: 10px 0 5px 0;
`;

const MenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${({ $isDestructive }) => ($isDestructive ? "red" : "#000")};
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Separator = styled.div`
  height: 1px;
  background: #e0e0e0;
  margin: 10px 0;
`;
    return (
    <Container {...props}>
        {data.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          {section.title && <SectionTitle>{section.title}</SectionTitle>}
          {section.items.map((item, itemIndex) => (
            <MenuItem
              key={itemIndex}
              onClick={item.onClick}
              $isDestructive={item.isDestructive}
            >
              {item.label}
            </MenuItem>
          ))}
          {sectionIndex < data.length - 1 && <Separator />}
        </div>
      ))}
    </Container>
    )
}