import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

import { useAuth, useCart } from "third_party/redux/selectors"
import SearchFunctions from "utility/searchFunctions"
import { ReactComponent as MenuIcon } from 'assets/menu-alt.svg';
import { NAVBAR_HEIGHT } from "components/constants";

//React
import {useState } from "react";
import { useNavigate, Link } from 'react-router-dom';

//DATA
import { useUser } from 'third_party/redux/selectors';

//Serivces
import AuthServiceAPI from 'api/User/AuthService';

//MANAGE LOGOUT
import { useStateManagementFunctions } from 'third_party/redux/stateManagementFunctions';

const UserMenuButton = ({onClick = null}) => {

  //API
  const authServices = new AuthServiceAPI()

  //APP LOGOUT
  const {logout} = useStateManagementFunctions()

  //FUNCTIONS
  const handleSignOut = async() => {

    console.log("Sign Out")
    try {
      const result = await authServices.logout();
      let message = result.message || 'You have logged out successfully'
      logout()
      navigate('/')
      //TODO: POPUP THAT SAYS you have logged out.
    }
    catch(error){
      console.error('error with signing out: ',error)
    }
  }



   //USER DATA TO POPULATE PROFILE
   const UserData = useUser()
   const picture = UserData.picture ||  null //'https://media.licdn.com/dms/image/v2/D4E03AQGgZ_biJmfMdw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1722013360250?e=1741824000&v=beta&t=nIxGn4jTCQSAT_vIolkJMRJ77lipZ7DZMvf1ynTjTB8'

//Navigation
const navigate = useNavigate();

//useAuth
const auth = useAuth()

//VARIABLES
    const cart = useCart()
    const [isVisble, setIsVisible] = useState(false)

    const toggleMenu = () => {
        setIsVisible((prev) => !prev);
        if(onClick){
            onClick()
        }
      };
      const menuData = [
        {
          title: "Account Management",
          items: [
            { 
                label: "Account",
                onClick: () => {
                    console.log("Go to Account") 
                    navigate('/account-settings')
                }
            },
            { label: "Bookings",
                onClick: () => {
                    console.log("Go to Bookings") 
                    navigate('/bookings')
                }
            },
          ],
        },
        {
          title: null, // No title, just a single item
          items: [
            { label: "Sign Out", onClick: () => {
              handleSignOut()
            }, isDestructive: true },
          ],
        },
      ];

//STYLED COMPONENTS
const Menu = styled(SignedInUserMenuComponent)`
`

const MenuOverlay = styled.div`
display:flex;
position:fixed;
width: 100vw;
height: 100vh;
background-color: #00000035;
top:0px;
left:0px;

`

const Container = styled.div`
display: block;
position: relative;
height: auto;
width: auto;
padding: 0px 15px;
// overflow: hidden;
`
const Button = styled.div`

    position: relative;
    display: flex;
    padding: 5px 5px;
    // background-color: #FBAE6260;
    // border: solid 1px #00000060;
    border-radius: 20px;
    cursor: pointer;
    color: white;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: auto;
    box-shadow: 0 4px 10px -2px #CCCCCC; /* Shadow at the bottom only */

    &:hover {
        background-color: #CCC;
   }
`

const IconContainer = styled.div`
width: 40px;
height: 40px;
align-items: center;
justify-content: center;
display: flex;

img {
    display: flex;
    width:65%;
    height: 65%;
    color: black;
}
`

const StyledMenuIcon = styled(MenuIcon)`
    display: flex;
    width:65%;
    height: 65%;
    color: black;
    &:hover {
        color: white;
   }
`

const UserIconContainer = styled.div`
width: 40px;
height: 40px;
border-radius: 50%;
align-items: center;
justify-content: center;
display: flex;
background-color: transparent;
 background-image: url('/images/apps/user/no-pic.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
      border-color: transparent;


img {
    display: flex;
    width:100%;
    height: 100%;
    color: black;
    border-radius: 50%;
    border-color: transparent;
    background-color: transparent;
}
    svg {
    display: flex;
    width:100%;
    height: 100%;
    background-color: transparent;
    border-radius: 50%;
    border-color: transparent;
    background-color: transparent;

}
`

const Indicator = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: red;
color: white;
position: absolute;
top: 0px;
right: calc(0px - 10px);
aspect-ratio: 1/1;
height: 20px;
width: 20px;
border-radius: 75%;
padding: 2px 4px;
`

//RETURNING
return (
    <>
    <Container>
        <Button onClick={toggleMenu}>
            <IconContainer>
                {/* <img src="/images/cart.svg" alt="Cart" /> */}
                <StyledMenuIcon/>
            </IconContainer>
            <UserIconContainer>
                {/* <img src="/images/cart.svg" alt="Cart" /> */}
                <img src={picture}/>
            </UserIconContainer>
            {/* <Indicator>1</Indicator> */}
        </Button>
        {isVisble && (
        <>
            <MenuOverlay onClick={toggleMenu}></MenuOverlay>
            <Menu data={menuData}>

            </Menu>
        </>
    )}
    </Container>
    </>
)

}
export default UserMenuButton;






















export const SignedInUserMenuComponent = ({data,...props}) => {


const Container = styled.div`
display: flex;
flex-direction:column;
position: absolute;
top: calc(${NAVBAR_HEIGHT} + 5px);
right: 0px;
min-width: 20vw;
max-height: calc(100vh - ${NAVBAR_HEIGHT} - 50px);
// min-height: 25vh;
background-color: white;
box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7); /* Optional styling for emphasis */
border-radius: 15px;
padding: 15px;
`

const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #333;
  margin: 10px 0 5px 0;
`;

const MenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${({ $isDestructive }) => ($isDestructive ? "red" : "#000")};
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Separator = styled.div`
  height: 1px;
  background: #e0e0e0;
  margin: 10px 0;
`;
    return (
    <Container {...props}>
        {data.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          {section.title && <SectionTitle>{section.title}</SectionTitle>}
          {section.items.map((item, itemIndex) => (
            <MenuItem
              key={itemIndex}
              onClick={item.onClick}
              $isDestructive={item.isDestructive}
            >
              {item.label}
            </MenuItem>
          ))}
          {sectionIndex < data.length - 1 && <Separator />}
        </div>
      ))}
    </Container>
    )
}