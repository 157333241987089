import styled from '@emotion/styled';
import React, { useState } from 'react';

const TogglePanelContainer = styled.div`
  display: inline-flex;
  gap: 5px;
  padding: 10px 25px;
  background-color: #EEE;
  border-radius: 25px;
  align-self: flex-start;
  box-sizing: border-box;
  justify-content: center;
`;

const ToggleButton = styled.button`
  padding: 10px 15px;
  border: none;
  color:${props => {
    if (props.$isActive) return '#EEE';
    return '#333';
  }};
  background-color:${props => {
    if (props.$isActive) return 'gray';
    return 'transparent';
  }};
//   color: ${({ active }) => (active ? "#EEE" : "#333")};
//   background-color: ${({ active }) => (active ? "gray" : "transparent")};
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    color: #BBB;
  }
`;

const TogglePanel = ({ onToggle,activeTab, options = ["All", "Now", "Upcoming", "Past"] }) => {
//   const [activeTab, setActiveTab] = useState(options[0]);

//   const handleTabClick = (tab) => {
//     setActiveTab(tab); // Update state first
//     if (onToggle) {
//       onToggle(tab); // Call onToggle after state update
//     }
//   };

  return (
    <TogglePanelContainer>
      {options.map((tab) => (
        <ToggleButton
          key={tab}
          $isActive={activeTab === tab} // Ensure active state is passed correctly
        //   onClick={() => handleTabClick(tab)}
        onClick={() => onToggle(tab)} // Inform parent of selection
        >
          {tab}
        </ToggleButton>
      ))}
    </TogglePanelContainer>
  );
};

export default TogglePanel;
