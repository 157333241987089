import { textFieldStyle } from "components/Inputs/InputStyles";

import React from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import PasswordTextField from "components/Inputs/PasswordTextField";

import { ObjectUserStaging, ObjectUserStagingClass } from 'model/objectUserStaging';

import { BaseModel } from "model/base";


interface HomeFollowUsFormProps {
    data?: ObjectHomeFollowUs; // Optional data object
    onChange: (value: string, field: string) => void; // Required change handler
    gap?: string; // Optional placeholder text
  }

  interface StyledProps {
    gap?: string;
  }

const HomeFollowUsForm: React.FC<HomeFollowUsFormProps> = ({data = new ObjectHomeFollowUsClass() , onChange, gap = '10px' }) => {

    return (
        <>
            <Container gap={gap}>


                {/* <Row gap={gap}> */}
                    <TextFieldCustom
                        id="name"
                        label="Name"
                        value={data.name || ''}
                        onChange={(e) => onChange(e.target.value, 'name')}
                        placeholder={"Name"}
                        sx = {textFieldStyle}
                    />
                    {/* <TextFieldCustom
                        id="last_name"
                        label="Last Name"
                        value={data.last_name || ''}
                        onChange={(e) => onChange(e.target.value, 'last_name')}
                        placeholder={"Last Name"}
                        sx = {textFieldStyle}
                    /> */}
                {/* </Row> */}
                <TextFieldCustom
                    id="email"
                    label="Email"
                    value={data.email || ''}
                    onChange={(e) => onChange(e.target.value, 'email')}
                    placeholder={"Email"}
                    sx = {textFieldStyle}
                />
            </Container>
        </>
    )

}

export default HomeFollowUsForm

const Container = styled.div<StyledProps>`
margin-top: ${props => props.gap};
display: flex;
width: 100%;
flex-direction: column;
gap: ${props => props.gap}; /* Use the gap prop here */
`
const Row = styled.div<StyledProps>`
display: flex;
flex-direction: row;
width: 100%;
gap: ${props => props.gap}; /* Use the gap prop here */
`

const TextFieldCustom = styled(TextField)`
width: 100%;
flex: 1;
`
















export interface ObjectHomeFollowUs {
    phone_number?: string;
    name?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    [key: string]: any; // Or a more specific type if known

  }
  
export class ObjectHomeFollowUsClass extends BaseModel implements ObjectHomeFollowUs {
    phone_number?: string;
    name?: string;
    first_name?: string;
    last_name?: string;
    email?: string;

    [key: string]: any; // Or a more specific type if known

    constructor(data: Partial<ObjectUserStaging> = {}) {
      super(data); // Call the constructor of BaseModel with 'data'
      this.phone_number = data.phone_number;
      this.name = data.name;
      this.first_name = data.first_name;
      this.last_name = data.last_name;
      this.email = data.email;
      this.password = data.password;
    }
  }