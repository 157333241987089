import styled from "styled-components";
import { NAVBAR_HEIGHT } from "components/constants";

//this is to get access to popup menu
import GeneralSignUpMenu, { useGeneralSignUpMenuContext } from "components/SignUpMenu/GeneralSignUpMenu";

//auth
import { useAuth } from "third_party/redux/selectors";
import UserMenuButton from "apps/User/NavBarComponents/UserMenuButton";

//navigate
import { useNavigate, Link } from 'react-router-dom';


function BookNavBar() {
    // const { isMenuOpen, toggleMenu, updateMenuItems } = useGeneralSignUpMenuContext()

    //navigate
    const navigate = useNavigate();

    //auth
    const { isLoggedIn } = useAuth()

    //items
    const { isMenuOpen, toggleMenu, updateMenuItems, closeMenu, signupOptions, loginOptions } = useGeneralSignUpMenuContext()


    const handleJoinNowClick = () => {
        updateMenuItems(signupOptions); // Set the menu items
        toggleMenu('join'); // Open the menu
      };

      const handleLoginNowClick = () => {
        console.log('loginOptions', loginOptions)
        updateMenuItems(loginOptions); // Set the menu items
        toggleMenu('login'); // Open the menu
      };
    
      function renderUserMenu() {
        if (isLoggedIn) {
            return <UserMenuButton />;
        }
        return (
            <>
                {/* <NavElementJoinNow onClick={handleJoinNowClick}>
                    <p>JOIN NOW</p>
                </NavElementJoinNow>
                <NavElementLogin onClick={handleLoginNowClick}>
                    <p>Sign In</p>
                </NavElementLogin> */}
            </>
        );
    }

    const handleLogoClick = () => {
        navigate('/s')
    }
    return (
        <>
        <Nav>
            <LogoContainer onClick={handleLogoClick}>
                <img src='/images/cta-logo-one.svg' alt="GoghNow" />
            </LogoContainer>
            <NavMenu>
                {renderUserMenu()}
            </NavMenu>
            {/* <LoginButton>

            </LoginButton> */}
        </Nav>
        <GeneralSignUpMenu isOpen={isMenuOpen} onClose={closeMenu} items = {[]}/>
        </>
    )
}

const Nav = styled.nav`
// position: fixed;
top : 0;
left: 0;
right: 0; 
height: ${NAVBAR_HEIGHT};
background-color: white;
color: black;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 36px;
z-index: 100;
`

const LogoContainer = styled.a`
padding: 0px;
width: 150px;
margin-top: 4px;
max-height: 70px;
font-size: 0px;
display: inline-block;
cursor: pointer;

img {
    display: block;
    width:100%;
}
`

const NavMenu = styled.div`

align-items: center;
display: flex;
flex-flow: row nowrap;
height: 100%;
justify-content: flex-end;
margin: 0px;
padding: 0px;
position: relative;
gap: 15px;
// margin-right: auto;
// margin-left: 25px;

@media (max-width: 768px){
    display: none;
}
`

const NavElementLogin = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: auto;
padding: 0px 25px;
flex-shrink: 0; // Prevents the element from shrinking
flex-grow: 1; // Allows the element to grow if necessary
flex-basis: auto; // Basis is based on the content size
border-radius: 25px;
border: 2px solid #FBAE62;
color: #FBAE62;
cursor: pointer;
background-color: transparent;

&:hover{
    background-color: #FBAE6260;
}

`

const NavElementJoinNow = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: auto;
padding: 0px 25px;
flex-shrink: 0; // Prevents the element from shrinking
flex-grow: 1; // Allows the element to grow if necessary
flex-basis: auto; // Basis is based on the content size
border-radius: 25px;
border: 1px solid transparent;
background-color: transparent;
color: black;
cursor: pointer;

&:hover{
    background-color: #CCCCCC70;
    color: black;
}
`

const IconContainer = styled.a`
padding: 0px;
width: 50px;
margin-top: 4px;
max-height: 50px;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}
`
export default BookNavBar;